<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>{{data.ct ? 'Editar tiempo imputado' : 'Imputar tiempo'}}</p>
<div mat-dialog-content>

    <div *ngIf="chartOptions">
        <apx-chart [series]="chartOptions.series!" [chart]="chartOptions.chart!" [dataLabels]="chartOptions.dataLabels!"
            [plotOptions]="chartOptions.plotOptions!" [xaxis]="chartOptions.xaxis!" [tooltip]="chartOptions.tooltip!"
            [stroke]="chartOptions.stroke!"></apx-chart>
    </div>

    <form [formGroup]="form" class="add-time-dialog p10">
        <div class="form">
            <div class="df jcsb">
                <mat-form-field class="pricehour w50" color='primary'>
                    <mat-label>Descripción mano de obra</mat-label>
                    <input type="text" matInput placeholder="Descripción" formControlName="name">
                    <!-- <mat-error *ngIf="this.form.get('hours')?.hasError('max')">Máx. 99 horas</mat-error> -->
                </mat-form-field>
                <mat-form-field class="pricehour w50" color='primary'>
                    <mat-label>Horas</mat-label>
                    <input max="99" onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number"
                        matInput placeholder="Horas" formControlName="hours">
                    <mat-error *ngIf="this.form.get('hours')?.hasError('max')">Máx. 99 horas</mat-error>
                </mat-form-field>

                <mat-form-field class="pricehour w50" color='primary'>
                    <mat-label>Minutos</mat-label>
                    <input max="59" onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number"
                        matInput placeholder="Minutos" formControlName="minutes">
                    <mat-error *ngIf="this.form.get('minutes')?.hasError('max')">Máx. 59 minutos</mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="pricehour w100" *ngIf="data.showPriceHour" color='primary'>
                <mat-label>Precio por hora</mat-label>
                <input type="number" matInput placeholder="Precio por hora" formControlName="price" min="0"
                    (keypress)="preventNegativeInput($event)">
                <mat-hint admin>Establece el valor por defecto
                    <span class="underline-hover c_a" [go]="v.settings" [newTab]="true" [param]="{tab : 1, subtab : 0}">aquí</span>
                </mat-hint>
            </mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions align="center">
    <button [mat-dialog-close]="false" mat-button>Cancelar</button>
    <button color="primary" mat-flat-button (click)="create()" [disabled]="disabled">{{data.ct ? 'Editar' :
        'Añadir'}}</button>
</div>