<div class="container advanced-details-container" *ngIf="workloadData != undefined">
    <div class="top">

        <!-- LINE 1-->
        <div class="df jcsb w100">
            <div>
                <app-view-title>Carga de taller</app-view-title>
                <app-card-subtitle [first]="true">{{bigRefByDay(matSelect.value)}}</app-card-subtitle>
            </div>
            <div class="df aic">
                <button mat-icon-button (click)="arrowNextByFilter(matSelect.value, false)"
                    [matTooltip]="getTooltipByFilter(matSelect.value, false)">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <mat-form-field appearance="outline" class="periodFilter onboarding-workload-change-view">
                    <mat-select #matSelect [value]="selectedPeriod" (valueChange)="onSelectedPeriodChange($event)">
                        <mat-option [value]="pe.WEEK">Semana</mat-option>
                        <mat-option [value]="pe.MONTH">Mes</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button (click)="arrowNextByFilter(matSelect.value, true)"
                    [matTooltip]="getTooltipByFilter(matSelect.value, true)">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>
        </div>

        <!-- LINE 2-->
        <div class="df aic jcsb w100">
            <mat-form-field appearance="outline">
                <mat-label>Buscar</mat-label>
                <input #searchInput matInput placeholder="Buscar" (keyup)="applySearchFilter(searchInput.value)">
                <mat-icon matSuffix class="cp" (click)="applySearchFilter(searchInput.value)">search</mat-icon>
                <app-autocomplete (click)="applySearchFilter(searchInput.value)">
                </app-autocomplete>
            </mat-form-field>
            <div class="onboarding-workload-filters onboarding-workload-phone-filters">
                <div class="df">
                    <app-tagfilter *ngIf="workloadData.data.switch" [label]="false" [filter]="sectionFilter"
                        (change)="changeView($event == 0)">
                    </app-tagfilter>
                    <app-filters [filters]="currentFilters"></app-filters>
                </div>
            </div>
        </div>
    </div>

    <!-- CALENDAR -->
    <div class="calendar">
        <app-drag-and-drop-grid (onModifyAppointment)="onModifyAppointment.emit($event)"
            (deliveryWarn)="deliveryWarn.emit($event)" [periodEnum]="matSelect.value"
            [refDay]="refDay"></app-drag-and-drop-grid>
    </div>
</div>

<mat-menu #create="matMenu">
    <button *ngIf="workloadData && workloadData.data.or.views.createOr" mat-menu-item
        (click)="routerS.goTo(workloadData.data.or.views.createOr)">OR</button>
</mat-menu>