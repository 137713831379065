import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { M_User } from '../../models/M_User';
import { MatDialog } from '@angular/material/dialog';
import { RolesExplanationComponent } from './roles-explanation/roles-explanation.component';
import { EinaDataService } from 'src/app/services/EinaMainData/eina-data.service';

@Component({
  selector: 'app-userform',
  templateUrl: './userform.component.html',
  styles: [':host ::ng-deep mat-form-field, ._customInput  {width : 100%}']
})
export class UserformComponent {

  public form: UntypedFormGroup;
  originalUser: M_User | undefined;
  isAdmin = false;

  constructor(private formBuilder: UntypedFormBuilder, private d: MatDialog, public einaDataS : EinaDataService) {
    this.form = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
      email: [''],
      role: ['', Validators.required],
      center: ['', Validators.required],
    });
    this.isAdmin = this.einaDataS.user.admin;
  }

  initForm(u: M_User | undefined) {
    if (u != undefined) {
      this.originalUser = u;
      this.form.patchValue({
        "id": u.id,
        "name": u.name,
        "email": u.email,
        "role": u.role,
        "center": u.center
      })
      this.form.get('email')?.disable();
      if (u.admin) {
        this.form.get('role')?.disable();
      }
      if (!this.isAdmin){
        this.form.get('center')?.disable();
      }
    }
  }

  openRolesExplanation() {
    this.d.open(RolesExplanationComponent, { autoFocus: false });
  }
}
