import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { ActionCreateEditParentComponent } from '../or-create-edit/or-create-edit-parent/or-create-edit-parent.component';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ParamsService } from 'src/app/services/params.service';
import { ViewPath } from 'src/app/app-routing.module';
import { M_GroupTask } from 'src/app/models/M_GroupTask';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { DragComponent } from 'src/app/components/drag/drag.component';
import { IExitSaveChanges } from 'src/app/interfaces/IExitSaveChanges';
import { PreviewService } from 'src/app/services/preview.service';
import { MASTER_CLIENT_MINIFIY, MASTER_VECHILE_MINIFY } from 'src/app/constants/masters';
import { TemplateFieldsService } from 'src/app/services/template-fields.service';
import { TrasnferActionComponent, transferData } from 'src/app/components/trasnfer-action/trasnfer-action.component';
import { M_PDFTemplate } from 'src/app/models/M_PDFTemplate';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { RouterService } from 'src/app/services/router.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { or_status_close } from 'src/app/custom-classes/or_states';
import { endpoints } from 'src/app/constants/Endpoints';
import { LoadingPanelService } from 'src/app/services/LoadingPanel/loading-panel.service';
import { M_Action } from 'src/app/models/M_Action';
import { M_Contact } from 'src/app/models/M_Contact';
import { CreateMasterInDialogService } from 'src/app/services/create-master-in-dialog.service';

@Component({
  selector: 'app-create-edit-budget',
  templateUrl: './create-edit-budget.component.html',
  styleUrls: ['./create-edit-budget.component.css']
})
export class CreateEditBudgetComponent extends ActionCreateEditParentComponent implements OnInit, IExitSaveChanges {
  @ViewChild(DragComponent) dragComponent!: DragComponent;
  vehicle = MASTER_VECHILE_MINIFY;
  client = MASTER_CLIENT_MINIFIY;
  e = endpoints;
  rBudget = false;
  /** Dont modify */
  forceExit = false;

  constructor(routerS: RouterService, apiS: ApiService, route: ActivatedRoute, private location: Location, d: MatDialog,
    public router: Router, confirmD: ConfirmDialogService, private paramsS: ParamsService, public subS: SubscriptionService,
    public previewS: PreviewService, public companyS: CompanyService, private templateService: TemplateFieldsService,
    public responsiveS: ResponsiveService, private chdRef: ChangeDetectorRef, private loadingS: LoadingPanelService) {
    super(apiS, routerS, route, ["budget", "vehicle"], d, confirmD);
    let routerData = route.snapshot.data['extra'];
    this.rBudget = routerData != undefined && routerData.recambios && routerData.recambios == true;
  }

  saveChangesBeforeExit(): void {
    this.dragComponent.saveAll();
  }

  showSaveExitDialog(): boolean {
    if (this.dragComponent && this.subS.hasProPermissions) {
      return this.dragComponent.hasChanges();
    }
    return false;
  }

  override noParams() {
    this.actionLoaded = true;
  }

  ngOnInit(): void { }

  get missingCompanyInfo() {
    return this.companyS.companyMissingInfo;
  }

  /** Presupuesto de taller */
  createBudget(v: M_Vehicle) {
    if (v != undefined) {
      this.apiS.createBudget(v.client!.client_id, v.vehicle_id, '').then(res => {
        this.afterCreateBudget(res, v, v.client);
      })
    }
  }

  createRecambiosBudget(c: M_Contact) {
    if (c != undefined) {
      this.apiS.createRecambiosBudget(c.client_id).then(res => {
        this.afterCreateBudget(res, undefined, c);
      })
    }
  }

  afterCreateBudget(a: M_Action, v: M_Vehicle | undefined, c: M_Contact | undefined) {
    this.action = a;
    this.action!.vehicle = v;
    this.action!.client = v!.client;
    this.fillViewWithAction(this.action!);
    this.location.replaceState("/" + this.editView.path + "?budget=" + this.action?.id);
  }

  updateTitle(v: string) {
    if (this.action != undefined) {
      if (v != this.action!.title) {
        this.apiS.editTitle(this.action.id, v).then(_res => {
          this.action!.title = v;
        });
      }
    }
  }

  reSendBudget() {
    this.loadingS.show();
    return this.apiS.closeBudget(this.action!.id)
  }

  saveBudget() {
    this.dragComponent.saveAll();
  }

  showTemplateFields() {

    if (this.action && this.action.calculateTotal() < 0) {
      this.confirmD.show({
        title: "¡No tan deprisa!",
        body: "No puedes cerrar un presupuesto con un total negativo.",
        type: "danger",
        showCancel: false,
        confirmTxt: "OK"
      })
      return;
    }

    this.dragComponent.saveAll().then(res => {
      console.log(res);
      if (res) {
        this.templateService.showTemplateSteps("B", this.action!.action_id, this.action!.token, this.action?.vehicle?.type).afterClosed().subscribe(res => {
          if (res) {
            this.closeBudget();
          }
        })
      }
    });
  }

  closeBudget() {
    if (this.haveBudget) {
      this.dragComponent.saveAll().then(res => {
        this.action?.groups.forEach(g => {
          if (!g.hasTasks()) {
            this.deleteGroupTask(g);
          }
        })
        this.apiS.closeBudget(this.action!.id).then(res => {
          this.action!.status = or_status_close;
          let budgetIndex = this.action?.urlpdf.findIndex(url => url.type == "B");
          if (budgetIndex) {
            this.action?.urlpdf.removeIndex(budgetIndex); // Remove the real time budget preview
          }
          this.action!.urlpdf.push(new M_PDFTemplate(res.urlpdf[0]));
          this.previewS.showPreview('B', this.action!.urlpdf[0].token, undefined, this.action?.action_id);
        });
      });
    }
  }


  isClosed() {
    return this.action?.isClosed();

  }

  deleteGroupTask(gt: M_GroupTask) {
    this.confirmD.show({
      title: "Eliminar intervención",
      body: "¿Está seguro de que quiere eliminar la intervención?",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.rmGroup(gt.id).then(_res => {
          this.action?.groups?.removeElement(gt);
          this.action?.refreshType();
        })
      }
    });

  }

  openTransferTo(to: "or" | "budget" | "albaran") {
    if (to == "albaran") {
      this.apiS.transferAction(this.action!.id, "albaran", { client: this.action!.client }).then(res => {
        this.routerS.goWithQueryParams(ViewPath.createEditAlbaran, { albaran: res })
      })
    }
    else {
      this.d.open<TrasnferActionComponent, transferData, boolean>
        (TrasnferActionComponent,
          {
            autoFocus: false,
            disableClose: true,
            data: {
              action: this.action!,
              from: "budget",
              to: to
            }
          }
        )
    }
  }

  goOr(id: number) {
    this.paramsS.go(ViewPath.editOr, id);
  }

  goCompany() {
    this.routerS.goTo(this.v.settings);
  }

  get haveBudget() {
    return this.action != undefined;
  }

  get editView() {
    return this.rBudget ? this.v.createRecambiosBudget : this.v.createEditWorkshopBudget;
  }

  get backView() {
    return this.rBudget ? this.v.recambiosBudget : this.v.workshopBudget;
  }
}
