import { Component, Input } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Module } from 'src/app/models/M_Module';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import * as confetti from 'canvas-confetti';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css']
})
export class ModuleComponent {
  @Input({ required: true }) module!: M_Module;

  constructor(public companyS: CompanyService, private apiS: ApiService, private d: MatDialog) { }

  get active() { return this.companyS.hasModule(this.module.id); }

  toggleModule() {
    this.apiS.toggleModule(this.module.id).then(res => {
      this.companyS.toggleModule(this.module.id);
      if (this.companyS.hasModule(this.module.id)) {
        var canvas: any = document.getElementById('my-canvas')!;
        canvas.confetti = canvas.confetti || confetti.create(canvas, { resize: true });
        canvas.confetti({
          spread: 70,
          origin: { y: 1.2 }
        });

      }
    })
  }
  
}
