<form class="form" style="max-width: unset;">
  <div class="container">
    <div class="title">
      <app-view-title>Crear or</app-view-title>
    </div>

    <div class="or_abstract">
      <app-card class="full-height-or" [contentLoaded]="loaded">

        <app-card-subtitle [first]="true">Información general</app-card-subtitle>
        <app-or-form #orform [isEdit]="false" (onLoad)="loaded = true" (onSelectOrInit)="checkInterno($event)"></app-or-form>

        <!-- OR NOTES AND IMAGES -->
        <app-card-subtitle>Datos adicionales</app-card-subtitle>
        <div class="eina-form-wrapper">
          <app-or-notes-and-images [orFormComponent]="orform"></app-or-notes-and-images>
        </div>


        <!-- GROUP TASKS -->

        <app-card-subtitle>Intervenciones</app-card-subtitle>
        <div class="eina-form-wrapper">
          <app-ra-groups #raGroups ></app-ra-groups>
        </div>


        <div class="mt20"></div>
        <mat-divider></mat-divider>
        <div class="mt20"></div>
        <div class="mt10" *ngIf="isInternal"></div>
        <div class="df jcc">
          <button [disabled]="!orform.isFormOk || missingCompanyInfo" subscribed-user class="onboarding-or-finish"
            color="primary" style="margin-top: 10px;" mat-flat-button (click)="createOr()">Crear orden de reparación
          </button>
        </div>
        <div class="mt10">
          <p class="tac c_r" *ngIf="!orform.isFormOk">Faltan campos requeridos</p>
          <p class="tac c_r" *ngIf="missingCompanyInfo" (click)="goCompany()" style="cursor: pointer;">Faltan rellenar
            la información de empresa</p>
        </div>

      </app-card>
    </div>
  </div>
</form>