<div class="df jcc fdc aic bottom-entrance-blurred" [ngClass]="{'dn_i' : !showComponent}">
    <lord-icon src="https://cdn.lordicon.com/yphfrmut.json" trigger="in" trigger="loop" delay="2000"
        colors="primary:#121331,secondary:{{primary()}},tertiary:#ebe6ef,quaternary:#646e78,quinary:{{accent()}},senary:#3a3347"
        class="lord-large">
    </lord-icon>
    <p class="fsi fsl mt10">¡Echa el freno!</p>
    <p>Antes debes rellenar la información de tu empresa.</p>
    <p>No te preocupes, <span class="underline_b">¡es más fácil que ajustar un retrovisor!</span></p>
    
    <p [not-roles]="[R.ADMIN]" class="c_t2">Contacta con el administrador</p>
    
    <button mat-flat-button color="primary" (click)="goCompany()" class="mt10" admin>
        Ir a la sección de empresa
        <mat-icon>arrow_forward</mat-icon>
    </button>

</div>