import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ParameterStateComponent } from '../parameter-state/parameter-state.component';
import { Views } from '../../custom-classes/View';
import { Filter, TagFilter } from '../../custom-classes/Filter';
import { RouterService } from '../../services/router.service';
import { ResponsiveService } from '../../services/responsive.service';
import { or_status } from '../../custom-classes/or_states';
import { or_types } from '../../custom-classes/or_types';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { albaran_status } from '../../custom-classes/albaran_status';
import { ISearchFiltrable } from 'src/app/interfaces/ISearchFiltrable';
import { getAccentColor, sortTable } from 'src/app/utils/FunctionUtils';
import { invoice_states } from 'src/app/custom-classes/invoice_states';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Action } from 'src/app/models/M_Action';
import { M_Appointment } from 'src/app/models/M_Appointment';

const minColumns = 3;

@Component({
  selector: 'app-page-structure',
  templateUrl: './page-structure.component.html',
  styleUrls: ['./page-structure.component.css', './tags.css']
})
export class PageStructureComponent<T extends ISearchFiltrable> extends ParameterStateComponent implements OnInit {

  v = ViewPath;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('auto') autocomplete!: AutocompleteComponent;

  @Input() goBack? : Views;

  /** Page title */
  @Input({ required: true }) pageTitle!: string;
  
  /** List of.... */
  @Input({ required: true }) listTitleText!: string;

  /** Create button */
  @Input({ required: true }) createButton!:
    {
      icon: string,
      text: string,
      view?: Views,
      disabled?: boolean,
      tooltip?: string,
      loading?: boolean,
      cssClass?: string,
    } | undefined;

  /** Searcher  */
  @Input() searchLabel?: string;
  @Input() searchOnboardingClass?: string;
  @Input() autocompleteID?: string;

  /** Table data */
  @Input() data: any[] = [];
  @Input() displayedHeader: string[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() cellZize: ("small" | "big" | undefined)[] = [];
  private allHeaders: string[] = [];
  private allColumns: string[] = [];
  dotsColumnName = "more_vert";

  @Input() cardStyle?: boolean = true;
  @Input() masterClass!: "client" | "vehicle" | "product" | "invoice" | "or" | "budget" | "user" | "issues" | "appointment" | "albaran" | undefined;
  @Input() specialText: (((data: any) => any) | "decimal" | "money" | undefined)[] = []
  @Input() specialClass: (((data: any) => any) | undefined)[] = []
  @Input() dateFormat: (undefined | "show-hour")[] = []
  @Input() preIcon: (((data: any) => string | undefined) | undefined)[] = []
  @Input() preIconClass: string | undefined;


  /**Text that appears when there is no data**/
  @Input() noDataCreateNew = "";
  /** The elements on the right menu must be 'mat-menu-item' */
  @Input() rightMenu?: TemplateRef<any>;
  @Input() dots?: TemplateRef<any>;
  @Input() customPageContent?: TemplateRef<any>;
  @Input() circularLetter: boolean = false;; // Show circular letter on users
  @Input() filters?: Filter[];
  /** The quickFilter filter must also be inside the filters array */
  @Input() quickFilter?: TagFilter;

  @Input() showPagination: boolean = true;
  @Input() filter?: (object: any, ...filters: Filter[]) => boolean;

  @Output() onSelect: EventEmitter<T> = new EventEmitter();
  @Output() onClickCreateButton: EventEmitter<any> = new EventEmitter();
  @Output() onclickRow: EventEmitter<T> = new EventEmitter();
  @Output() onSearch: EventEmitter<string> = new EventEmitter();
  @Output() onFiltersDone: EventEmitter<Filter[]> = new EventEmitter();

  loaded_: boolean = false;
  allDataOnInit: any[] = [];
  /**Item seleccionado de la pantalla */
  item: T | undefined;
  dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
  /** If there is applied filers  */
  aplyedFilters: boolean = false;
  /** Filters to aplly when the page is loaded */
  applyFilterOnLoad: Filter | undefined;
  /** The object id to highlight. Normally after creating an object and come to this view */
  stateId: number | undefined;
  addNewDisabledBySubscription: boolean = false;

  accent = getAccentColor;

  constructor(private chdRef: ChangeDetectorRef, routerS: RouterService, route: ActivatedRoute, public responsive: ResponsiveService, private subS: SubscriptionService) {
    super(routerS, route, ["filter", "filtervalue", "filter1", "filtervalue1", "filter2", "filtervalue2"])
    this.responsive.onResize.subscribe(val => {
      this.resizeColumns()
    })
  }

  ngOnInit(): void {
    this.chdRef.detectChanges();
    if (this.subS.isBasic) {
      this.addNewDisabledBySubscription = true;
      if (this.createButton) {
        this.createButton.tooltip = this.subS.proPlanRequiredTxt;
      }
    }
  }

  override onState(v: any) {
    if (typeof v == "number") {
      this.stateId = v;
    }
  }

  override onParams(params: { param: string, value: string }[]) {

    for (let i = 0; i < 3; i++) {

      let filterName = "filter" + (i == 0 ? '' : i.toString());
      let filterValue = "filtervalue" + (i == 0 ? '' : i.toString());

      var f = params.filter(v => v.param == filterName)[0];
      var fv = params.filter(v => v.param == filterValue)[0];

      if (f && fv && this.filters) {
        if (this.filters[Number(f.value)] != undefined) {
          this.filters[Number(f.value)].initByParam(Number(fv.value));
          if (this.loaded_) {
            this.applyScreenFilter(this.filters[Number(f.value)]);
          }
          else {
            this.applyFilterOnLoad = this.filters[Number(f.value)]
          }
        }
      }
    }

  }

  ngAfterViewInit() {

    this.pushDotsColumn();

    /** Init the all colums and headers array */
    this.allColumns = [...this.displayedColumns];
    this.allHeaders = [...this.displayedHeader];


    this.resizeColumns();
  }

  resizeColumns() {
    if (this.responsive.isPhone()) { //622px
      this.displayedColumns = this.allColumns.slice(0, minColumns)
      this.displayedHeader = this.allHeaders.slice(0, minColumns)
    }
    else if (this.responsive.w < 750) {
      let columns = Math.max(this.allColumns.length - 2, 3);
      this.displayedColumns = this.allColumns.slice(0, columns)
      this.displayedHeader = this.allHeaders.slice(0, columns)
    }
    else if (this.responsive.w < 950) {
      let columns = Math.max(this.allColumns.length - 1, 3);
      this.displayedColumns = this.allColumns.slice(0, columns)
      this.displayedHeader = this.allHeaders.slice(0, columns)
    }
    else {
      this.displayedColumns = this.allColumns;
      this.displayedHeader = this.allHeaders;
    }

    if (this.dots && !this.displayedColumns.includes(this.dotsColumnName)) {
      this.pushDotsColumn();
    }

  }

  pushDotsColumn() {
    if (this.dots) {
      this.displayedColumns.push(this.dotsColumnName);
      this.displayedHeader.push("");
    }
  }


  initTable(d: any) {
    this.checkAndHighlightStateObject(d);
    this.allDataOnInit = d;
    this.data = d;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loaded_ = true;
    this.chdRef.detectChanges();
    this.dataSource.data = this.data;
    this.dataSource._updateChangeSubscription();
    this.dataSource._updatePaginator(this.data.length);
    this.dataSource.filterPredicate = this.defaultSearchFilter;
    this.chdRef.detectChanges();
    if (this.applyFilterOnLoad) {
      this.applyScreenFilter(this.applyFilterOnLoad);
      this.applyFilterOnLoad = undefined;
    }
  }

  defaultSearchFilter(object: any, searchFilter: string) {
    return searchFilter ? object.defaultSearchFilter(searchFilter) : true;
  }

  /** If a state is present, puts the object that match the state id on the top of the table an highlight it*/
  checkAndHighlightStateObject(d: any) {
    if (Array.isArray(d) && d.length != 0 && d[0].id) {
      var objIndex = d.findIndex((obj) => obj.id == this.stateId);
      var obj = d.filter((obj) => obj.id == this.stateId);
      d.removeIndex(objIndex) // Delete obj from array
      d.unshift(...obj); // Place obj on the first array position
    }
  }

  /** If filter is unefined, removes page filters */
  applyScreenFilter(...f: Filter[]) {
    let filteredTable: any[] = [];
    if (f) {
      /** TODO */
      this.allDataOnInit.forEach(obj => {
        if (this.filter) {
          if (this.filter(obj, ...f)) {
            filteredTable.push(obj);
          }
        }
      })
    }
    this.dataSource = new MatTableDataSource(f ? filteredTable : this.allDataOnInit);
    this.dataSource.filterPredicate = this.defaultSearchFilter;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loaded_ = true;
    this.aplyedFilters = f != undefined;
    this.dataSource.filter = this.searchInput.nativeElement.value;
    this.chdRef.detectChanges();
  }

  get isSelectedOnCurrentPage(): boolean {
    if (!this.selected) { return false; }
    const selectedIndex = this.dataSource.data.indexOf(this.selected as any);
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;
    return selectedIndex >= startIndex && selectedIndex < endIndex;
  }

  /** Seleccionar un item */
  select(data: T) {
    this.autocomplete.setSuggestion(data);
    this.item = data;
    if (this.item) {
      this.onSelect.emit(this.item);
    }

    this.chdRef.detectChanges();

    this.onclickRow.emit(data);
  }

  /** Apply search filters to the table */
  applySearchFilter(val: string) {
    //if (this.lazyLoading == undefined || (this.lazyLoading != undefined && this.lazyFinished)) {
    this.dataSource.filter = val;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.onSearch.emit(val);
    //}
  }

  /** Al hacer click al botón circular */
  onClickAdd() {
    if (this.addNewDisabledBySubscription) {
      this.subS.openTryProDialog()
    }
    else if (this.createButton && this.createButton.view) {
      this.routerS.goTo(this.createButton.view)
    }
    this.onClickCreateButton.emit();
  }

  removeElement(e: T) {
    this.item = undefined;
    this.allDataOnInit.removeElement(e);
    this.initTable(this.allDataOnInit);
    this.chdRef.detectChanges();
  }

  needDirective(i: number) {
    var specialText = this.specialText[i];
    return specialText != undefined && specialText == "decimal" || specialText == "money";
  }

  getSyleBySize(i: number, dc: string) {
    let currentCellSize = this.cellZize[i];
    if (dc == this.dotsColumnName) { currentCellSize = "small"; }
    if (currentCellSize != undefined) {
      if (currentCellSize == "big") {
        let width = 100 / this.displayedHeader.length;
        return "width : " + (width + 5) + "%;"
      }
      else {
        let width = 100 / this.displayedHeader.length;
        return "width : " + (width - 5) + "%;"
      }
    }

    return "";
  }

  isMoney(i: number) {
    var specialText = this.specialText[i];
    return specialText == "money";
  }

  isDecimal(i: number) {
    var specialText = this.specialText[i];
    return specialText == "decimal";
  }

  getInvoiceTagLabel(row: any) {
    if ("tagLabel" in row) {
      return row.tagLabel;
    }
    else return "";
  }

  isInvoice(row: any, dc: string) {
    return row[dc] instanceof invoice_states;
  }

  isAppointment(row: any) {
    return row instanceof M_Appointment;
  }

  /** Devuelve el texto de la row solicitada
   *  Si es una row 'especial', se llama a la función pertinente
   */
  getRow(row: any, dc: string, i: number) {
    if (row[dc] instanceof or_status) {
      return row;
    }
    if (row[dc] instanceof or_types) {
      return row[dc];
    }
    if (row[dc] instanceof invoice_states) {
      return row[dc];
    }
    if (row[dc] instanceof albaran_status) {
      return row[dc];
    }

    var specialText = this.specialText[i];

    if (this.specialText != undefined && specialText != undefined && specialText != "decimal" && specialText != "money") {
      return specialText(row);
    }

    if (row[dc] instanceof Date) {
      if (this.dateFormat[i] && this.dateFormat[i] == "show-hour") {
        return row[dc].shortFormat();
      }
      else {
        return row[dc].dayMonthYearFormat();
      }
    }
    return row[dc];
  }

  haveRowData(row: any, dc: string, i: number) {
    /** Check if the row has data (included special text funcion) */
    let val = this.getRow(row, dc, i);
    let valexists = val != undefined && val != null;

    if (dc == this.dotsColumnName) { return true; }

    if (typeof val == "string" && val == "") { return false }

    /** Check if the class contains the 'dc' entry */
    let entry = row[dc];
    let entryExists = (entry != undefined && entry != null);

    return valexists || entryExists;
  }

  getClass(row: any, _dc: string, i: number) {
    if (this.specialClass != undefined && this.specialClass[i] != undefined) {
      return this.specialClass[i]!(row);
    }
    return '';
  }

  getPreIcon(row: any, dc: string, i: number) {
    if (this.preIcon != undefined && this.preIcon[i] != undefined) {
      return this.preIcon[i]!(row);
    }
    return undefined;
  }

  showItem() {
    return this.item != undefined;
  }

  loadedNoData() {
    return this.loaded_ && this.data.length == 0;
  }

  onPaginateChange() {
    this.item = undefined;
  }

  onSortChanged(sort: Sort) {
    this.item = undefined;
    sortTable(this.data, sort);
  }

  showGroupsCircles(row: any) {
    if (this.isOr(row)) {
      return row.groups.length && !row.isBudget();
    }
    return false;
  }

  isOr(row: any): row is M_Action {
    return "groups" in row && "status" in row;
  }

  get dataOnScreen() { return this.dataSource.filteredData }
  get selected() { return this.item }
}
