<div class="form-sell" *ngIf="form">
  <form class="eina-form" [formGroup]="form" style="max-width: unset;">
    <app-card-subtitle>Compra</app-card-subtitle>

    <!-- Sección común para cliente/proveedor -->
    <div class="eina-form-section">
      <app-class-searcher [showLeftNumber]="false" #csearcher2 [masterClass]="client" [width100]="true"
        searchPlaceHolder="Buscar cliente o Proveedor" [extendedInfo]="false" [form_]="form" [formCname]="'client_id'"
        [disableIf]="disabledIf.bind(this)" [required]="true" (onSelect)="handleClientSelection($event)"
        [createData]="{ text : 'Crear contacto', by : 'dialog' }">
      </app-class-searcher>

      <!-- Sección específica para vehículos -->
      <div *ngIf="!notVehicle">
        <app-class-searcher #vehicleSearcher [masterClass]="vehicle"
          [formCname]="'vehicle_id'"
          [form_]="form"
          searchPlaceHolder="Buscar vehículo o propietario" 
          [noSelectionPlaceHolder]="'Ningún vehículo seleccionado'"
          (onSelect)="changeClient($event)" [required]="true" 
          [createData]="{ text : 'Nuevo vehículo', by : 'dialog' }">
        </app-class-searcher>
      </div>
    </div>

    <!-- Sección común para precio, fecha, IVA -->
    <div class="eina-form-section">
      <mat-form-field class="w50" appearance="outline">
        <mat-label>Precio</mat-label>
        <input type="number" min="0" matInput formControlName="price">
      </mat-form-field>

      <mat-form-field class="w50" appearance="outline">
        <mat-label>Fecha de Compra</mat-label>
        <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_3" [min]="minDate" (click)="picker_3.open()"
          formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker_3"></mat-datepicker-toggle>
        <mat-datepicker #picker_3></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="w50" appearance="outline">
        <mat-label>IVA</mat-label>
        <input type="number" matInput placeholder="IVA" formControlName="tax">
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error *ngIf="form.get('tax')?.touched && form.get('tax')?.errors">Valor máx.: 21</mat-error>
      </mat-form-field>
    </div>

    <!-- Sección específica para factura cuando no es vehículo -->
    <div *ngIf="notVehicle">
      <app-card-subtitle>Factura</app-card-subtitle>
      <mat-form-field class="w50" appearance="outline">
        <mat-label>Número de Factura</mat-label>
        <input type="text" matInput formControlName="docum_prov_num">
      </mat-form-field>
      <app-pdf-documents-upload></app-pdf-documents-upload>
    </div>

    <!-- Sección específica para factura cuando es un vehículo y ciertos tipos de cliente -->
    <div *ngIf="!notVehicle && (client_select?.type == 2 || client_select?.type == 3)">
      <app-card-subtitle>Factura</app-card-subtitle>
      <mat-form-field class="w50" appearance="outline">
        <mat-label>Número de Factura</mat-label>
        <input type="text" matInput formControlName="docum_prov_num">
      </mat-form-field>
      <app-pdf-documents-upload></app-pdf-documents-upload>
    </div>
    
  </form>
</div>
