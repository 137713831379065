<div recambios>
    <ng-container *ngIf="c.isClient">
        <ng-container *ngIf="style == 'flat'">
            <button mat-flat-button [color]="'primary'" [go]="v.createEditAlbaran" [param]="{'client' : c.client_id}">
                <mat-icon>assignment</mat-icon>
                Abrir albarán
            </button>
        </ng-container>
        <ng-container *ngIf="style == 'menu-option'">
            <button mat-menu-item [go]="v.createEditAlbaran" [param]="{'client' : c.client_id}">
                <mat-icon>assignment</mat-icon>
                Abrir albarán
            </button>
        </ng-container>
    </ng-container>    
</div>
