import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientDetailsComponent } from './views/client-details/client-details.component';
import { ClientsComponent } from './views/clients/clients.component';
import { CreateRecoverPasswordComponent } from './views/create-recover-password/create-recover-password.component';
import { CreateClientComponent } from './views/create-client/create-client.component';
import { CreateProductComponent } from './views/create-product/create-product.component';
import { CreateVehicleComponent } from './views/create-vehicle/create-vehicle.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LinkVehicleComponent } from './views/link-vehicle/link-vehicle.component';
import { LoginComponent } from './views/login/login.component';
import { OrComponent } from './views/or/or.component';
import { CreateOr } from './views/or-create-edit/create-or/create-or.component';
import { PageNotFoundEinaComponent } from './views/page-not-found/page-not-found.component';
import { ProductDetailsComponent } from './views/product-details/product-details.component';
import { ProductsComponent } from './views/products/products.component';
import { RegisterComponent } from './views/register/register.component';
import { VehicleDetailsComponent } from './views/vehicle-details/vehicle-details.component';
import { VehiclesComponent } from './views/vehicles/vehicles.component';
import { CreateEditBudgetComponent } from './views/create-budget/create-edit-budget.component';
import { EditOrComponent } from './views/or-create-edit/edit-or/edit-or.component';
import { DataUploadComponent } from './components/data-upload/data-upload.component';
import { DeactivateGuard } from './guards/deactivate-guard-';
import { IssuesViewComponent } from './views/issues-view/issues-view.component';
import { SubscribedUserGuard } from './guards/subscribed-user.guard';
import { CargaTallerParentComponent } from './views/carga-taller-parent/carga-taller-parent.component';
import { AbonoInvoiceComponent } from './views/abono-invoice/abono-invoice.component';
import { RolesEnum, roleGroup } from './enums/RolesEnum';
import { FrontendDocumentation } from './views/frontend-documentation/frontend-documentation.component';
import { GarageComponent } from './views/garage/garage.component';
import { AppointmentsComponent } from './views/appointments/appointments.component';
import { CreateVNVOcomponent } from './views/create-vn-vo/create-vn-vo.component';
import { SellVehicleComponent } from './views/sell-vehicle/sell-vehicle.component';
import { UserDetailsComponent } from './views/user-details/user-details.component';
import { AppointmentFormComponent } from './views/appointment-form/appointment-form.component';
import { AlbaranesComponent } from './views/albaranes/albaranes.component';
import { OrdersComponent } from './views/orders/orders.component';
import { CreateAppointmentClientSideComponent } from './views/create-appointment-client-side/create-appointment-client-side.component';
import { ShowAppointmentsCompanyComponent } from './views/show-appointments-company/show-appointments-company.component';
import { EinaDataGuard } from './guards/eina-data.guard';
import { CreateOrderComponent } from './views/create-order/create-order.component';
import { StorehousesComponent } from './views/storehouses/storehouses.component';
import { RoleGuard } from './guards/role.guard';
import { ModuleGuard } from './guards/module.guard';
import { environment } from 'src/environments/environment';
import { ModulesEnum } from './enums/ModulesEnum';
import { LandingComponent } from './views/landing/landing.component';
import { ManualStockMovesComponent } from './views/product-details/manual-stock-moves/manual-stock-moves.component';
import { CreateEditAlbaranComponent } from './views/create-edit-albaran/create-edit-albaran.component';
import { EditOrderComponent } from './views/edit-order/edit-order.component';
import { InvoicesComponent } from './views/invoices/invoices.component';
import { CreateInvoiceComponent } from './views/create-invoice/create-invoice.component';
import { CreateStorehouseEntryComponent } from './views/create-storehouse-entry/create-storehouse-entry.component';
import { StorehouseEntryComponent } from './views/storehouse-entry/storehouse-entry.component';
import { DiscountGroupsComponent } from './views/discount-groups/discount-groups.component';
import { ConceptsComponent } from './components/concepts/concepts.component';
import { CreateConceptComponent } from './components/create-concept/create-concept.component';
import { VnvoDetailsComponent } from './views/vnvo-details/vnvo-details.component';
import { CreateComercialBudgetComponent } from './views/create-comercial-budget/create-comercial-budget.component';
import { SellVehicleClientComponent } from './components/sell-vehicle-client/sell-vehicle-client.component';
import { AuthGuard } from './guards/auth.guard';
import { Views } from './custom-classes/View';
import { permissionsViewData } from './utils/FunctionUtils';
import { ComercialBudgetComponent } from './views/budget/comercial-budget/comercial-budget.component';
import { WorkshopBudgetComponent } from './views/budget/workshop-budget/workshop-budget.component';
import { PurchasesComponent } from './views/purchases/purchases.component';
import { BalanceComponent } from './views/balance/balance.component';
import { SettingsComponent } from './views/settings/settings.component';
import { CreateEditUserComponent } from './components/create-edit-user/create-edit-user.component';
import { PurchaseDetailsComponent } from './purchase-details/purchase-details.component';
import { CompanyRequiredGuard } from './guards/company-required.guard';
import { MissingCompanyInfoComponent } from './components/missing-company-info/missing-company-info.component';
import { InvoiceDetailsComponent } from './views/invoice-details/invoice-details.component';
import { RecambiosBudgetComponent } from './views/budget/recambios-budget/recambios-budget.component';
import { CreatePurchaseComponent } from './create-purchase/create-purchase.component';

export const ViewPath = {
  login :                   new Views("login"),
  landing :                 new Views("landing"),
  registerCompleted :       new Views("register-completed"),
  createRecoverPassword :   new Views("newpassword"),
  register :                new Views("register"),
  appointmentclientside :   new Views("pedircitaprevia"),
  dashboard :               new Views(""),
  profile :                 new Views("profile"),
  editProfile :             new Views("editprofile"),
  contacts :                new Views("contacts"),
  createContact :           new Views("createcontact"),
  vehicles :                new Views("vehiculos"),
  createvehicle :           new Views("createvehicle"),
  linkvehicle :             new Views("linkvehicle"),
  sellvehicleclient :       new Views("sellvehicleclient"),
  products :                new Views("products"),
  createManualMove :        new Views("createManualMoves"),
  discountGroups :          new Views("discountgroups"),
  createProduct :           new Views("createproducts"),
  invoices :                new Views("facturas"),
  invoiceDetails :          new Views("invoicedetails"),
  purchases :               new Views("purchases"),
  balance :                 new Views("balance"),
  albaranes :               new Views("albaranes"),
  createEditAlbaran :       new Views("createeditalbaran"),
  createInvoice :           new Views("createinvoice"),
  createConcept :           new Views("createconcept"),
  settings :                new Views("empresa"),
  userdetails :             new Views("userdetails"),
  createEditUser :          new Views("edituser"),
  contactDetails :          new Views("contactdetails"),
  vehicleDetails :          new Views("vehicledetails"),
  productDetails :          new Views("productdetails"),
  conceptDetails :          new Views("conceptdetails"),
  appointments :            new Views("appointments"),
  createappointment :       new Views("createappointment"),
  editAppointment :         new Views("editappointment"),
  showAppointments :        new Views("showAppointments"),
  or :                      new Views("or"),
  createOr :                new Views("creteor"),
  editOr :                  new Views("editor"),
  workshopBudget :          new Views("workshopbudget"),
  createEditWorkshopBudget :new Views("createeditworkshopbudget"),
  cargataller :             new Views("workload"),
  dataupload :              new Views("dataupload"),
  abono :                   new Views("abono"),
  orders :                  new Views("orders"),
  createOrder :             new Views("createorder"),
  editOrder :               new Views("editorder"),
  storeHouseEntry :         new Views("storehouseentry"),
  createStoreHouseEntry :   new Views("createstorehouseentry"),
  issues :                  new Views("issues"),
  garage :                  new Views("garage"),
  createVnVo :              new Views("createvnvo"),
  sellVehicle :             new Views("sellvehicle"),
  vnvoDetails :             new Views("vnvodetails"),
  purchaseDetails :         new Views("purchasedetails"),
  brandmodel :              new Views("brandandmodel"),
  options :                 new Views("options"),
  concepts :                new Views("concepts"),
  comercialBudget :         new Views("comercialbudget"),
  createComercialBudget :   new Views("createcomercialbudget"),
  createPurchase :          new Views("createpurchase"),
  recambiosBudget :         new Views("recambiosbudget"),
  createRecambiosBudget :   new Views("createrecambiosbudget"),
  frontend :                new Views("frontend"),
  companyRequired :         new Views("companyrequired"),
  pageNotFound :            new Views("notfound"),
}

/*data: {roles: roleGroup.adminAsesor, modules : [ModulesEnum.CONTROL_STOCK]}*/
const routes: Routes= [

  /** NO AUTH ROUTES */
  { path: ViewPath.login.path,                  title:"Login",                      component: LoginComponent},
  { path: ViewPath.registerCompleted.path,      title:"Login",                      component: LoginComponent},
  { path: ViewPath.landing.path,                title:"Landing",                    component: LandingComponent},
  { path: ViewPath.createRecoverPassword.path,  title:"Nueva contraseña",           component: CreateRecoverPasswordComponent}, 
  { path: ViewPath.register.path,               title:"Registrarse en Eina",        component: RegisterComponent},
  { path: ViewPath.appointmentclientside.path,  title:"Pedir cita previa",          component: CreateAppointmentClientSideComponent},
  { path: ViewPath.showAppointments.path,       title:"Próximas citas",             component: ShowAppointmentsCompanyComponent},
  { path: ViewPath.pageNotFound.path,           title:"Error",                      component: PageNotFoundEinaComponent}, 

  /** AUTH ROUTES */
  /** -------- */
  { path: ViewPath.dashboard.path,              title:"Dashboard",                  component: DashboardComponent,                  canActivate:[AuthGuard, EinaDataGuard]}, 
  { path: ViewPath.profile.path,                title:"Perfil",                     component: UserDetailsComponent,                canActivate:[AuthGuard, EinaDataGuard],    data : {profile : true}},
  { path: ViewPath.editProfile.path,            title:"Editar perfil",              component: CreateEditUserComponent,             canActivate:[AuthGuard, EinaDataGuard],    data : {profile : true}}, 
  { path: ViewPath.cargataller.path,            title:"Carga de taller",            component: CargaTallerParentComponent,          canActivate:[AuthGuard, EinaDataGuard],    data: permissionsViewData(roleGroup.adminAdviserMechanic, undefined)},

  
  /** Contact routes */
  { path: ViewPath.contacts.path,               title:"Contactos",                  component: ClientsComponent,                    canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)}, 
  { path: ViewPath.createContact.path,          title:"Crear contacto",             component: CreateClientComponent,               canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)}, 
  { path: ViewPath.contactDetails.path,         title:"Detalles de contacto",       component: ClientDetailsComponent,              canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},

  /** Vehicle routes */
  { path: ViewPath.vehicles.path,               title:"Vehiculos",                  component: VehiclesComponent,                   canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},
  { path: ViewPath.linkvehicle.path,            title:"Vincular Vehículo",          component: LinkVehicleComponent,                canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)}, 
  { path: ViewPath.sellvehicleclient.path,      title:"Comprar vehículo cliente",   component: SellVehicleClientComponent,          canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)}, 
  { path: ViewPath.createvehicle.path,          title:"Crear Vehículo",             component: CreateVehicleComponent,              canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},
  { path: ViewPath.vehicleDetails.path,         title:"Detalles del vehiculo",      component: VehicleDetailsComponent,             canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},

  /** Product routes */
  { path: ViewPath.products.path,               title:"Productos",                  component: ProductsComponent,                   canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},
  { path: ViewPath.createProduct.path,          title:"Crear Producto",             component: CreateProductComponent,              canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},
  { path: ViewPath.productDetails.path,         title:"Detalles del producto",      component: ProductDetailsComponent,             canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},
  { path: ViewPath.createManualMove.path,       title:"Crear movimiento manual",    component: ManualStockMovesComponent,           canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},
  { path: ViewPath.discountGroups.path,         title:"Groupos de descuento",       component: DiscountGroupsComponent,             canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, ModulesEnum.RECAMBIOS)},
  
  /** Invoice / purchase routes */
  { path: ViewPath.invoices.path,               title:"Facturas",                   component: InvoicesComponent,                   canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},
  { path: ViewPath.createInvoice.path,          title:"Crear factura",              component: CreateInvoiceComponent,              canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard, CompanyRequiredGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined),  canDeactivate: [DeactivateGuard]},
  { path: ViewPath.invoiceDetails.path,         title:"Detalles de factura",        component: InvoiceDetailsComponent,             canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},
  { path: ViewPath.abono.path,                  title:"Abono",                      component: AbonoInvoiceComponent,               canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},
  { path: ViewPath.purchases.path,              title:"Compras",                    component: PurchasesComponent,                  canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, ModulesEnum.VNVO)},
  { path: ViewPath.balance.path,                title:"Balance",                    component: BalanceComponent,                    canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.everyoneExcepMechanic, undefined)},

  /** Orders routes */
  { path: ViewPath.orders.path,                 title:"Pedidos",                    component: OrdersComponent,                     canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminRecambista, ModulesEnum.RECAMBIOS)},
  { path: ViewPath.createOrder.path,            title:"Crear pedido",               component: CreateOrderComponent,                canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard, CompanyRequiredGuard],  data: permissionsViewData(roleGroup.adminRecambista, ModulesEnum.RECAMBIOS)},
  { path: ViewPath.editOrder.path,              title:"Editar pedido",              component: EditOrderComponent,                  canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminRecambista, ModulesEnum.RECAMBIOS)},

  /** OR routes */
  { path: ViewPath.or.path,                     title:"Ordenes de reparación",      component: OrComponent,                         canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard], data: permissionsViewData(roleGroup.adminAdviserMechanic, undefined)}, 
  { path: ViewPath.createOr.path,               title:"Crear or",                   component: CreateOr,                            canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard, CompanyRequiredGuard], data: permissionsViewData(roleGroup.adminAdviserMechanic, undefined)},
  { path: ViewPath.editOr.path,                 title:"Editar or",                  component: EditOrComponent,                     canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard], data: permissionsViewData(roleGroup.adminAdviserMechanic, undefined),   canDeactivate: [DeactivateGuard]},

  /** Workshop budget routes */
  { path: ViewPath.workshopBudget.path,           title:"Presupuestos de taller",   component: WorkshopBudgetComponent,             canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminAdviser, undefined)},
  { path: ViewPath.createEditWorkshopBudget.path, title:"Presupuesto de taller",    component: CreateEditBudgetComponent,           canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard, CompanyRequiredGuard],  data: permissionsViewData(roleGroup.adminAdviser, undefined),      canDeactivate: [DeactivateGuard]},
  
  /** Recambios budget routes */
  { path: ViewPath.recambiosBudget.path,        title:"Presupuestos de recambios",  component: RecambiosBudgetComponent,            canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminRecambista, undefined)},
  { path: ViewPath.createRecambiosBudget.path,  title:"Presupuesto de recambios",   component: CreateEditBudgetComponent,           canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard, CompanyRequiredGuard],  data: permissionsViewData(roleGroup.adminRecambista, ModulesEnum.RECAMBIOS, {recambios : true})},

  /** Appointment routes */
  { path: ViewPath.appointments.path,           title:"Citas",                      component: AppointmentsComponent,               canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminAdviser, undefined)},
  { path: ViewPath.createappointment.path,      title:"Crear cita",                 component: AppointmentFormComponent,            canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminAdviser, undefined)},
  { path: ViewPath.editAppointment.path,        title:"Editar cita",                component: AppointmentFormComponent,            canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminAdviser, undefined)},

  /** Albaranes */
  { path: ViewPath.albaranes.path,              title:"Albaranes",                  component: AlbaranesComponent,                  canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminRecambista, ModulesEnum.RECAMBIOS)},
  { path: ViewPath.createEditAlbaran.path,      title:"Albarán",                    component: CreateEditAlbaranComponent,          canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard, CompanyRequiredGuard],  data: permissionsViewData(roleGroup.adminRecambista, ModulesEnum.RECAMBIOS), canDeactivate: [DeactivateGuard]},

  /** Storehouse entry */
  { path: ViewPath.storeHouseEntry.path,        title:"Entrada de almacén",         component: StorehouseEntryComponent,            canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminRecambista, ModulesEnum.RECAMBIOS)},
  { path: ViewPath.createStoreHouseEntry.path,  title:"Crear entrada de almacén",   component: CreateStorehouseEntryComponent,      canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminRecambista, ModulesEnum.RECAMBIOS)},

  /** VN and VO */
  { path: ViewPath.garage.path,                 title:"Garaje",                     component: GarageComponent,                       canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.createVnVo.path,             title:"Crear VN/VO",                component: CreateVNVOcomponent,                   canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.vnvoDetails.path,            title:"Detalles de VN/VO",          component: VnvoDetailsComponent,                  canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.purchaseDetails.path,        title:"Detalles de compra",         component: PurchaseDetailsComponent,              canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.sellVehicle.path,            title:"Venta de vehículo",          component: SellVehicleComponent,                  canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.brandmodel.path,             title:"Marcas y modelos",           component: CreateStorehouseEntryComponent,        canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.options.path,                title:"Opciones",                   component: CreateStorehouseEntryComponent,        canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.concepts.path,               title:"Conceptos",                  component: ConceptsComponent,                     canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.comercialBudget.path,        title:"Presupuesto de comercial",   component: ComercialBudgetComponent,              canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.createComercialBudget.path,  title:"Crear presupuesto",          component: CreateComercialBudgetComponent,        canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.createPurchase.path,         title:"Crear factura compra",       component: CreatePurchaseComponent,               canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, ModulesEnum.VNVO)},
  { path: ViewPath.createConcept.path,          title:"Crear Concepto",             component: CreateConceptComponent,                canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, undefined)},
  { path: ViewPath.conceptDetails.path,         title:"Editar Concepto",            component: CreateConceptComponent,                canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(roleGroup.adminComercial, undefined)},

  /** Company routes */
  { path: ViewPath.settings.path,               title:"Configuración",              component: SettingsComponent,                     canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(RolesEnum.ADMIN, undefined)},
  { path: ViewPath.userdetails.path,            title:"Detalles del usuario",       component: UserDetailsComponent,                  canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(RolesEnum.ADMIN, undefined)},
  { path: ViewPath.createEditUser.path,         title:"Editar usuario",             component: CreateEditUserComponent,               canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(RolesEnum.ADMIN, undefined)},
  { path: ViewPath.companyRequired.path,        title:"Falta información",          component: MissingCompanyInfoComponent,           canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard],  data: permissionsViewData(undefined, undefined)},

  /** --------- */
  { path: ViewPath.dataupload.path,             title:"Importación de datos",       component: DataUploadComponent,                   canActivate:[AuthGuard, EinaDataGuard, RoleGuard, ModuleGuard, SubscribedUserGuard],     data: permissionsViewData(RolesEnum.ADMIN, undefined)},
  { path: ViewPath.issues.path,                 title:"Incidencias",                component: IssuesViewComponent,                   canActivate:[AuthGuard, EinaDataGuard]},
  { path: ViewPath.frontend.path,               title:"Frontend documentation",     component: FrontendDocumentation,                 canActivate:[AuthGuard, RoleGuard],                              data: permissionsViewData(environment.local || environment.dev ? [] : [RolesEnum.NOONE], undefined)},

  

  /** Don't touch this */
  { path: '**', pathMatch: 'full',              title:"Error",                      component: PageNotFoundEinaComponent},  
];

@NgModule({
  imports: [RouterModule.forRoot(routes , {
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


/** Get Roles if... */
function _GRIF_(roles : RolesEnum[], enableif : boolean){
  if (enableif){return roles;}
  else return [RolesEnum.NOONE]
}
