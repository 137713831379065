<div recambios *ngIf="product && product.locations.length && product.control_stock">
    <button *ngIf="companyS.recambiosModule" mat-icon-button [mat-menu-trigger-for]="locations" [disabled]="disabled">
        <mat-icon>warehouse</mat-icon>
    </button>
    <mat-menu #locations="matMenu" [mat-menu] class="storehouse-selector">
        <div mat-menu-item [ngClass]="{'selected' : product.selected_location == location.id}" *ngFor="let location of getProductLocations" (click)="selectedStore(location)">
            <p class="nmb">
                <mat-icon class="vam" [style]="location.bookmarkColorStyle">bookmark</mat-icon>
                {{location.store_name}}
                <span class="location-text">{{location.name}} </span>
                <span *ngIf="productLineTable && showStock" [class]="stockClass(location)"> | {{stockText(location)}}</span>
            </p>
            <p *ngIf="locationOnlyInformative" class="c_t2 fsi fss nmb">No editable</p>
        </div>
    </mat-menu>
</div>