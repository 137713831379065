import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QrDialogComponent } from '../../../../components/qr-dialog/qr-dialog.component';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { TimeService } from '../../../../services/time.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { M_AppointmentConfig } from '../../../../models/M_AppointmentConfig';
import { ParamsService } from '../../../../services/params.service';
import { ViewPath } from 'src/app/app-routing.module';
import { CustomTime } from 'src/app/custom-classes/CustomTime';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { SnackService } from 'src/app/services/snack.service';
import { ApiService } from 'src/app/services/Api/api.service';

@Component({
  selector: 'app-workshop-and-appointments',
  templateUrl: './workshop-and-appointments.component.html',
  styleUrls: ['./workshop-and-appointments.component.css']
})

export class WorkshopAndAppointmentsComponent implements AfterViewInit {
  selectsArray: MatSelect[] = [];
  @Input({ required: true }) form!: UntypedFormGroup;
  COMPANY_HOURS: [CustomTime[], CustomTime[], CustomTime[], CustomTime[]] = [[], [], [], []];
  @ViewChild('select4') select_ca!: MatSelect;
  @ViewChild('select1') select_om!: MatSelect;
  @ViewChild('select2') select_cm!: MatSelect;
  @ViewChild('select3') select_oa!: MatSelect;
  disabled = false;
  max = 30;
  min = 5;
  showTicks = false;
  value = 0;

  constructor(private d: MatDialog, private timeService: TimeService, private cdRef: ChangeDetectorRef, private apiS: ApiService, private paramS: ParamsService, private snackService: SnackService,
    public responsiveS: ResponsiveService) {
  }

  ngAfterViewInit(): void {
    this.selectsArray = [this.select_om, this.select_cm, this.select_oa, this.select_ca];
    this.generateInputs();
    this.cdRef.detectChanges();
  }

  openQRDialog(url?: string) {
    if (!url) {
      this.snackService.show("Ha ocurrido un problema generando el QR.")
    }
    this.d.open(QrDialogComponent, { data: url, autoFocus: false });
  }

  inputChanged(evnt: MatSelectChange, selectId: number) {
    this.correctProgressiveBigger();
    this.generateInputs();
    this.correctRequired();
  }

  get someAfternoon() {
    if (this.selectsArray.length) {
      return this.selectsArray[2].value || this.selectsArray[3].value
    }
    return false;
  }

  correctRequired() {
    var f1 = this.form.get('afternoon_work_schedule_start');
    var f2 = this.form.get('afternoon_work_schedule_finish');

    if (this.someAfternoon) {
      f1?.setValidators(Validators.required);
      f2?.setValidators(Validators.required);
    }
    else {
      f1?.removeValidators(Validators.required);
      f2?.removeValidators(Validators.required);
    }

    f1?.updateValueAndValidity();
    f2?.updateValueAndValidity();
    this.cdRef.detectChanges();
  }
  getStartHour(i: number) {
    if (i == 0 || !this.selectsArray[i - 1].value) { return 0 }
    return this.selectsArray[i - 1].value;
  }
  generateInputs() {
    let maxHour = 60 * 24;
    let granularity = 30;
    for (let i = 0; i < this.selectsArray.length; i++) {
      let hoursavailable: CustomTime[] = []
      let startHour = this.getStartHour(i);
      for (let i = startHour; i < maxHour; i += granularity) {
        if (i < maxHour) {
          hoursavailable.push(this.timeService.parseNumberToHour(i));
        }
      }
      this.COMPANY_HOURS[i] = hoursavailable;
    }
  }

  /** If the company shcedule is correct like*/
  correctProgressiveBigger() {
    var ok = true;
    for (let i = 0; i < this.selectsArray.length; i++) {
      if (i != 0) {
        if (this.selectsArray[i - 1].value > this.selectsArray[i].value || this.selectsArray[i - 1].value == undefined) {
          ok = false;
        }
        if (ok == false) {
          this.selectsArray[i].value = "";
        }
      }
    }
    return ok;
  }

  get token() { return this.form.get('token')?.value }
  get url() {return this.form.get('url')?.value }

  goClientSideAppointments() {
    //this.dialogRef.close();
    this.paramS.go(ViewPath.appointmentclientside, this.token, undefined, true);
  }

  copyToClippboard() {
    navigator.clipboard.writeText(this.url);
    this.snackService.show("Enlace copiado en el portapapeles")
  }

  vehicleChange(event: MatSelectChange) {
    this.form.patchValue({ default_vehicle: event.value });
  }

}
