<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Facturación massiva de {{title}}</p>


<!-- Massive invoice dialog-->
<div mat-dialog-content [ngClass]="{'dn_i' : hasApiResponse}">
    <div class="eina-form-wrapper">
        <app-class-searcher-filter [ngClass]="{'dn_i' : data.client_id != undefined}" (onFilterChanges)="filter()"
            [filter]="clientFilter" [label]="false"></app-class-searcher-filter>
        <app-day-filter (onFilterChanges)="filter()" [filter]="dateFilter" [label]="false"></app-day-filter>
        <app-tagfilter *ngIf="data.type == 'or'" (change)="filter()" [filter]="OR_TYPE_FILTER"
            [label]="false"></app-tagfilter>
    </div>

    <app-card [noStyle]="true" [contentLoaded]="pageLoaded">
        <!-- Data -->

        <div *ngIf="billable.length;else noData">
            <ng-container *ngIf="filtered.length; else noDataByFilter">
                <mat-divider class="mt10 mb10"></mat-divider>
                <mat-checkbox (change)="selectUnselect()" [checked]="allSelecteds" [indeterminate]="someSelecteds">
                    {{allSelecteds ? 'Deseleccionar todo' : 'Seleccionar todo'}}
                </mat-checkbox>
            </ng-container>

            <mat-divider class="mt10 mb10"></mat-divider>
            <div *ngFor="let item of filtered">

                <!-- OR -->
                <ng-container *ngIf="isOR(item)">
                    <p class="nmb">
                        <mat-checkbox [checked]="isSelected(item)" (change)="addRemoveSelected(item)">
                            <div class="df aic gap5">
                                <span class="fw600" [ngClass]="isSelected(item) ? 'c_b' : 'c_p'">
                                    {{item.title}}
                                </span>
                                <app-or-type-label [tipo]="[item.type]" [wrapOn]="1"
                                    [showText]="false"></app-or-type-label>
                                <span class="ml5 mr5"></span>
                                <span class="c_p" money [val]="item.total"></span>
                            </div>
                        </mat-checkbox>
                    </p>
                    <p class="fss c_t2" style="margin-left: 40px;">
                        <span *ngIf="item.OR">{{item.OR}}</span>
                        <span class="c_t2 ml5 mr5"> | </span>
                        <span>{{item.created_at.dayMonthYearFormat()}}</span>
                    </p>
                </ng-container>

                <!-- ALBARANES -->
                <ng-container *ngIf="isAlbaran(item)">
                    <p class="nmb">
                        <mat-checkbox [checked]="isSelected(item)" (change)="addRemoveSelected(item)">
                            <div class="df aic gap5">
                                <span class="fw600"
                                    [ngClass]="isSelected(item) ? 'c_b' : 'c_p'">{{item.title_id}}</span>
                                <span class="c_t2 ml5 mr5">| </span>
                                <span class="c_p" money [val]="item.total"></span>
                            </div>
                        </mat-checkbox>
                    </p>
                    <p class="fss c_t2" style="margin-left: 40px;">{{item.created_at.dayMonthYearFormat()}}</p>
                </ng-container>

            </div>
        </div>
        <ng-template #noData>
            <p class="c_t2">¡Parece ser que estas al día! No hay {{title}} para facturar.</p>
        </ng-template>
        <ng-template #noDataByFilter>
            <p class="c_t2">Sin {{title}} para los filtros selccionados.</p>
        </ng-template>
    </app-card>
</div>


<div mat-dialog-actions [ngClass]="{'dn_i' : hasApiResponse}">
    <button color="primary" mat-flat-button (click)="invoiceMassive()" [disabled]="checkDisabled">Facturar</button>
</div>


<!-- Massive invoice result-->
<div mat-dialog-content [ngClass]="{'dn_i' : !hasApiResponse}">
    <ng-container *ngIf="apiResponse != undefined">

        <div *ngFor="let e of apiResponse.error" class="alert-warn">
            <mat-icon [filled]="true">warning</mat-icon>
            <p>{{e.message}}</p>
        </div>

        <ng-container *ngIf="apiResponse.invoiced.length">
            <p mat-dialog-subtitle>Facturas realizadas</p>
            <div class="bc_p0">
                <p *ngFor="let i of apiResponse.invoiced; let last = last" class="line-invoiced" [ngClass]="{'middle' : !last}" (click)="previewInvoice(i.invoice.token)">
                    <mat-icon class="vam">receipt_long</mat-icon>
                    <span>{{i.invoice.company_scope_id}}</span>
                    <span class="mla">
                        <span class="pdf-label"></span>
                    </span>
                </p>
            </div>
        </ng-container>

    </ng-container>
</div>

<div mat-dialog-actions [ngClass]="{'dn_i' : !hasApiResponse}">
    <button color="primary" mat-flat-button (click)="successClose()" [disabled]="checkDisabled">Ok</button>
</div>