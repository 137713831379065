<app-card-title>{{module.name}}</app-card-title>
<div class="module-card" [style]="'background-color: var(--' + module.cardColor +');'">
    <div class="module-header">
        <img class="module-img medium" [src]="module.img">
        <div class="mla">
            <button [disabled]="companyS.companyMissingInfo" *ngIf="!active" mat-flat-button color="primary"
                (click)="toggleModule()">Activar ahora</button>
            <button [disabled]="companyS.companyMissingInfo" *ngIf="active" mat-flat-button color="warn"
                (click)="toggleModule()">Quitar</button>
        </div>
    </div>
    <div class="module-desc">
        <p class="fw600 nmb">{{module.title}}</p>
        <p class="fss">{{module.desc}}</p>
    </div>
    <div class="module-bottom">
        <div class="module-funcionality" *ngFor="let funcionality of module.functionalities">
            <mat-icon class="mb10">{{funcionality.icon}}</mat-icon>
            <p class="module-funcionality-title">{{funcionality.title}}</p>
        </div>
    </div>
</div>