<app-card>
    <app-view-title>Días festivos</app-view-title>
    <app-card-title>{{hc.year}}</app-card-title>
    <app-holidays-calendar #hc 
        [prespective]="'company'" 
        [enabledFor]="enabledCalender"
        [showLegend]="false"
        [selfSave]="true"
        [showSaveButton]="false">
    </app-holidays-calendar>
</app-card>