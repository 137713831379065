<table mat-table [dataSource]="dataSource"  *ngIf="dataSource.data.length; else noData">

    <ng-container *ngIf="client != undefined && product == undefined" matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Referencia </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.product;else noRow" class="vam">{{element.product.reference}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="nif">
        <th mat-header-cell *matHeaderCellDef> NIF/DNI </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.client; else noRow" class="vam">{{element.client.nif}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef> Documento </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.document; else noRow" class="vam">{{element.document}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="quant_available">
        <th mat-header-cell *matHeaderCellDef> Disponible </th>
        <td mat-cell *matCellDef="let element"> 
            <span class="vam">{{element.quant_available}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="quant_fault" *ngIf="responsiveS.w > 500">
        <th mat-header-cell *matHeaderCellDef> Faltas </th>
        <td mat-cell *matCellDef="let element"> 
            <span class="vam">{{element.quant_fault}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="quant_delivered">
        <th mat-header-cell *matHeaderCellDef> Entregado </th>
        <td mat-cell *matCellDef="let element"> 
            <span class="vam">{{element.quant_delivered}}</span>
        </td>
    </ng-container>

 <ng-container matColumnDef="created_at" *ngIf="responsiveS.w > 500">
        <th mat-header-cell *matHeaderCellDef> Reserva realizada </th>
        <td mat-cell *matCellDef="let element"> 
            <span>{{element.created_at.dayMonthYearFormat()}}</span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="goDocument(row)"></tr>

</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" *ngIf="dataSource.data.length"></mat-paginator>


<!-- <div style="margin-top: 10px;">
    <button class="w100" mat-stroked-button (click)="goCreateProduct()" >
        <mat-icon>add</mat-icon>
        Crear reserva
    </button>
</div> -->
<ng-template #noRow class="mt10">
    <span class="c_t2">Sin datos</span>
</ng-template>

<ng-template #noData>
    <p *ngIf="reservations" class="c_t2">Este {{client ? "cliente": "producto"}} no tiene asociada ninguna reserva.</p>
</ng-template>
