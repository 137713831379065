import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { FilterEnum } from '../../../custom-classes/Filter';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.css']
})
export class FilterDialogComponent implements OnInit {
  fe = FilterEnum;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any[], private ref: MatBottomSheetRef<FilterDialogComponent>) { 
    this.ref.backdropClick().subscribe(() =>{
      this.close();
    })
  }
  ngOnInit(): void { }

  close() {
    this.ref.dismiss(this.data)
  }

}
