<div class="linkParent">

    <app-go-back [show]="ve != undefined" [text]="'Volver atrás'" (click)="returnClient()"></app-go-back>

    <app-view-title>Cambiar propietario</app-view-title>
    <div class="mt20">
        <app-card [contentLoaded]="ve != undefined" class="bc_b1">
            
            <ng-container *ngIf="ve">     
                <app-advanced-details [showButton]="false">
                    <app-advanced-title>
                        {{ve.getName()}}
                    </app-advanced-title>
                    <app-advanced-subtitle>
                        {{ve.license}}
                    </app-advanced-subtitle>
                </app-advanced-details>

                <app-advanced-details-footer [showTitle]="false">
                    <app-footer-right>
                        <app-contact-client-rectangle *ngIf="ve.client" [client]="ve.client"></app-contact-client-rectangle>
                    </app-footer-right>
                </app-advanced-details-footer>
            </ng-container>

        </app-card>
    </div>
    <div class="df jcc mb20 mt20"></div>
    <div class="mb20">
        <app-vehicle-owner-search-create #vosc [contentLoaded]="ve != undefined" [title]="'Nuevo propietario'"
            [isLinkVehicle]="true" [currentOwner_id]="ve?.client?.client_id">
            <div class="df jcc">
                <button subscribed-user mat-flat-button color="primary" style="margin-top: 10px"
                    [disabled]="!vosc.isValid" (click)="linkVehicle()">
                    Establecer nuevo propietario
                </button>
            </div>
        </app-vehicle-owner-search-create>
    </div>

</div>