import { Component, Inject } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { SotreHouseFormComponent } from '../sotre-house-form/sotre-house-form.component';
import { M_StoreHouse } from '../../models/M_StoreHouse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { FormService } from 'src/app/services/form.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-store-house-create-dialog',
  templateUrl: './store-house-create-dialog.component.html',
  styleUrls: ['./store-house-create-dialog.component.css']
})
export class StoreHouseCreateDialogComponent {

  constructor(public dialogRef: MatDialogRef<StoreHouseCreateDialogComponent>, private formS: FormService, private apiS: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: M_StoreHouse | undefined, private confirmD: ConfirmDialogService, private snackS: SnackService) { }

  createSotreHouse(component: SotreHouseFormComponent) {
    if (this.formS.isOk(component.form)) {
      let sh = new M_StoreHouse(component.form.value);
      this.dialogRef.close(sh);
    }
  }

  deleteStoreHouse() {
    if (!this.data) { return; }
    this.confirmD.show({
      title: "Eliminar almacén",
      body: "¿Está seguro de que quiere eliminar el almacén?",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res == true) {
        this.apiS.deleteStoreHouse(this.data!).then(res => {
          this.dialogRef.close();
          this.snackS.show("Almacén eliminado");
        })
      }
    })
  }
}