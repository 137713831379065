import { Component, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { VehicleOwnerSearchCreateComponent } from 'src/app/components/vehicle-owner-search-create/vehicle-owner-search-create.component';
import { VehicleformComponent } from 'src/app/components/vehicleform/vehicleform.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { FormService } from 'src/app/services/form.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['./create-vehicle.component.css']
})
export class CreateVehicleComponent extends ParameterStateComponent {

  @ViewChild(VehicleOwnerSearchCreateComponent) ownerComponent!: VehicleOwnerSearchCreateComponent;
  @ViewChild(VehicleformComponent, { static: true }) vehicleForm!: VehicleformComponent;
  @ViewChild(ClassSearcherComponent, { static: true }) searcherForm!: ClassSearcherComponent<M_Vehicle>;

  v = ViewPath;
  c: M_Contact | undefined;
  ve: M_Vehicle | undefined;
  state: "alta_vehiculo" | "create" | "edit" | undefined;
  isVehicle: boolean = false;
  isClient: boolean = false;
  constructor(private fs: FormService, routerS: RouterService, private paramS: ParamsService, private apiS: ApiService,
    route: ActivatedRoute, public params: ParamsService, private snackS: SnackService, public dialog: MatDialog,
    @Optional() public dialogRef: MatDialogRef<CreateVehicleComponent>) {
    super(routerS, route, ["client", "vehicle"]);
  }

  override noParams() {
    this.setUpCreation();
  }

  override onParam(k: string, v: any) {
    if (k == "client") {
      this.apiS.getClientById(v).then(res => {
        this.c = res;
        this.state = "alta_vehiculo";
        this.vehicleForm.form.get('battery_1_no')!.disable();
        this.vehicleForm.form.get('battery_2_no')!.disable();
      })
    }
    else if (k == "vehicle") {
      this.apiS.getVehicleById(v).then(res => {
        this.ve = res;
        this.state = "edit";
        this.setupEdit();
      })
    }
    else {
      this.setUpCreation();
    }
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  getTitle() {
    switch (this.state) {
      case "alta_vehiculo":
        return "Dar de alta un vehículo"
      case "create":
        return "Crear un vehículo"
      case "edit":
        return "Guardar"
    }
    return ""
  }

  getButton() {
    switch (this.state) {
      case "alta_vehiculo":
        return "Vincular nuevo vehículo"
      case "create":
        return "Crear vehículo"
      case "edit":
        return "Guardar"
    }
    return ""
  }

  /** Here, the default cration vehicle type */
  setUpCreation() {
    this.apiS.vehiclesType().then(res => {
      this.vehicleForm.form.get('battery_1_no')!.disable();
      this.vehicleForm.form.get('battery_2_no')!.disable();
      this.vehicleForm?.form.patchValue({ type: res });
      this.state = "create";
    })
  }

  setupEdit() {
    if (this.ve) {
      this.vehicleForm.patchFormWithVehicle(this.ve);
    }
  }

  create() {

    console.log("On create vehicle")

    this.vehicleForm.form.patchValue({ serie: this.vehicleForm.serieToggle.value() })
    console.log(this.vehicleForm.form);
    let vehicle = new M_Vehicle(this.vehicleForm.form.getRawValue());

    if (this.state == "create") {
      /** Si se linka el veículo con un cliente ya existente */
      if (this.ownerComponent.ch1?.checked) {
        if (this.fs.isOk(this.vehicleForm.form, this.ownerComponent.searchForm.form)) {
          this.apiS.vehicle.createVehicleWithClientId(new M_Vehicle(this.vehicleForm.form.value), this.ownerComponent.searchForm.selected!).then(res => {
            if (!this.isOnDialog) {
              this.params.go(this.v.vehicleDetails, res.vehicle_id)
              this.snackS.show("¡Vehículo creado y vinculado con éxito!")
            }
            else {
              vehicle.vehicle_id = res.vehicle_id;
              vehicle.client = this.ownerComponent.searchForm.selected;
              this.dialogRef.close(vehicle);
            }
          });
        }
      }

      /** Si se crea vehículo y cliente */
      else {
        let c = new M_Contact(this.ownerComponent.clientForm.form.value);
        if (this.fs.isOk(this.vehicleForm.form, this.ownerComponent.clientForm.form)) {
          c.vehicles = [new M_Vehicle(vehicle)];
          this.apiS.client.createClient(c).then(res => {
            if (!this.isOnDialog) {
              this.params.go(this.v.vehicleDetails, res.vehicle_id);
              this.snackS.show("¡Vehículo y cliente creados con éxito!")
            }
            else {
              vehicle.vehicle_id = res.vehicle_id;
              c.client_id = res.client_id;
              vehicle.client = c;
              this.dialogRef.close(vehicle);
            }

          })
        }
      }
    }

    /**Editamos solo la moto, sin cliente */
    else if (this.state == "edit") {
      if (this.fs.isOk(this.vehicleForm.form)) {
        this.apiS.vehicle.editVehicle(vehicle).then(_resp => {
          this.params.go(this.v.vehicleDetails, vehicle.vehicle_id);
          this.snackS.show("¡Vehículo editado con éxito!")
        })
      }
    }

    /** Dar de alta un vehículo a un cliente especifico */
    else if (this.state == "alta_vehiculo") {
      if (this.fs.isOk(this.vehicleForm.form)) {
        vehicle.client_id = this.c?.client_id;
        this.apiS.vehicle.editVehicle(vehicle).then(_resp => {
          this.params.go(this.v.contactDetails, this.c!.client_id)
          this.snackS.show("¡Vehículo añadido con éxito!")
        })
      }
    }
  }

  goBackVehicle() {
    if (!this.ve) { return; }
    this.paramS.go(ViewPath.vehicleDetails, this.ve.vehicle_id);
  }

  get buttonDisabled() {
    let isOk = this.vehicleForm.form.valid && this.ownerComponent.isValid;
    return !isOk;
  }

}