import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoggerService } from '../../services/logger.service';
import { TerminosCondicionesComponent } from '../terminos-condiciones/terminos-condiciones.component';

export interface FirmaData {
  title?: string,
  subtitle?: string,
  showTerms?: boolean,
  repeatText?: string
  showActions?: boolean
  pencilColor?: string,
  background?: string,
}


/**
 * @Inject(MAT_DIALOG_DATA) public data?: { background: string | undefined, showTerms: boolean | undefined }
 */
@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.css']
})
export class FirmaComponent implements OnInit {

  @ViewChild('firmaParent', { static: true }) firmaParent!: ElementRef<HTMLDivElement>;
  @ViewChild('signPad', { static: false }) signPad!: ElementRef<HTMLCanvasElement>;
  @ViewChild("tyc") tyc!: MatCheckbox;
  @Input() singatureData?: FirmaData;
  @Output() onSign: EventEmitter<File | undefined> = new EventEmitter();


  dialogResult: (string | File)[] | undefined[] = [undefined, undefined];
  private context!: CanvasRenderingContext2D;
  private canvasRef: HTMLCanvasElement | undefined;
  private sigPadElement: any;
  private isDrawing!: boolean;
  public showTermsLogic: boolean = true;
  public reInitOnChange: boolean = false;

  constructor(private dialog: MatDialog,
    private logger: LoggerService,
    @Inject(MAT_DIALOG_DATA) public data?: FirmaData) {
  }

  ngOnInit(): void {
    if (this.singatureData) {
      this.data = this.singatureData;
    }
    if (this.data && this.data.showTerms != undefined) {
      this.showTermsLogic = this.data.showTerms;
    }

    this.resizeCanvas();

    window.addEventListener('mouseup', () => {
      this.drawComplete();
    })

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.reInitOnChange){
      this.fitCanvasToParent();
    }
  }

  ngAfterViewInit() {
    this.sigPadElement = this.signPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#2d43ba';
    if (this.data?.pencilColor) {
      this.context.strokeStyle = this.data.pencilColor;
    }
    this.context.lineWidth = 3.5;
    this.context.lineCap = "round";
    this.clearSignature();
    this.setBackgroundImage();
  }

  setBackgroundImage() {
    if (this.data?.background) {
      let exampleImage = new Image();
      exampleImage.onload = () => {
        this.context.drawImage(exampleImage, 0, 0, this.context.canvas.width, this.context.canvas.height);  // draw duck        
      };
      exampleImage.src = this.data.background; //"/assts/img/something.png"
    }
  }

  /**
   * Debido a que el componente de la firma se genera posteriormente, no podemos asociarle un estilo por defecto.
   * Es necesario establecer que la altura y anchura del canvas se corresponde con la altura/anchura de su elemento html padre.
   */
  resizeCanvas() {
    if (this.firmaParent) {
      this.canvasRef = this.firmaParent.nativeElement.getElementsByTagName("canvas")[0];
      this.firmaParent.nativeElement.setAttribute("style", "height : " + this.firmaParent.nativeElement?.offsetWidth + "px; max-height: 50vh;")
      this.firmaParent.nativeElement.setAttribute("overflow", "hidden;")
    }
    this.fitCanvasToParent();
  }

  fitCanvasToParent() {
    if (this.firmaParent && this.canvasRef) {
      if (this.firmaParent.nativeElement.offsetHeight == 0 || this.firmaParent.nativeElement.offsetWidth == 0) {
        this.reInitOnChange = true;
      }
      else {
        this.canvasRef.setAttribute('height', this.firmaParent.nativeElement.offsetHeight.toString());
        this.canvasRef.setAttribute('width', this.firmaParent.nativeElement.offsetWidth.toString());
        this.reInitOnChange = false;
      }
    }
  }

  /**Cada vez que se levanta el el dedo al hacer la firma, guardamos la imagen */
  drawComplete() {
    if (this.isDrawing) {
      this.isDrawing = false;
      this.drawResults();
    }
  }

  drawResults() {

    this.logger.log("Singature results")

    /**Base 64 para mostrar la firma por pantalla */
    const base64Data = this.sigPadElement.toDataURL('image/png');
    const signatureImg = base64Data;

    /**Conversión a 'File' para posteriormente poder guardar la imagen */
    const signatureFile = this.base64ToFile(base64Data);
    this.onSign.emit(signatureFile);
    this.dialogResult = [signatureImg, signatureFile];
    return this.dialogResult;
  }

  /** Conversión de una imagen en Base64 a un archivo tipo 'File' */
  base64ToFile(dataurl: string) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'firma', { type: mime });
  }

  /**Funcion para borrar la firma */
  clearSignature() {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
    this.setBackgroundImage();
    this.onSign.emit(undefined);
  }

  openTerminosyCondiciones(event: any, tyc: MatCheckbox) {
    tyc.checked = !tyc.checked;
    event.stopPropagation()
    const dref = this.dialog.open(TerminosCondicionesComponent, { autoFocus: false, maxHeight: '90vh' });
    dref.afterClosed().subscribe(result => {
      this.tyc.checked = result;
    })
  }


  /** NEW FUNCTIONS */
  onMouseDown(e: any): void {
    // The mouse button is clicked, which means the start of drawing the signature
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
    e.preventDefault();
  }

  onMouseMove(e: any): void {
    if (this.isDrawing) { // if we're not drawing we need to ignore the events
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
    e.preventDefault();
  }

  private relativeCoords(event: any): { x: number, y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return { x, y };
  }

  getDisabled(checkbox: MatCheckbox | undefined) {
    if (!this.showTermsLogic || !checkbox) {
      return false;
    }
    return !checkbox.checked;
  }

}
