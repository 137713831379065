import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { MenuComponent } from '../menu/menu.component';
import * as LogRocket from 'logrocket';
import { SubscriptionService } from '../../services/EinaMainData/subscription.service';
import { feature } from '../../utils/FeaturesController';
import { OrTimerService } from '../../services/or-time.service';
import { UserService } from '../../services/EinaMainData/user.service';
import { OnboardingComponent } from '../onboarding/onboarding.component';
import { RouterService } from 'src/app/services/router.service';
import { SessionService } from 'src/app/services/session.service';
import { INotification } from 'src/app/interfaces/INotification';
import { M_TicketNotificacion } from 'src/app/models/M_TicketNotificacion';
import { CreateTicketDialog } from '../create-ticket-dialog/create-ticket-dialog';
import { EinaDataService } from 'src/app/services/EinaMainData/eina-data.service';
import { roleGroup } from 'src/app/enums/RolesEnum';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})

export class ToolbarComponent implements OnInit {
  f = feature;
  v = ViewPath;
  q: number = 0;
  @Input() mc!: MenuComponent;
  @Input() onboarding!: OnboardingComponent;
  @Input({ required: true }) showMenuButton!: boolean;
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();

  constructor(public subS: SubscriptionService, public routerS: RouterService, private sessionS: SessionService,
    public apiS: ApiService, public userS: UserService, private d: MatDialog, public timerS: OrTimerService,
    private chdRef: ChangeDetectorRef, private einaDataS: EinaDataService) { }

  ngOnInit(): void {

    this.timerS.makeCall().then(() => {
      this.chdRef.detectChanges();
    });

    if (feature.logRocket) {
      LogRocket.identify(this.userS.userId!.toString(), {
        name: this.userS.userName,
        email: this.userS.userId
      });
    }

  }

  goProfile() {
    this.routerS.goTo(this.v.profile);
  }

  openIssueDialog() {
    this.d.open(CreateTicketDialog, { data: { view: ViewPath.issues, showGoIssues: true }, autoFocus: false });
  }

  notificationAction(not: INotification) {
    if (not instanceof M_TicketNotificacion) {
      this.routerS.goWithQueryParams(ViewPath.issues, { ct_id: not.ct.id, ct_proj: not.ct.project });
    }
  }

  logOut() {
    this.apiS.profile.logout().then(res => {
      this.sessionS.logOut();
      this.einaDataS.destroyData();
    })
  }

  showBigMamaByRoleAndView() {
    let role = this.userS.getRole();
    if (!role) { return true; }
    if (roleGroup.bigMamaAsDashboard.includes(role) && this.routerS.is(ViewPath.dashboard)) { return false };
    return true;
  }

}
