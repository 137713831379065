import { Component, Input } from '@angular/core';
import { Views } from '../../custom-classes/View';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.css']
})
export class GoBackComponent {

  @Input() v!: Views;
  @Input() show: boolean = true;
  @Input() text: string = "Atrás"
  constructor(private routerS: RouterService) { }
  action() {
    var previous = this.routerS.getViewFromRoute(this.routerS.previousRoute);
    if (this.v) {
      if (previous && previous == this.v) {
        this.routerS.back();
      }
      else {
        this.routerS.goTo(this.v);
      }
    }
    else {
      console.log("No go back action, add a custom with onclick...")
    }
  }
}
