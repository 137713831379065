<app-card [noStyle]="true">
    <app-card-title>Filtros</app-card-title>
    <div *ngFor="let f of data">
        <app-day-filter             *ngIf="f.filterId == fe.DAY"                [filter]="f"></app-day-filter>
        <app-slider-filter          *ngIf="f.filterId == fe.SLIDER"             [filter]="f"></app-slider-filter>
        <app-enum-filter            *ngIf="f.filterId == fe.ENUMFILTER"         [filter]="f"></app-enum-filter>
        <app-tagfilter              *ngIf="f.filterId == fe.TAGFILTER"          [filter]="f"></app-tagfilter>
        <app-text-filter            *ngIf="f.filterId == fe.TEXTFILTER"         [filter]="f"></app-text-filter>
        <app-user-filter            *ngIf="f.filterId == fe.USERFILTER"         [filter]="f"></app-user-filter>
        <app-checkbox-filter        *ngIf="f.filterId == fe.CHECKBOXFILTER"     [filter]="f"></app-checkbox-filter>
        <app-class-searcher-filter  *ngIf="f.filterId == fe.CLASSSEARCHER"      [filter]="f"></app-class-searcher-filter>
        <app-button-toggle-filter   *ngIf="f.filterId == fe.BUTTONTOGGLEFILTER" [filter]="f"></app-button-toggle-filter>
    </div>
    <div class="df jcc mt20">
        <button mat-flat-button [color]="'primary'" (click)="close()">Aplicar filtros</button>
    </div>
</app-card>