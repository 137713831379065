
import { Component, ElementRef, Inject, Injectable, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SingleImgComponent } from './single-img/single-img.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomFile } from '../../custom-classes/CustomFile';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { UploadService } from '../../services/upload.service';
import { NavigationStart, Router } from '@angular/router';
import { LoadingPanelService } from 'src/app/services/LoadingPanel/loading-panel.service';



/** Service to open the MultiplePhoneImage dialog */
@Injectable({
  providedIn: 'root'
})
export class MultiplePhoneImageService {

  constructor(private d: MatDialog, private ddS: DeviceDetectorService, private loadingP: LoadingPanelService) { }

  async open(fuc: FileUploadComponent) {

    /** Early exit conditions */
    if (!this.ddS.isMobile() && !this.ddS.isTablet()) {
      //console.log("No camera device");
      this.normalInputbehavior(fuc);
      return;
    }

    /** Request camera permisions. */
    this.loadingP.show();
    const res = await this.requestCamera();
    if (res != 'allowed') {
      //console.log("No access to the camera. Details :", res)
      this.normalInputbehavior(fuc);
    }
    else {
      this.getMediaStream().then(
        res => {
          this.loadingP.hide();
          return this.d.open(MultiplePhoneImageComponent,
            {
              width: '100vw',
              height: '100vh',
              maxHeight: '100vh',
              maxWidth: '100vw',
              panelClass: "no-padding",
              disableClose: true,
              data: { stream: res, fuc: fuc } //Open the dialog with the stream and the FileUploadComponent object
            });
        })
        .catch(res => {
          //console.log(res);
          this.normalInputbehavior(fuc);
        })
    }
  }

  /** This is the typical browser pop up */
  requestCamera(): Promise<"allowed" | "notAllowed" | "notFounded" | "notSpecified"> {
    return new Promise((resolve) => {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((_res) => {
          resolve("allowed");
        })
        .catch((_e: DOMException) => {
          let noCamera = _e.name == "NotFoundError";
          let notAllowed = _e.name == "NotAllowedError";
          resolve(noCamera ? "notFounded" : notAllowed ? "notAllowed" : "notSpecified");
        });
    });
  }

  /** Gets the MediaStream object to display the camera on the <video></video> element */
  private getMediaStream(): Promise<MediaStream | { title: string, detail: string }> {
    const video_constraints = { video: { facingMode: { ideal: "environment" } } };
    return new Promise<MediaStream>((resolve, reject) => {
      return navigator.mediaDevices.
        getUserMedia(video_constraints)
        .then(stream => {
          (<any>window).stream = stream;
          return resolve(stream);
        })
        .catch(err => { reject({ title: "Algo ha salido mal al abrir la cámara", detail: err }); }
        );
    });
  }

  /** If the user device don't meet te rqueirements to display the camera, do the normal input (FileUploadComponent) behavior */
  private normalInputbehavior(fuc: FileUploadComponent) {
    this.loadingP.hide();
    fuc.input.nativeElement.click();
  }
}

/**
 * It is assumed that if this component is displayed, you have a camera available with the appropriate permissions.
 * This component, also recives the MediaStream object on the dialog params.
 * This component only upload CustomFiles without ID!!
 */

@Component({
  selector: 'app-multiple-phone-image',
  templateUrl: './multiple-phone-image.component.html',
  styleUrls: ['./multiple-phone-image.component.css']
})

export class MultiplePhoneImageComponent implements OnInit {

  @ViewChild("videoElement", { static: true }) video!: ElementRef<HTMLVideoElement>;
  @ViewChild("scanContent") content!: ElementRef;
  @ViewChildren(SingleImgComponent) singleImages?: QueryList<SingleImgComponent>;
  selectedIndex = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { stream: MediaStream, fuc: FileUploadComponent }, public dialogRef: MatDialogRef<MultiplePhoneImageComponent>,
    private uploadS: UploadService, private router: Router) {

    /** Enbale the component phone feature. 
     *  This is for modify the normal behavior of the component. */
    this.data.fuc.phoneMultiplUploadFeature = true;

    /** File uploaded with gallery */
    this.data.fuc.onPhoneUpload.subscribe(cf => {
      this.setImage(cf);
    });

    /** When the route changes, stop the stream */
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.stopVideoStream();
      }
    })
  }

  ngOnInit(): void {
    this.initVideo();
  }

  ngAfterViewInit() {
    this.initVideo();
    this.initializeImages();
  }

  ngOnDestroy() {
    //console.log("Destroying stream object");
    this.stopVideoStream();
  }

  /** Put the necessary properties in the video tag.*/
  initVideo() {
    const _video = this.video.nativeElement;
    _video.srcObject = this.data.stream;
    _video.setAttribute("playsinline", 'true'); //iOS !!
    _video.onloadedmetadata = function (_e: any) { };
    _video.play();
  }

  /** Set the current uploaded files on the "bottom-pannel" */
  initializeImages() {
    if (Array.isArray(this.data.fuc.uploadedFiles)) {
      this.data.fuc.uploadedFiles.forEach(f => {
        this.selectedImg?.initializeImage(f)
        this.incrementSelectedIndex()
      })
    }
  }

  /** Stops the current stream */
  stopVideoStream() {
    if (this.data.stream && this.data.stream.getTracks()) {
      this.data.stream.getTracks().forEach(track => {
        track.stop();
        this.data.stream?.removeTrack(track)
      });
    }
  }

  /** If the CustomFile has ID, call the FileUploadComponent method to remove a file */
  onDeleteImg(cf: CustomFile) {
    if (cf.id != undefined) {
      this.data.fuc.uploadS.removeFile(cf.id, this.data.fuc);
    }
  }

  /** Take a picture */
  takePicture() {
    // Get the video element and canvas element
    const canvas = document.createElement("canvas");
    let h = this.video.nativeElement.videoHeight;
    let w = this.video.nativeElement.videoWidth;
    canvas.width = w;
    canvas.height = h;
    // Draw the current video frame onto the canvas
    canvas!.getContext('2d')!.drawImage(this.video.nativeElement, 0, 0, canvas.width, canvas.height);
    // Get the data URL of the canvas content
    const dataUrl = canvas.toDataURL('image/jpeg');
    // Set the image
    this.setImage(new CustomFile(dataUrl, undefined));
  }

  /** Set a image and increment the current selected index */
  setImage(src: CustomFile) {
    this.selectedImg?.setImage(src)
    this.incrementSelectedIndex()
  }

  /** Select the next image */
  incrementSelectedIndex() {
    if (this.data.fuc.maxImagesLength) {
      this.selectedIndex++;
      if (this.selectedIndex > this.data.fuc.maxImagesLength - 1) {
        this.selectedIndex = 0;
      }
    }
  }

  /** Returns the index of the selected image */
  get selectedImg() {
    return this.singleImages?.get(this.selectedIndex);
  }

  /** Close dialog with the custom files */
  userPressOk() {
    this.singleImages?.forEach(i => {
      if (i.hasImage) {
        this.uploadS.multiplePhoneUpload(i.source, this.data.fuc);
      }
    })
    this.close();
  }

  /** Always, call this function to close the dialog. */
  close() {
    this.data.fuc.phoneMultiplUploadFeature = false;
    this.dialogRef.close();
  }

}