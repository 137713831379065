import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { M_Product } from '../../models/Products/M_Product';
import { M_StoreHouse } from '../../models/M_StoreHouse';
import { MatSelectChange } from '@angular/material/select';
import { M_Location } from '../../models/M_Location';
import { ApiService } from 'src/app/services/Api/api.service';
import { AbstractControl } from '@angular/forms';


@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.css']
})
export class SelectLocationComponent {

  @Input({ required: true }) control!: AbstractControl<any, any>;
  @Input() label: string | undefined;

  //En caso que le pasemos Product
  @Input() product?: M_Product;
  selectedLocation: M_Location | undefined
  p: M_Product | undefined

  //En caso que le pasemos Stores(almacén)
  @Input() stores?: M_StoreHouse[] = [];
  selectedStore: M_StoreHouse | undefined;
  selectedLocationStore: M_Location | undefined

  @Output() onSelectLocation: EventEmitter<M_Location> = new EventEmitter();;

  constructor(private cdr: ChangeDetectorRef, public apiS: ApiService) { }

  ngAfterViewInit() {
    if (this.product && this.product.selectedLocation) {
      this.selectLocation(this.product.selectedLocation);
    }
  }

  onSelectLocationByProduct(event: MatSelectChange) {
    this.selectLocation(event.value)
  }

  private selectLocation(location: M_Location) {
    this.selectedLocation = location;
    this.control.setValue(location.id);
    this.cdr.detectChanges();
    this.onSelectLocation.emit(this.selectedLocation);
  }

  //En caso que le pasemos Stores(almacén)
  onSelectLocationByStore(event: MatSelectChange) {
    this.selectedLocationStore = event.value;
    if (this.selectedLocationStore != undefined) {
      let location = this.selectedLocationStore;
      this.selectedStore = this.stores!.find(store => store.locations.includes(location));
      this.control.setValue(this.selectedStore?.id);
    }
    this.cdr.detectChanges();
  }

  get disp() {
    return this.selectedLocation?.disp || 0;
  }


}
