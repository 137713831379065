<app-card [nopadding]="true" class="company-parent-card">
    <div class="df">
        <mat-tab-group class="vertical-tabs">
            <mat-tab *ngFor="let center of companyS.company.centers">
                <ng-template mat-tab-label>
                    <mat-icon>location_away</mat-icon>
                    {{center.name}}
                </ng-template>
                <app-center-form [center]="center"></app-center-form>
            </mat-tab>
            <mat-tab (click)="createCenter($event)">
                <ng-template mat-tab-label>
                    <mat-icon>add</mat-icon>
                    Añadir centro
                </ng-template>
                <app-center-form [center]="undefined"></app-center-form>
            </mat-tab>
        </mat-tab-group>
    </div>
</app-card>