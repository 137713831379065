<app-page-structure [masterClass]="'invoice'" pageTitle="Ventas" [autocompleteID]="'bills'"
    searchLabel="Buscar facturas"
    listTitleText="Listado de facturas" noDataCreateNew="Crear una nueva factura"
    [data]="[]"
    [displayedHeader]=" ['Número',              'Fecha facturación',   'Cliente',     'Total',          'Tipo factura',   'Estado' ]"
    [displayedColumns]="[ 'company_scope_id',   'created_at',    'client',      'total',          'invoice_type_or_type',   'state']"
    [preIcon]="[invoiceIcon, undefined]" 
    [specialText]="     [ undefined,            undefined,    getClientName,  getTotal,         undefined,          undefined,   undefined]"
    [specialClass]="    [ undefined,            undefined,      undefined,      undefined,       undefined,          undefined,   undefined]"
    [cellZize]="        ['big',    undefined,   undefined,   'small',     undefined, undefined]"
    [rightMenu]="rightMenu" [filters]="filtersToShow" [filter]="f" (onSelect)="this.resetPaymentForm()"
    (onclickRow)="paramsS.go(v.invoiceDetails, $event.id)"
    [createButton]="
        {
            text : 'Crear factura' , 
            icon : 'receipt_long', 
            view : v.createInvoice, 
            tooltip : isDraft ? 'Borrador en curso' : '',
            loading : isCircleLoading,
            cssClass : 'onboarding-create-invoice ' + (isDraft ? 'draft' : '')
        }" 
    [quickFilter]="quickFilter">
</app-page-structure>


<ng-template #rightMenu>
    <div *ngIf="ps">
        <button *ngIf="ps.loaded_" mat-menu-item class="onboarding-checkout-control"
            (click)="openDialog()"><mat-icon>point_of_sale</mat-icon>
            Control de caja
        </button>
        <app-skeleton *ngIf="!ps.loaded_" [width]="176" [height]="36"></app-skeleton>
        <div style="margin-left: auto;"
            [matTooltip]="disableMax(ps.dataOnScreen) ? 'Máximo ' + MAX_DOWNLOAD_NUMBER + ' facturas' : disableMin(ps.dataOnScreen) && ps.loaded_ ? 'Mínimo una factura' : ''">
            <button [disabled]="disabledDownload(ps)" *ngIf="ps?.loaded_" mat-menu-item color="secondary"
                (click)="exportInvoices(ps.dataOnScreen)">
                <mat-icon>library_books</mat-icon>
                Exportar a Excel
            </button>
            <button *ngIf="true && ps?.loaded_" mat-menu-item [disabled]="disabledDownload(ps)"
                (click)="donwloadInvoices(ps.dataOnScreen)">
                <mat-icon>download</mat-icon>
                Descargar PDF ({{ps.dataOnScreen.length}})
            </button>
            <app-skeleton *ngIf="!ps.loaded_" [width]="176" [height]="36"></app-skeleton>
        </div>
    </div>
</ng-template>