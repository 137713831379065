import { M_TypePayment } from "./M_TypePayment";
import { LastMovementType } from "../enums/LastMovementType";
import { M_HistoryAdvances } from "./M_HistoryAdvances";
import { getArrayOf } from "../utils/FunctionUtils";

export class M_LastMovements {
    id: number;
    concept: string;
    import: number;
    invoice_id: number;
    client_id: number;
    type_payment_id: number;
    created_at: Date;
    type_payment: M_TypePayment;
    type: LastMovementType;
    history_advances: M_HistoryAdvances[];
    import_origin: number;
    deleted: boolean; //Abono is 'used'
    id_movement:string;
    abono: boolean;
    id_advance : number | undefined;
    id_buytransac : number | undefined;
    constructor(d: any) {
        this.id = d.id;
        this.concept = d.concept;
        this.import = d.import;
        this.invoice_id = d.invoice_id;
        this.client_id = d.client_id;
        this.type_payment = new M_TypePayment(d.type_payment);
        this.type_payment_id = this.type_payment.id;
        this.type = d.type;
        this.history_advances = getArrayOf(M_HistoryAdvances, d.history_advances);
        this.import_origin = d.import_origin;
        this.deleted = d.deleted;
        this.created_at = new Date(d.created_at);
        this.abono = d.abono;
        this.id_advance = d.id_advances;
        this.id_buytransac = d.id_buytransac;
        if(this.isAdvance){
            this.id_movement= d.id_movement;
        }else{
            this.id_movement = "";
        }
    }

    get isAdvance() {
        return this.type == LastMovementType.type_advances;
    }


}