<ng-container *ngIf="isOnDialog">
    <app-close-dialog-button></app-close-dialog-button>
    <p mat-dialog-title>{{title}}</p>
</ng-container>


<div class="create-item create-product-form" mat-dialog-content
    [ngClass]="!isOnDialog ? 'conditional-mat-dialog-content' : ''">
    <app-go-back *ngIf=" p != undefined && !isOnDialog" [show]="isEdit && !isOnDialog" [text]="p!.name"
        (click)="goBackProduct()"></app-go-back>
    <app-card [contentLoaded]="loaded" [noStyle]="isOnDialog">
        <app-card-title *ngIf="!isOnDialog">{{title}}</app-card-title>
        <p *ngIf="isOnDialog && data && data.product != undefined" class="fw600"
            [ngClass]="data.product.price != p?.price ? 'c_r' : 'c_g'">PVP imputado :
            <span money [val]="data.product.price"></span>
        </p>
        <p *ngIf="isOnDialog && data?.product == undefined" class="fw600"></p>
        <form [formGroup]="form">
            <div class="form">
                <div class="onboarding-product-required">
                    <app-card-subtitle [first]="true">Datos obligatorios</app-card-subtitle>
                    <div class="eina-form-wrapper">
                        <div class="df">
                            <mat-form-field [ngClass]="companyS.recambiosModule ? 'w100' : 'w50'" appearance="outline">
                                <app-async-validator-spinner matSuffix
                                    [control]="form.get('reference')"></app-async-validator-spinner>
                                <mat-label>Referencia</mat-label>
                                <input matInput placeholder="Referencia" formControlName="reference">
                                <mat-error *ngIf="form.get('reference')?.getError('ref-exists')">Esta referencia ya
                                    existe.</mat-error>
                            </mat-form-field>
                            <div class="w50" *ngIf="!companyS.recambiosModule">
                                <app-add-remove [form]="form" [formCName]="'stock'" [showSaveIcon]="false"
                                    [total]="this.p? this.p.stock : 0"></app-add-remove>
                            </div>

                            <div class="w50" *ngIf="companyS.recambiosModule && !isEdit">
                                <app-add-remove [form]="form" [formCName]="'stock'" [showSaveIcon]="false"
                                    [total]="this.p? this.p.stock : 0"></app-add-remove>
                            </div>
                        </div>

                        <div class="df">
                            <mat-form-field class="w50" appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input matInput placeholder="Nombre" formControlName="name">
                            </mat-form-field>
                            <mat-form-field class="w50" appearance="outline">
                                <mat-label>IVA del producto</mat-label>
                                <mat-select formControlName="tax">
                                    <mat-option [value]="0">Sin IVA</mat-option>
                                    <mat-option [value]="4">4%</mat-option>
                                    <mat-option [value]="10">10%</mat-option>
                                    <mat-option [value]="21">21%</mat-option>
                                </mat-select>
                                <mat-hint>IVA por defecto : {{companyS.companyTax == 0 ? 'Sin IVA' :
                                    companyS.companyTax}}</mat-hint>
                            </mat-form-field>
                        </div>

                        <div [ngClass]="{'w33p' :companyS.recambiosModule}">
                            <mat-form-field [ngClass]="companyS.recambiosModule ? 'w33': 'w50'" appearance="outline">
                                <mat-label>Coste</mat-label>
                                <input type="number" min="0" matInput [placeholder]="'Coste'"
                                    formControlName="buy_price">
                                <mat-error
                                    *ngIf="this.form.get('buy_price')?.hasError('required') || this.form.get('buy_price')?.hasError('min')">
                                    El valor debe ser mayor o igual que 0
                                </mat-error>
                                <mat-error *ngIf="this.form.get('buy_price')?.hasError('max')">Número demasiado
                                    grande</mat-error>
                            </mat-form-field>
                            <mat-form-field [ngClass]="companyS.recambiosModule ? 'w33': 'w50'" appearance="outline">
                                <mat-label>PVP</mat-label>
                                <input type="number" min="0" matInput placeholder="PVP" formControlName="price">
                                <mat-error
                                    *ngIf="this.form.get('price')?.hasError('required') || this.form.get('buy_price')?.hasError('min')">
                                    El valor debe ser mayor o igual que 0
                                </mat-error>
                                <mat-error *ngIf="this.form.get('price')?.hasError('max')">Número demasiado
                                    grande</mat-error>
                                <mat-hint
                                    *ngIf="form.get('price')?.value && form.get('price')?.value < form.get('buy_price')?.value">
                                    <mat-icon class="vam aic fs10 c_r"
                                        style="font-size: 20px; margin-bottom: 5px;">warning</mat-icon>
                                    El PVP es más pequeño que el coste.
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field recambios [ngClass]="companyS.recambiosModule ? 'w33': 'w50'"
                                appearance="outline">
                                <mat-label>PVP tarifa</mat-label>
                                <input type="number" min="0" matInput placeholder="PVP" formControlName="pvp_t">
                                <mat-error
                                    *ngIf="this.form.get('pvp_t')?.hasError('required') || this.form.get('pvp_t')?.hasError('min')">
                                    El valor debe ser mayor o igual que 0
                                </mat-error>
                                <mat-error *ngIf="this.form.get('pvp_t')?.hasError('max')">Número demasiado
                                    grande</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <app-image-toggle #imageToggle [form]="form" [default]="true" [formCName]="'category'"
                        (onToggle)="resetExtraField()">
                        <app-img-toggle-item [text]="'General'" [formValue]="productCategory.GENERAL">
                            <mat-icon [svgIcon]="'barcode'"></mat-icon>
                        </app-img-toggle-item>

                        <app-img-toggle-item [text]="'Recambios'" [formValue]="productCategory.WRENCH">
                            <mat-icon>build_circle</mat-icon>
                        </app-img-toggle-item>

                        <app-img-toggle-item [text]="'Neumáticos'" [formValue]="productCategory.WHEEL">
                            <mat-icon>tire_repair</mat-icon>
                        </app-img-toggle-item>

                        <app-img-toggle-item [text]="'Electrónica'" [formValue]="productCategory.LIGHT">
                            <mat-icon>lightbulb</mat-icon>
                        </app-img-toggle-item>

                        <app-img-toggle-item [text]="'Aceite'" [formValue]="productCategory.LIQUID">
                            <mat-icon>water_drop</mat-icon>
                        </app-img-toggle-item>

                        <app-img-toggle-item [text]="'Boutique'" [formValue]="productCategory.BOUTIQUE">
                            <mat-icon>checkroom</mat-icon>
                        </app-img-toggle-item>


                        <!-- LIQUID EXTRA FIELD (SIGAUS)-->
                        <mat-form-field appearance="outline" *ngIf="isCategory(productCategory.LIQUID)">
                            <mat-label>Litros del producto</mat-label>
                            <mat-icon matPrefix>water_drop</mat-icon>
                            <input matInput type="number" placeholder="Litros del producto"
                                formControlName="extra_field">
                            <mat-hint *ngIf="!form.get('extra_field')?.value">Si está vacío, se asume como 1L</mat-hint>
                            <mat-hint *ngIf="form.get('extra_field')?.value">Precio por litro actual :
                                <span money [val]="SIGAUS"></span>
                                <p class="c_b nmb"><a href="https://www.sigaus.es/factura-del-taller" target="_blank"
                                        rel="noopener noreferrer">Mas info <mat-icon
                                            class="c_b">open_in_new</mat-icon></a>
                                </p>
                            </mat-hint>
                        </mat-form-field>

                        <!-- WHEEL EXTRA FIELD (SIGNUS)-->
                        <mat-form-field appearance="outline" *ngIf="isCategory(productCategory.WHEEL)">
                            <mat-label>Categoría</mat-label>
                            <mat-icon matPrefix>tire_repair</mat-icon>
                            <mat-select formControlName="extra_field">
                                <mat-option *ngFor="let signus of SIGNUS_ARRAY" [value]="signus.id">
                                    {{signus.name}}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="!getSignus()">Si está vacío, se asume como N1</mat-hint>
                            <mat-hint *ngIf="getSignus() as signus">El precio actual de {{signus.name}} es de <span
                                    money [val]="signus.price"></span>
                                <p class="c_b nmb"><a href="https://www.signus.es/tarifa-ecovalor" target="_blank"
                                        rel="noopener noreferrer">Mas info <mat-icon
                                            class="c_b">open_in_new</mat-icon></a>
                                </p>
                            </mat-hint>
                        </mat-form-field>
                    </app-image-toggle>
                    <div [ngTemplateOutlet]="submitButton" *ngIf="!isOnDialog && !companyS.recambiosModule"></div>
                </div>
            </div>
        </form>
    </app-card>

    <div class="m20" *ngIf="companyS.recambiosModule && !isOnDialog"></div>

    <app-card [contentLoaded]="loaded" *ngIf="companyS.recambiosModule && !isOnDialog">
        <app-card-title>
            <img class="module-img small vam" src="./assets/img/modules/module-1.png">
            Módulo de recambios
        </app-card-title>
        <form [formGroup]="form" *ngIf="companyS.recambiosModule">
            <app-card-subtitle [first]="true">Datos de stock</app-card-subtitle>
            <div class="eina-form-wrapper">
                <div class="df">
                    <mat-form-field class="w66" appearance="outline">
                        <mat-label>Referencia Fabricante</mat-label>
                        <input type="text" matInput placeholder="Referencia Fabricante" formControlName="ref_factory">
                    </mat-form-field>
                    <mat-slide-toggle #control_stock formControlName="control_stock" class="stock-toggle w33"
                        (change)="controlStockSelected($event)">
                        <p class="nmb">Control de stock</p>
                    </mat-slide-toggle>
                </div>

                <div class="df">
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Stock mínimo</mat-label>
                        <input type="number" min="0" [defaultValue]="0" matInput placeholder="Stock mínimo"
                            formControlName="stock_min">
                    </mat-form-field>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Stock máximo</mat-label>
                        <input type="number" min="0" [defaultValue]="0" matInput placeholder="Stock máximo"
                            formControlName="stock_max">
                    </mat-form-field>
                </div>

                <div class="w33p">
                    <mat-form-field class="w33" appearance="outline">
                        <mat-label>Grupo contable</mat-label>
                        <input type="text" matInput placeholder="Grupo contable" formControlName="acc_group">
                    </mat-form-field>
                    <mat-form-field class="w33" appearance="outline">
                        <mat-label>Preobsolescencia (meses)</mat-label>
                        <input type="number" min="0" matInput placeholder="Preobsolescencia"
                            formControlName="preobsolete">
                    </mat-form-field>
                    <mat-form-field class="w33" appearance="outline">
                        <mat-label>Obsolescencia (meses)</mat-label>
                        <input type="number" min="0" matInput placeholder="Obsolescencia" formControlName="obsolete">
                    </mat-form-field>
                </div>
            </div>
            <div recambios>
                <!-- <div [ngClass]="isEdit ? 'df m10' : 'dn'">
                    <mat-slide-toggle #alternatives [checked]="p?.alternatives?.length! > 0">
                        <p class="nmb">Productos Alternativos</p>
                    </mat-slide-toggle>
                </div>
                <div [ngClass]="!alternatives.checked ? 'dg' : 'dn'" *ngIf="isEdit">
                    <p class="c_t2 nmb fsi">Los productos alternativos son opciones que se presentan como reemplazo a
                        este producto</p>
                </div> -->

                <!-- <div [ngClass]="alternatives.checked ? 'dg' : 'dn'" *ngIf="isEdit">
                    <div style="font-size: medium; font-weight: 700;">
                        <p class="mt10">Alternativas a {{p?.name}} :</p>
                    </div>
                    <div *ngIf="p?.alternatives?.length! != 0; else noAlternative">
                        <mat-list-item *ngFor="let alternative of p?.alternatives" [value]="alternative">
                            <div class="df aic jcsb w80">

                                <div style=" padding-inline: 1rem; overflow: hidden" class="df vac">
                                    <div style="width: fit-content; padding-inline: 0.5rem;">
                                        <mat-icon [svgIcon]="'barcode'"></mat-icon>
                                    </div>
                                    {{alternative.name !== undefined ? alternative.name : alternative.product.name}}
                                </div>
                                <div *ngIf="responsiveS.w > 500" style=" padding-inline: 1rem;; overflow: hidden"
                                    class="df vac">
                                    Ref: {{alternative.reference !== undefined ? alternative.reference :
                                    alternative.product.reference}}
                                </div>
                                <div class="df">
                                    <button mat-menu-item matTooltip="Eliminar" (click)="deleteAlterProd(alternative)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-list-item>
                    </div>

                    <div *ngIf="showAlternativeInput" class="df w100 aic">
                        <app-class-searcher #productSearch [showLeftNumber]="false" class="w100"
                            (onSelect)="onProductSelected($event)" [masterClass]="product" [width100]="true"
                            searchPlaceHolder="Buscar productos" [specialRow]="'Nuevo producto'" [required]="false"
                            [disableIf]="disabledIfProduct.bind(this)" (onSpecialRow)="goCreateClient()"
                            [extendedInfo]="true">
                        </app-class-searcher>
                        <div class="m10"></div>
                    </div>

                    <button *ngIf="p?.alternatives!.length > 0" class="mt10 w100" mat-stroked-button color="primary"
                        (click)="showAlternativeInputDiv()" matTooltip="Agregar producto">
                        <mat-icon [svgIcon]="'barcode'" class="c_r"></mat-icon>
                        Producto Alternativo
                    </button>

                    <ng-template #noAlternative>
                        <p class="c_t2">Ningún producto alternativo a {{p?.name}}.<button mat-button color="accent"
                                (click)="showAlternativeInputDiv()">Añadir</button></p>
                    </ng-template>
                </div> -->

                <!-- Providers -->
                <div>
                    <app-card-subtitle>Proveedores</app-card-subtitle>
                    <table>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th *ngIf="!companyS.discountTable">Dto. Normal</th>
                                <th *ngIf="!companyS.discountTable">Dto. Urgente</th>
                                <th>Habitual</th>
                                <th class="small-action"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let provider of formProviders">
                                <td>
                                    <div class="provider-item df vac jcc">
                                        <mat-icon *ngIf="responsiveS.w > 500" class="mr10">local_shipping</mat-icon>
                                        {{provider.name}}
                                    </div>
                                </td>
                                <td *ngIf="!companyS.discountTable">
                                    <div class="df">
                                        <mat-form-field class="small-form-field">
                                            <input [(ngModel)]="provider.provider_discount"
                                                [ngModelOptions]="{standalone: true}" type="number" matInput
                                                [placeholder]="'Dto. Normal'" />
                                            <span matTextSuffix>%</span>
                                        </mat-form-field>
                                    </div>
                                </td>
                                <td *ngIf="!companyS.discountTable">
                                    <div class="df">
                                        <mat-form-field class="small-form-field">
                                            <input [(ngModel)]="provider.provider_discount_urg"
                                                [ngModelOptions]="{standalone: true}" type="number" matInput
                                                [placeholder]="'Dto. Urgente'" />
                                            <span matTextSuffix>%</span>
                                        </mat-form-field>
                                    </div>
                                </td>
                                <td>
                                    <div class="df jcc">
                                        <mat-slide-toggle #providerUsual [(ngModel)]="provider.usual"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="toggleUsual($event, provider)">
                                            <mat-icon class="vam" [ngClass]="provider.usual ? 'c_y' : 'c_t2'">
                                                {{provider.usual ? 'star' : 'star_rate'}}
                                            </mat-icon>
                                        </mat-slide-toggle>
                                    </div>
                                </td>
                                <td>
                                    <button mat-icon-button (click)="removeProvider(provider)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="m10">
                        <p class="c_t2" *ngIf="!formProviders.length && !searchProviderContainer?.length"> ¿Sin
                            proveedores? ¡No esperes más! 🚚👇🏻</p>
                    </div>
                    <!-- Add provider searcher container -->
                    <div #searchProviderContainerHTML></div>
                    <button class="mt10 w100" mat-stroked-button [color]="'accent'" (click)="addProviderSearcher()">
                        <mat-icon>local_shipping</mat-icon>
                        Añadir proveedor
                    </button>
                </div>

                <!-- Discount table -->
                <ng-container *ngIf="companyS.discountTable">
                    <app-card-subtitle>Grupo de descuento</app-card-subtitle>
                    <div class="eina-form-wrapper">
                        <div class="df">
                            <mat-form-field appearance="outline" class="w50">
                                <mat-label>Grupo</mat-label>
                                <mat-select formControlName="group_discount">
                                    <mat-option [value]="undefined">--</mat-option>
                                    <mat-option *ngFor="let group of allDiscountGroups" [value]="group">
                                        {{ group }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="w50">
                                <mat-label>Línea</mat-label>
                                <mat-select formControlName="group_discount_line">
                                    <ng-container *ngIf="!linesOfSelectedGroup.length">
                                        <mat-option [disabled]="true" [value]="undefined">Sin líneas</mat-option>
                                    </ng-container>
                                    <ng-container *ngIf="linesOfSelectedGroup.length">
                                        <mat-option [value]="undefined">--</mat-option>
                                        <mat-option *ngFor="let line of linesOfSelectedGroup" [value]="line.id">
                                            {{ line.code_line }} <span class="c_t2"> ({{line.p_group}})</span>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="group_discount_line_value as GL">
                            <table class="discount-table">
                                <tr>
                                    <th>Dto. Normal</th>
                                    <th>Dto. Urgente</th>
                                    <th>Dto. cliente 1</th>
                                    <th>Dto. cliente 2</th>
                                    <th>Dto. cliente 3</th>
                                    <th>Dto. cliente 4</th>
                                    <th>Dto. cliente 5</th>
                                </tr>
                                <tr>
                                    <td>{{GL.p_type_1}}%</td>
                                    <td>{{GL.p_type_2}}%</td>
                                    <td>{{GL.v_cat_1}}%</td>
                                    <td>{{GL.v_cat_2}}%</td>
                                    <td>{{GL.v_cat_3}}%</td>
                                    <td>{{GL.v_cat_4}}%</td>
                                    <td>{{GL.v_cat_5}}%</td>
                                </tr>
                            </table>
                        </ng-container>
                    </div>
                </ng-container>
            </div>

            <div [ngTemplateOutlet]="submitButton" *ngIf="!isOnDialog"></div>

        </form>
    </app-card>
</div>



<div mat-dialog-actions *ngIf="isOnDialog">
    <div [ngTemplateOutlet]="submitButton"></div>
</div>

<ng-template #submitButton>
    <div class="df jcc mt10">
        <button [disabled]="!form.valid" subscribed-user mat-flat-button color="primary"
            class="onboarding-product-finish" (click)="create()">
            <span>{{isEdit ? isOnDialog ? 'Actualizar' : 'Guardar' : 'Crear producto'}}</span>
        </button>
    </div>
</ng-template>