<!-- WEEK GRID -->
<app-card [customSkeleton]="customSkeleton" [contentLoaded]="grid.parent.loaded_" cdkDropListGroup class="scroll-x workload-main-card no-border"
    [nopadding]="true">
    <div class="df fdr onboarding-workload-week" style="min-width: 0; width: 100%;">
        <div *ngFor="let day of grid.parent.currentWeek?.days; let last = last" class="weekday"
            [ngClass]="{'last' : last, 'today' : day.v.isToday()}" cdkDropList
            (cdkDropListDropped)="grid.drop($event, day.v)" [cdkDropListData]="day.data"
            [cdkDropListSortingDisabled]="!grid.parent.workloadData.isOrView"
            [cdkDropListDisabled]="respoisiveS.w <= 1003" (mouseover)="cnc.style.visibility = 'visible'"
            (mouseleave)="cnc.style.visibility = 'hidden'">
            <div class="df fdc aic">

                <div class="temporal-line">
                    <div *ngIf="grid.getTemporalLineClass(day.v) as temporalClass">
                        <div [class]="temporalClass">
                            <div *ngIf="temporalClass == 'temporal-line-mixed'" class="df">
                                <span class="temporal-line-schedule" style="width: 50%;">Realización</span>
                                <span class="temporal-line-delivery" style="width: 50%;">Entrega</span>
                            </div>
                            <span *ngIf="temporalClass == 'temporal-line-schedule'" style="width: 100%;">
                                Realización
                            </span>
                            <span *ngIf="temporalClass == 'temporal-line-delivery'" style="width: 100%;">
                                Entrega
                            </span>
                        </div>
                    </div>
                </div>

                <!-- TEMPORAL LINE 
                <div [class]=" + ' temporal-line'"></div>
                <div [class]="grid.getTemporalLineClass(day.v) + ' temporal-line-text'"></div>-->


                <div class="dayname">{{day.v.getDayName()}}</div>
                <div class="daynumber fsxl mb20" [ngClass]="{'mini-companyDay' : grid.isCompanyHolidays(day.v)}">{{day.v.getDate()}}
                    <ng-container *ngIf="grid.parent.workloadData.AppoPermisison">
                        <div *ngIf="grid.parent.getItemOfDay(day.v, true).length as total" class="other-item-number"
                            [ngClass]="!grid.parent.workloadData.isOrView ? 'or' : 'appointment'"
                            (click)="grid.parent.changeView(!grid.parent.workloadData.isOrView)">
                            <mat-icon [filled]="true">{{!grid.parent.workloadData.isOrView ? "build" : "event"}}</mat-icon>
                            +{{total}}
                        </div>
                    </ng-container>
                </div>


                <p class="c_r tac fw500" [ngClass]="{'fss' : respoisiveS.w < 818}"
                    *ngIf="grid.getOrUserAssignedHolidays(day.v)">{{respoisiveS.w < 818 ? 'Vacaciones'
                        : 'Vacaciones para el usuario' }} </p>

                        <div *ngIf="grid.parent.loaded_" style="width: 100%;">
                            <app-grid-card class="no-blue" [draggable]="respoisiveS.w >= 1003"
                                (onModifyAppointment)="grid.onModifyAppointment.emit($event)"
                                [highlightId]="grid.parent.highlightId" [workloadData]="grid.parent.workloadData"
                                [day]="day.v" [filters]="grid.parent.currentFilters"
                                [searchFilter]="grid.parent.searchFilter" [allUsers]="grid.parent.users"
                                [period]="grid.parent.selectedPeriod" *ngFor="let item of day.dataAsAny"
                                [otherCardIsDragging]="grid.draggingCard != undefined"
                                (onRemoveHighlight)="grid.parent.highlightId = undefined"
                                (onStartDragging)="grid.draggingCard = $event" [item]="item"></app-grid-card>
                        </div>
            </div>
            <div #cnc style="visibility: hidden" class="addNewOr">
                <div *ngIf="day.v.getDay() != 0 && grid.parent.workloadData.createView && day.v.todayOrBigger()">
                    <button mat-button (click)="grid.goCreateOnDay(day.v)">
                        {{grid.parent.workloadData.isOrView ? "Nueva OR" : "Nueva cita"}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</app-card>


<ng-template #customSkeleton>
    <div class="custom-skeleton">
        <app-skeleton [fullW]="true" [height]="50"></app-skeleton>
        <app-skeleton [fullW]="true" [heightPercentage]="80"></app-skeleton>
    </div>
</ng-template>