<div class="df jcc">
    <div class="table">
        <app-view-title>Almacenes</app-view-title>
        <app-card class="m10">
            <div class="storehouses-content">
                <app-card class="page-card normal-padding-top no-border add-new-card tac"
                    (click)="createStoreHouse()">
                    <div class="df jcc fdc" style="margin-top: -10px;">
                        <app-card-subtitle [first]="true">Crear almacén</app-card-subtitle>
                        <mat-icon class="c_p">add_circle</mat-icon>
                    </div>
                </app-card>
                <app-card class="page-card normal-padding-top no-border" [cornerMatMenu]="label"
                    *ngFor="let storehouse of storehouses" (click)="editStoreHouse(storehouse)">
                    <app-card-subtitle [first]="true" style="margin-top: 3px;">
                        <p style="margin-right: 22px">{{storehouse.name}}</p>
                    </app-card-subtitle>
                    <app-storehouse-lord-icon [iconClass]="'df lord-medium mla mra'"
                        [lordIcon]="storehouse.lordicon"></app-storehouse-lord-icon>
                    <ng-template #label>
                        <button prevent mat-icon-button [matTooltip]="'Cambiar color'"
                            [matMenuTriggerFor]="bookmarkComponent.menu">
                            <mat-icon [style]="storehouse.bookmarkColorStyle">bookmark</mat-icon>
                        </button>
                        <app-sotrehouse-bookmark (onBookmark)="updateBookmark(storehouse, $event)"
                            #bookmarkComponent></app-sotrehouse-bookmark>
                    </ng-template>
                </app-card>
            </div>
        </app-card>
    </div>
</div>