import { Injectable } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Vehicle } from '../models/M_Vehicle';
import { RouterService } from './router.service';
import { Views } from '../custom-classes/View';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  constructor(private routerS: RouterService) { }

  go(v: Views, param: string | number, param_name?: string, newTab?: boolean, replaceUrl?: boolean) {
    let paramName = param_name ? param_name : this.getStateNameByView(v);
    let p = {
      [paramName]: param
    }
    this.routerS.goWithQueryParams(v, p, newTab ? newTab : false, replaceUrl ? replaceUrl : false);
  }

  goVehicleDetails(v: M_Vehicle | undefined, newTab?: boolean) {
    if (!v) { return; }
    if (!v.isVnVo) { this.go(ViewPath.vehicleDetails, v.vehicle_id!, undefined, newTab) }
    else { this.go(ViewPath.vnvoDetails, v.vehicle_id!, undefined, newTab) }
  }

  getStateNameByView(v: Views) {
    let paramName = "";
    switch (v) {
      case ViewPath.contacts:
      case ViewPath.createContact:
      case ViewPath.contactDetails:
        paramName = "client";
        break;
      case ViewPath.vehicles:
      case ViewPath.createvehicle:
      case ViewPath.vehicleDetails:
      case ViewPath.linkvehicle:
      case ViewPath.sellvehicleclient:
      case ViewPath.createOr:
        paramName = "vehicle";
        break;
      case ViewPath.products:
      case ViewPath.productDetails:
      case ViewPath.createProduct:
      case ViewPath.createManualMove:
        paramName = "product";
        break;
      case ViewPath.invoices:
      case ViewPath.invoiceDetails:
        paramName = "bill"
        break;
      case ViewPath.editOr:
        paramName = "or";
        break;
      case ViewPath.editAppointment:
        paramName = "appointment";
        break;
      case ViewPath.createEditWorkshopBudget:
      case ViewPath.createComercialBudget:
      case ViewPath.createRecambiosBudget:
        paramName = "budget";
        break;
      case ViewPath.userdetails:
      case ViewPath.createEditUser:
      case ViewPath.editProfile:
        paramName = "user";
        break;
      case ViewPath.appointmentclientside:
        paramName = "company";
        break;
      case ViewPath.editOrder:
        paramName = "order"
        break;
      case ViewPath.createEditAlbaran:
        paramName = "albaran"
        break;
      case ViewPath.purchaseDetails:
        paramName = "purchase"
        break;
      case ViewPath.vnvoDetails:
      case ViewPath.createVnVo:
      case ViewPath.sellVehicle:
        paramName = "vnvo"
        break;
    }

    return paramName;
  }
}
