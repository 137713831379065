<div *ngIf="!canAssign; else cheangeUsr">
    <div class="p10">
        <p class="c_t2 nmb">No puedes cambiar el operario</p>
    </div>
</div>
<ng-template #cheangeUsr>
    <!-- We can only change the open or or the closed or only on Weberp-->
    <div
        *ngIf="item.status.open || item.status.pending && core.projectName != 'sinicloud'; else cannotChangeByStatus">
        <div class="select-user" (click)="onClickUser(u)" [ngClass]="{'hover' : isClickable(u)}" *ngFor="let u of allUsers" [matTooltipPosition]="'right'" [matTooltip]="userHasHolidays(u) ? 'El usuario tiene vacaciones' : ''">
            <app-circular-letter [showTooltip]="false" [disabled]="userHasHolidays(u)" [user]="u"></app-circular-letter>
            <div class="already-assigned" *ngIf="isUserAlreadyAssigned(u)">Asignado</div>
        </div>
    </div>
</ng-template>
<ng-template #cannotChangeByStatus>
    <div class="p10">
        <p class="c_t2 nmb" *ngIf="item.status.pending">No puedes cambiar el operario de una OR ya cerrada</p>
        <p class="c_t2 nmb" *ngIf="item.status.sinicloudFinished">No puedes cambiar el operario de una OR ya finalizada</p>
        <p class="c_t2 nmb" *ngIf="item.status.invoiced">No puedes cambiar el operario de una OR ya facturada</p>
    </div>
</ng-template>