import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { M_Vehicle } from 'src/app/models/M_Vehicle';

@Component({
  selector: 'app-vehicle-container',
  templateUrl: './vehicle-container.component.html',
  styleUrls: ['./vehicle-container.component.css']
})
export class VehicleContainerComponent implements OnInit {

  @Input()  v : M_Vehicle[] | undefined;
  @Input()  top : boolean = false;
  @Input()  other! : VehicleContainerComponent;
  @Input()  lordIcon! : HTMLElement;
  constructor(private chdRef : ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  move(v : M_Vehicle) {
    this.v?.removeElement(v);
    this.other.recive(v);
  }

  recive(v : M_Vehicle){
    if (this.v == undefined){
      this.v = []
    }
    this.v.push(v);
    this.chdRef.detectChanges();
  }

}
