import { M_Contact } from './M_Contact';
import { M_Breakdown } from './M_Breakdown';
import { M_LastMovements } from './M_LastMovement';
import { calculateTotalsBreakdown, M_TotalsBreakdown } from '../services/totals-calculator.service';
import { ISearchFiltrable } from '../interfaces/ISearchFiltrable';
import { IInvoiceLabel } from '../interfaces/IInvoiceLabel';
import { invoice_states, invoice_states_partial, invoice_states_payed, invoice_states_pending } from '../custom-classes/invoice_states';
import { invoice_type, invoice_type_abono, invoice_type_comercial, invoice_type_or, invoice_type_recambios } from '../custom-classes/invoice_types';
import { or_types } from '../custom-classes/or_types';
import { getArrayOf } from '../utils/FunctionUtils';
import { match } from '../services/search.service';


export class M_Invoice implements ISearchFiltrable, IInvoiceLabel {
  id: number | undefined;
  /** TODO  */
  company_scope_id: number | undefined;
  type: number | undefined;
  client_id: number;
  template_id: number = 1;
  user_id: number = 1;
  iva?: number;
  title: string;
  footer: string;
  comment: string;
  custom = 0;
  present = 0;
  payment = 'card';
  total: number;
  state: invoice_states;
  client: M_Contact | undefined;
  created_at: Date | undefined;
  token: string | undefined;
  isDraft: boolean;
  aboned: boolean;
  last_movement: M_LastMovements[] = [];
  type_invoice: invoice_type | undefined = undefined;
  type_or: or_types | undefined = undefined;
  abono_of: M_Invoice | undefined = undefined;
  private _pendingImport: number | undefined;
  breakdown: M_Breakdown;
  internal: boolean;
  destroyed: boolean = false;
  created: boolean = false;

  constructor(d: any) {

    this.id = d.id;
    this.type = d.type;
    this.company_scope_id = d.invoice_id;
    this.client_id = d.client_id;
    this.template_id = d.template_id;
    this.aboned = d.has_payout;
    this.user_id = d.user_id;
    this.title = d.title;
    this.footer = d.footer;
    this.custom = d.custom;
    this.present = d.present;
    this.payment = d.payment;
    this.total = d.total;
    this.state = d.state == 'created' ? invoice_states_pending : d.state == 'partial' ? invoice_states_partial : invoice_states_payed;
    this.client = d.client ? new M_Contact(d.client) : undefined;
    this.iva = d.iva ? d.iva : undefined;
    this.created_at = d.created_at ? new Date(d.created_at) : undefined;
    this.token = d.token ? d.token : undefined;
    this.isDraft = d.draft ? d.draft : false;
    this.abono_of = d.abono_of ? new M_Invoice(d.abono_of) : undefined;
    this.comment = d.comment;
    this.internal = d.internal;
    if (d.last_movement) {
      this.last_movement = getArrayOf(M_LastMovements, d.last_movement);
    }
    this.breakdown = new M_Breakdown(d.lines);
    this.generateType(d.type);
  }


  get invoice_type_or_type() {
    return [this.type_invoice, this.type_or];
  }

  generateType(t: any) {
    if (typeof t == 'number') {
      if (t == 0 || t == 1 || t == 2 || t == 3) {
        this.type_invoice = invoice_type_or;
        this.type_or = new or_types(t);
      } else if (t == 4) {
        this.type_invoice = invoice_type_recambios;
      } else if (t == 5) {
        this.type_invoice = invoice_type_abono;
      } else if (t == 6) {
        this.type_invoice = invoice_type_comercial;
      }
    }
  }

  type_filter() {
    let filter_arrat_type: number[] = [];

    if (this.state.isPending) {
      filter_arrat_type.push(0);
    }
    if (this.state.isPartial) {
      filter_arrat_type.push(1);
    }
    if (this.state.isPayed) {
      filter_arrat_type.push(2);
    }
    return filter_arrat_type;
  }

  get isAbono() {
    return this.type_invoice?.isAbono;
  }

  get pending_import() {
    if (!this.total) { return 0 }
    let totalaux = this.total;
    this.last_movement.forEach((l) => {
      if (this.isAbono) {
        totalaux = l.import - totalaux;
      }
      else {
        totalaux -= l.import;
      }
    });
    this._pendingImport = Math.abs(totalaux.castDecimals(2));
    return this._pendingImport;
  }
  
  set SetPending_import(value: number) {
    this._pendingImport = value;
  }

  get isTotalPay() {
    if (this.state.isPending) {
      return false;
    }
    return this.pending_import == 0;
  }

  defaultSearchFilter(text: string) {
    text = text.toLocaleLowerCase();
    return match(
      text,
      this.company_scope_id ? this.company_scope_id.toString() : '',
      this.client_id ? this.client_id.toString() : '',
      this.client ? this.client.getName() : '',
      this.total ? this.total.toString() : '',
      this.state ? '' : '',
      this.created_at ? this.created_at.dayMonthYearFormat() : ''
    );
  }

  get tagLabel() {

    if (this.isInternoInvoice) { return "TRAMITADO" }

    return this.isAbono
      ? this.state.isPending
        ? 'PENDIENTE'
        : this.state.isPartial
          ? 'PARCIAL'
          : 'PAGADO'
      : this.state.isPending
        ? 'PENDIENTE'
        : this.state.isPartial
          ? 'PARCIAL'
          : 'COBRADA';
  }

  get icon() {
    if (this.type_invoice) {
      return this.type_invoice.isOr ? 'build' : this.type_invoice.isRecambios ? 'receipt_long'
        : this.type_invoice.isComercial ? 'shoppingmode'
          : 'currency_exchange';
    }
    return 'build';
  }

  getTotalBreakdown(): M_TotalsBreakdown {
    return calculateTotalsBreakdown(this.breakdown.all, this.client)
  }

  get isInternoInvoice() {
    let internalOR = this.type_or != undefined && this.type_or.interno;
    let internalAbono = this.isAbono && this.internal;
    return internalOR || internalAbono;
  }

  get someProductRequested() {
    return this.breakdown.products.some(p => p.isRequested)
  }
}
