<app-card [contentLoaded]="parent.loaded" [noStyle]="true">
    <app-card-title>Configurar documentación</app-card-title>
    <div>
        <!-- Missing company info warning -->
        <p *ngIf="companyS.companyMissingInfo">
            <mat-icon class="c_o" style="vertical-align: middle;">error</mat-icon>
            Se recomienda rellenar primero la
            <span class="c_o fw500 underline_hover" (click)="changeTab.emit(0)">
                informacón general
            </span>
            de tu empresa entes de empezar con esta sección
        </p>
    </div>

    <div class="title-and-form-field">
        <p class="c_t2">En este apartado puedes personalizar la documentación modificando su pie de página y campos
            adicionales.</p>
        <mat-form-field appearance="outline">
            <mat-label>Documento</mat-label>

            <span matPrefix style="margin-right: 8px; margin-left: 8px;">
                <mat-icon class="unsavedDot" [filled]="true" *ngIf="showSaveDot">
                    circle
                </mat-icon>
            </span>

            <mat-select #select [value]="'B'" [formControl]="documentFc">
                <mat-option [value]="'B'">Presupuestos
                    <mat-icon class="unsavedDot" *ngIf="budget.pending" [filled]="true">circle</mat-icon>
                </mat-option>
                <mat-option [value]="'RA'">Apertura OR
                    <mat-icon class="unsavedDot" *ngIf="ra.pending" [filled]="true">circle</mat-icon>
                </mat-option>
                <mat-option [value]="'OR'">Factura OR
                    <mat-icon class="unsavedDot" *ngIf="or.pending" [filled]="true">circle</mat-icon>
                </mat-option>
                <mat-option [value]="'I'">Factura recambios
                    <mat-icon class="unsavedDot" *ngIf="invoices.pending" [filled]="true">circle</mat-icon>
                </mat-option>
                <mat-option [value]="'CV'">Compra-venta VN y VO
                    <mat-icon class="unsavedDot" *ngIf="compraventa.pending" [filled]="true">circle</mat-icon>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="select-content">
        <app-documentation-content #budget [pdf_type]="'B'"
            [ngClass]="{'dn_i' : select.value != 'B'}"></app-documentation-content>
        <app-documentation-content #ra [pdf_type]="'RA'"
            [ngClass]="{'dn_i' : select.value != 'RA'}"></app-documentation-content>
        <app-documentation-content #or [pdf_type]="'OR'"
            [ngClass]="{'dn_i' : select.value != 'OR'}"></app-documentation-content>
        <app-documentation-content #invoices [pdf_type]="'I'"
            [ngClass]="{'dn_i' : select.value != 'I'}"></app-documentation-content>
            <app-documentation-content #compraventa [pdf_type]="'CV'"
            [ngClass]="{'dn_i' : select.value != 'CV'}"></app-documentation-content>
    </div>

    <div [ngTemplateOutlet]="saveColorsAndFooters" [ngTemplateOutletContext]="{icon : 'arrow_upward'}"></div>

</app-card>

<ng-template #saveColorsAndFooters let-icon='icon'>
    <div class="df jcc mb20" [ngClass]="icon == 'arrow_upward' ? 'mt20' : ''">
        <button subscribed-user mat-flat-button [disabled]="saveAllDisabled()" (click)="saveLayout()" color="primary"
            class="onboarding-company-layouts-finish">
            Guardar
        </button>
    </div>
</ng-template>