import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from '../../../constants/Endpoints';
import { M_Register } from 'src/app/models/M_Register';

/**
 * Endpoints with no authorization token
 */
@Injectable({
  providedIn: 'root'
})
export class ApiNoAuthService {

  constructor(private http: HttpClient) { }

  /** User login */
  doLogin(body: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.login.url, body).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  createCompany(c: M_Register) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createCompany.url, c).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  forgotPaswword(email: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.forgotPassword.url, { email: email }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  updatePassword(formObj: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updatePassword.url, formObj).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  /** Email validation
   * Targets "/api/auth/validateEmail"
   * Pass the validation hash that was received via mail
   * body = {
   *  hash : "hashdeejemplo"
   * }
  */
  validateEmail(hash: string, email: string) {
    return new Promise<"error" | "success_new" | "success_change">(resolve => {
      this.http.post<any>(endpoints.validateEmail.url, { hash: hash, email: email }).subscribe(data => {
        resolve(data);
      });
    })
  }
}

// '/api/auth/login'
// '/api/auth/confirmEmail'
// '/api/auth/updatePassword'
// '/api/auth/createCompany'

'/api/show/appointment/booked/rider'
'/api/show/holidays/rider'
'/api/show/rider'
'/api/create/rider/appointment'
'/api/config/appointmentById'
'/api/show/appointment/day'