import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';

@Component({
  selector: 'app-contact-client-rectangle',
  templateUrl: './contact-client-rectangle.component.html',
  styleUrls: ['./contact-client-rectangle.component.css']
})
export class ContactClientRectangleComponent {
  v = ViewPath;
  @Input({required : true}) client! : M_Contact;
}
