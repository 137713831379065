export class M_ModelDetails {
    id: number;
    model_id: number;
    company_id: number;
    price: number;
    cost: number;
    accounting: number;
    tax: number;
    tax_registration: number;
    campaign_description: string | undefined;
    campaign_accounting: any | undefined;
    created_at: Date | undefined;
    updated_at: Date | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.model_id = d.model_id;
        this.company_id = d.company_id;
        this.price = d.price;
        this.cost = d.cost;
        this.accounting = d.accounting;
        this.tax = d.tax;
        this.tax_registration = d.tax_registration
        this.campaign_description = d.campaign_description || undefined;
        this.campaign_accounting = d.campaign_accounting || undefined;
        this.created_at = d.created_at ?  new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ?  new Date(d.updated_at) : undefined;
    }
}