<div class="df aic gap5 card-wrapper-fit" [ngClass]="{'rectangle-clickable' : !client.deleted}" mat-ripple [matRippleDisabled]="client.deleted"
    [go]="client.deleted ? undefined : v.contactDetails" [param]="client.client_id" [newTab]="true">
    <mat-icon class="vam">{{client.icon}}</mat-icon>
    <div class="user-name">{{client.getName()}}</div>
    <div class="icon-buttons rectange">
        <app-contact-buttons>
            <app-whatsapp [phone]="client.phone"></app-whatsapp>
            <app-email-button [email]="client.email"></app-email-button>
            <app-phone-button [phone]="client.phone"></app-phone-button>
        </app-contact-buttons>
    </div>
</div>