<div vnvo>
    <ng-container *ngIf="style == 'flat'">
        <button mat-flat-button [color]="'primary'" [go]="v.createComercialBudget" [param]="{'client' : c.client_id}">
            <mat-icon>calculate</mat-icon>
            Abrir p. comercial
        </button>
    </ng-container>
    <ng-container *ngIf="style == 'menu-option'">
        <button mat-menu-item [go]="v.createComercialBudget" [param]="{'client' : c.client_id}">
            <mat-icon>calculate</mat-icon>
            Abrir p. comercial
        </button>
    </ng-container>
</div>
