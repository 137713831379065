<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Delete file?</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <!-- Fist section -->
    <p mat-dialog-subtitle>This is a substitle</p>
    <p>Yes yes, a subtitle</p>
    <!-- Second section -->
    <p mat-dialog-subtitle>This is a other section</p>
    <p>Yes yes, other section</p>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>No</button>
    <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>