import { Injectable } from '@angular/core';
  import { Location } from '@angular/common';

/**
 * Append query parametres to the CURRENT URL.
 */
@Injectable({
  providedIn: 'root'
})
export class MemoryParamsService {

  currentQueryParams: string[][] = [];
  constructor(private location: Location) { }

  /**
 * Before adding or removing some query params, re-init the 'currentQueryParams' varaible with the actual query params on the url.
 * This function, don't add, don't remove and don't check anything, just put the current query params.
 */
  private prventOverride() {
    this.currentQueryParams = [];
    let qp = window.location.search.replace('?', '');
    const urlParams = new URLSearchParams(qp);
    urlParams.forEach((value, key) => {
      this.currentQueryParams.push([key, value])
    });
  }


  /** Add a new query param to the current location. If 'values[1]' is undefined, remove the key. */
  add(values: [string, string | undefined]): void {
    this.prventOverride();

    /** Is the 'key' already on the query params? */
    var currentValue = this.currentQueryParams.find((v => {
      return v[0] == values[0];
    }))

    /** Set the new value if exists. If is undefined, remove de key and the value form the URL */
    if (currentValue) {
      if (values[1] == undefined) { this.currentQueryParams.removeElement(currentValue) }
      else { currentValue[1] = values[1] }
    }
    /** If not exists, and the value is not undefined, add the new query param */
    else {
      if (typeof values[1] == "string") {
        this.currentQueryParams.push(values as [string, string]);
      }
    }

    /** The current url is the query params */
    const currentURL = this.location.path();
    if (this.currentQueryParams.length) {
      /** Put the new quey params based on the 'currenQueryParams' variable */
      const newURL = this.location.prepareExternalUrl(currentURL).split('?')[0] + '?' + new URLSearchParams(this.currentQueryParams).toString();
      this.location.replaceState(newURL);
    }
    else {
      /** If ther is no length on 'currenQueryParams' variable, remove all query params */
      this.location.replaceState("");
    }
  }
}
