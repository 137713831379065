import { Views } from "../custom-classes/View";
import { Endpoint } from "../custom-classes/Endpoint";

export interface I_ProjectConfiguration {
    endpoints: Endpoint[] | object;
    loginView: Views;
    mainView: Views;
    errorView: Views;
    verbose: boolean;
    errorHandlerEndpoint?: Endpoint;
    checkVersionEndpoint?: Endpoint;
    errorCodes?: Map<number, string>;
}


export class ProjectConfiguration implements I_ProjectConfiguration {
    /** All the app endpoints */
    endpoints: Endpoint[];
    /** The login view */
    loginView: Views;
    /** The main view, usually Dashboard */
    mainView: Views;
    /** Error view */
    errorView: Views;
    /** Show console.log(...) ? */
    verbose: boolean;
    projectName : string;
    /** Endpoint to send the errors. Send a `CustomLog` class on the body. Check CustomErrorHandler.ts*/
    errorHandlerEndpoint?: Endpoint;
    /** Endpoint to check the last forntend version. Usually used to force refresh de frontend cached content. 
     *  The current version is on 'version' of logalStorage.
     * 
     *  The endpoint return should be  : ["version", number]
    */
    checkVersionEndpoint?: Endpoint;
    /** Controlled errors by the app. The error should return : {  error_code : number} */
    errorCodes?: Map<number, string>;

    constructor(data: I_ProjectConfiguration) {
        this.projectName = "weberp";
        this.endpoints = this.parseEndpoints(data.endpoints);
        this.loginView = data.loginView;
        this.errorView = data.errorView;
        this.mainView = data.mainView;
        this.verbose = data.verbose;
        this.errorHandlerEndpoint = data.errorHandlerEndpoint;
        this.checkVersionEndpoint = data.checkVersionEndpoint;
        this.errorCodes = data.errorCodes;
    }

    parseEndpoints(endpoints: Endpoint[] | object) {
        if (Array.isArray(endpoints)) {
            return endpoints;
        }
        else {
            let allEndpoints: Endpoint[] = []
            Object.keys(endpoints).forEach(key => {
                allEndpoints.push(endpoints[key as keyof typeof endpoints])
            });
            return allEndpoints;
        }
    }
}