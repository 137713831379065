import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_Vehicle } from '../models/M_Vehicle';
import { M_ComercialBudget } from '../models/M_ComercialBudget';
import { VehicleformComponent } from '../components/vehicleform/vehicleform.component';
import { ViewPath } from '../app-routing.module';
import { CreateVNVObyBudetg, CreateVNVOcomponent } from '../views/create-vn-vo/create-vn-vo.component';
import { M_Contact } from '../models/M_Contact';
import { MatStepper } from '@angular/material/stepper';
import { SellVehicleClientComponent } from '../components/sell-vehicle-client/sell-vehicle-client.component';
import { M_Concept } from '../models/M_Concept';
import { ConfirmDialogService } from '../services/confirm-dialog.service';
import { TemplateFieldsService } from '../services/template-fields.service';
import { PreviewService } from '../services/preview.service';
import { ApiService } from '../services/Api/api.service';
import { ParamsService } from '../services/params.service';
import { SnackService } from '../services/snack.service';
import { RouterService } from '../services/router.service';
import { T } from '@angular/cdk/keycodes';
import { validatorTax } from '../validators/tax';
export interface TEMPLATE_PDF_PARAMS {
  client_id:    number | undefined,
  vehicle_id:   number | undefined,
  price:        number |  undefined,
  tax:          number |   undefined,
  comment :     string | undefined;
  type :        number | undefined;
  docum_prov_num :     string | undefined;
  mode : string | undefined;
  buy_transac_id:number | undefined;
  date: Date | undefined;
  token: string | undefined;
  type_new: number | undefined
} 
@Component({
  selector: 'app-stepp-form-and-file',
  templateUrl: './stepp-form-and-file.component.html',
  styleUrls: ['./stepp-form-and-file.component.css']
})
export class SteppFormAndFileComponent {
  IsDialog = true
  isStep2Disabled = false
  isEdit = false;
  loaded = true;
  ParamsTempl : TEMPLATE_PDF_PARAMS ={
    client_id:        undefined,
  vehicle_id:         undefined,
  price:              undefined,
  tax:                undefined,
  comment :           undefined,
  type :              undefined,
  docum_prov_num :    undefined,
  mode :              undefined,
  buy_transac_id:     undefined,
  date:               undefined,
  token:              undefined,
  type_new:           undefined
  }
  loadedForm = false
  ve: M_Vehicle | undefined;
  client: M_Contact | undefined;
  Client_pross : M_Contact | undefined
  vehicle: M_Vehicle | undefined;
  vehicle_res: M_Vehicle | undefined;
  v = ViewPath;
  formData_ = new FormData();
  public form: UntypedFormGroup;
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild(VehicleformComponent, { static: true }) vehicleForm!: VehicleformComponent;
  @ViewChild(SellVehicleClientComponent, { static: true }) compraventaForm!: SellVehicleClientComponent;
  constructor(private formBuilder: FormBuilder,@Inject(MAT_DIALOG_DATA) public data:{ budget:M_ComercialBudget,concepts:M_Concept[],vehicle:M_Vehicle},
  public dialogRef: MatDialogRef<SteppFormAndFileComponent>,public confirmD: ConfirmDialogService,private templateService: TemplateFieldsService,
  public previewS: PreviewService,
  private snackS: SnackService,
  private apiS: ApiService,
  public routerS: RouterService,
  public params: ParamsService) {
    this.form = this.formBuilder.group({
      vehicle_id: [''],
      client_id: [''],
      price: ['', Validators.required],
      tax: ['', validatorTax()],
      comment: [''],
      pdf_file: [''],
      type: [2],
      docum_prov_num: [''],
      mode: [''],
      buy_transac_id: [''],
      date: [''],
      token:['CV']
    });
  }
  ngOnInit(): void {
    this.IsDialog = true;
    console.log(this.data.budget);
    this.vehicleForm.form.patchValue({brand:this.data.budget.brand.name,
      model:this.data.budget.model.name, price:this.data.budget.price,
      budget_id:this.data.budget.id,
    });
    if(this.data.budget.brand.id != undefined){
      this.vehicleForm.form.patchValue({brand_id:this.data.budget.brand.id});
      if(this.data.budget.model.id != undefined){
        this.vehicleForm.form.patchValue({model_id:this.data.budget.model.id});
      }
    }
  }
  goBackVehicle(){}

  getConceptsFromForm(): M_Concept[] {
    return this.vehicleForm.getConcepts();
  }
  create() {
    const formData = new FormData();
    if (this.vehicleForm.form.valid) {

      Object.keys(this.vehicleForm.form.controls).forEach(key => {
        const control = this.vehicleForm.form.get(key);
        if (control) {
          formData.append(key, control.value);
        }
      });

      
      formData.set("brand",this.data.budget.brand.name);

      let v = new M_Vehicle(this.vehicleForm.form.value);
      v.concepts = this.getConceptsFromForm();
      this.apiS.vnvo.createVNVO(v).then(res => {
        if (this.isEdit) {
          this.snackS.show("Vehículo editado con éxito");
          this.params.go(ViewPath.vnvoDetails, v.vehicle_id);
        }
        else {
          if(res instanceof M_Vehicle){
           this.vehicle_res = res;
           this.crateparams(this.vehicle_res);
           this.templateService.showTemplateSteps("CV", this.ve?.vehicle_id, "CV", this.ve?.type, undefined, this.ParamsTempl).afterClosed().subscribe(res => {
            if (res) {
                //preview finish and save pdf after call endpoint sell vehicle.
               
                this.ParamsTempl.buy_transac_id = res;
                this.ParamsTempl.mode= "save";
                this.construct_form(this.ParamsTempl);
                let hasData = false;
                this.formData_.forEach((e) => {
                  hasData = true;
                });

                if (hasData) {
                  this.form.patchValue({ mode: "save", buy_transac_id: res });
                  this.previewS.showPreview("CV", "CV", undefined, undefined, false, undefined, undefined, undefined, this.form.value);
                  this.apiS.sellVehicleClient(this.formData_).then(_resp => {
                    this.params.go(this.v.vnvoDetails, this.ve!.vehicle_id)
                  });

                } else {
                    // FormData está vacío
                }
            }
          })
            
          }
          this.snackS.show("Vehículo creado con éxito");
          if (this.isOnDialog) {
            res.budget_id = this.vehicleForm.form.get('budget_id')?.value;
            
            this.dialogRef.close(res);
          }
          else {
            this.routerS.goTo(ViewPath.garage);
          }
        }
      })
     
      // LLamada al template ALOMEJOR ES NECESARIO:

      //LLAMADA TEMPLATE PRIMERO DEBO LLAMAR EL ENDPOINT PARA OBTENER EL VEHICULO QUE HACER DESPUES 
      
    }
    else{
      this.confirmD.show({
        title: "¡No tan deprisa!",
        body: "No puedes hacer una compra venta sin añadir el precio",
        type: "danger",
        showCancel: false,
        confirmTxt: "OK"
      })
      return;
    }
      console.log('Formulario enviado', this.vehicleForm.form.value);

  }
  goToNextStep() {
    console.log(this.vehicle_form);
    
  }
  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
  get vehicle_form(): M_Vehicle | undefined{
    if(this.vehicleForm.form.valid){
        return  new M_Vehicle(this.vehicleForm.form.value);
    }
    return undefined;
  }
  get compraventa(): UntypedFormGroup | undefined{
    if(this.compraventaForm.form.valid){
      return this.compraventaForm.form;
    }
    return undefined;
  }
  get clientsearch(){
    if(this.vehicleForm.clientSearcherComponent?.selected != undefined){
      return this.vehicleForm.clientSearcherComponent?.selected;
    }
    return undefined;
  }
  
  crateparams(v: M_Vehicle){

    this.ParamsTempl = {
      client_id:  this.vehicleForm.client_select?.client_id,  
      vehicle_id: v.vehicle_id,
      price:      this.vehicleForm.form.get('price')?.value, 
      tax:        this.vehicleForm.form.get('tax')?.value,         
      comment :   undefined,
      type :      2, 
      docum_prov_num :     this.vehicleForm.form.get('doc_prov_num')?.value,
      mode :              "view",
      buy_transac_id:     undefined,
      date:               this.vehicleForm.form.get('date')?.value,
      token:              'CV',
      type_new:              1,
    };
  }
  construct_form(params_form : TEMPLATE_PDF_PARAMS){
    if(params_form != undefined){
      this.form.patchValue({
        client_id:      params_form.client_id,  
        vehicle_id:     params_form.vehicle_id,
        price:          params_form.price, 
        tax:            params_form.tax,      
        comment :       params_form.comment,
        type :          params_form.type, 
        docum_prov_num :     params_form.docum_prov_num,
        mode :              "save",
        buy_transac_id:     params_form.buy_transac_id,
        date:               params_form.date,
        token:              'CV',
        type_new :          1
      });

      this.formData_.append('vehicle_id',this.form.get('vehicle_id')?.value);
      this.formData_.append('client_id', this.form.get('client_id')?.value);
      this.formData_.append('price', this.form.get('price')?.value);
      this.formData_.append('tax', this.form.get('tax')?.value);
      this.formData_.append('docum_prov_num', this.form.get('docum_prov_num')?.value);
      this.formData_.append('type', this.form.get('type')?.value);
      this.formData_.append('date', this.form.get('date')?.value);
      this.formData_.append('mode', 'view');
      this.formData_.append('token', 'CV');
      this.formData_.append('comment', 'CV');
      this.formData_.append('type_new', '1');
  
      if (this.vehicleForm.client_select?.type === 2 || this.vehicleForm.client_select?.type  === 3) {
        if (this.vehicleForm.pdfUploadComponent.selectedFile) {
          this.formData_.append('pdf_file', this.vehicleForm.pdfUploadComponent.selectedFile);
         
        }
      }
    }
    
  }
}
