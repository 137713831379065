import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { endpoints } from '../../../constants/Endpoints';
import { M_Model } from 'src/app/models/M_Model';
import { M_Concept } from 'src/app/models/M_Concept';
import { M_ModelDetails } from 'src/app/models/M_ModelDetails';
import { M_ComercialBudget } from 'src/app/models/M_ComercialBudget';
import { getArrayOf } from 'src/app/utils/FunctionUtils';

/**
 * All the endpoints related to the VN and VO module
 */
@Injectable({
  providedIn: 'root'
})
export class ApiVnvoService {

  constructor(private http: HttpClient) { }


  /** ====== VEHICLES ====== */
  /** Get all the VN or VO vehicles */
  vehicles() {
    return new Promise<M_Vehicle[]>(resolve => {
      this.http.get<any>(endpoints.vehiclesC.url).subscribe(
        data => {
          resolve(getArrayOf(M_Vehicle, data));
        }
      );
    })
  }

  getVehicleById(id: number) {
    return new Promise<M_Vehicle | undefined>(resolve => {
      this.http.post<any>(endpoints.vehicleC.url, { vehicle_id: id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Vehicle(data));
          }
          else {
            resolve(undefined)
          }
        }
        ,
        _error => {
          resolve(undefined)
        }
      );
    })
  }


  /** ====== MODELS ====== */
  /** Get a single model */
  getModel(model_id: number) {
    return new Promise<M_Model>(resolve => {
      this.http.post<any>(endpoints.getModel.url, { model_id: model_id }).subscribe(
        data => {
          resolve(new M_Model(data));
        }
      );
    })
  }

  /** ====== CONCEPTS ====== */
  /** Get all the concepts */
  getConcepts() {
    return new Promise<M_Concept[]>(resolve => {
      this.http.get<any>(endpoints.getConcepts.url).subscribe(
        data => {
          resolve(getArrayOf(M_Concept, data));
        }
      );
    })
  }

  /** Creates or updates a concept */
  createUpdateConcept(concept: M_Concept) {
    return new Promise<M_Concept>(resolve => {
      this.http.post<any>(endpoints.createUpdateConcept.url, concept).subscribe(
        data => {
          resolve(new M_Concept(data));
        }
      );
    })
  }


  /** Creates or updates a concept */
  createVNVO(formData: FormData | M_Vehicle) {
    return new Promise<M_Vehicle>(resolve => {
      this.http.post<M_Vehicle>(endpoints.createVNVO.url, formData).subscribe(
        data => {
          resolve(new M_Vehicle(data));
        }
      );
    })
  }

  brandsModels() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.brandModels.url).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  updateModel(modelDetails: M_ModelDetails) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updateModel.url, modelDetails).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  createEditComercialBudget(budget: M_ComercialBudget) {
    return new Promise<M_ComercialBudget>(resolve => {
      this.http.post<any>(endpoints.createComercialBudget.url, budget).subscribe(
        data => {
          resolve(new M_ComercialBudget(data));
        }
      );
    })
  }

  getComercialBudget(id: number) {
    return new Promise<M_ComercialBudget>(resolve => {
      this.http.post<any>(endpoints.getComercialBudget.url, { budget_id: id }).subscribe(
        data => {
          resolve(new M_ComercialBudget(data));
        }
      );
    })
  }

  getComercialBudgets() {
    return new Promise<M_ComercialBudget[]>(resolve => {
      this.http.get<any>(endpoints.getComercialBudgets.url).subscribe(
        data => {
          resolve(getArrayOf(M_ComercialBudget, data));
        }
      );
    })
  }

  deleteConmercialBudget(id : number) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.deleteConmercialBudget.url, { budget_id: id }).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

  closeComercialBudget(id : number) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.closeComercialBudget.url, { budget_id: id }).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

}
