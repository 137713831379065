export class M_DiscountGroupLine {
    id: number;
    code_line: string;
    product_group: string;
    p_group: string;
    p_type_1: number;
    p_type_2: number;
    v_cat_1: number;
    v_cat_2: number;
    v_cat_3: number;
    v_cat_4: number;
    v_cat_5: number;
    company_id: number;

    constructor(data: any) {
        this.id = data.id;
        this.code_line = data.code_line;
        this.product_group = data.product_group;
        this.p_group = data.p_group;
        this.p_type_1 = data.p_type_1 || 0;
        this.p_type_2 = data.p_type_2 || 0;
        this.v_cat_1 = data.v_cat_1 || 0;
        this.v_cat_2 = data.v_cat_2 || 0;
        this.v_cat_3 = data.v_cat_3 || 0;
        this.v_cat_4 = data.v_cat_4 || 0;
        this.v_cat_5 = data.v_cat_5 || 0;
        this.company_id = data.company_id;
    }
}
