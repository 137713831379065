import { Injectable } from '@angular/core';
import { M_Company } from '../../models/M_Company';
import { EinaDataService } from './eina-data.service';
import { ModulesEnum } from '../../enums/ModulesEnum';
import { ConfirmDialogService } from '../confirm-dialog.service';
import { IModuleService } from 'src/app/interfaces/IModuleService';
import { M_Center } from 'src/app/models/M_Center';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements IModuleService {
  constructor(private einaDataService: EinaDataService, private confirmD: ConfirmDialogService) { }

  getModules(): ModulesEnum[] {
    if (!this.company) { return [] }
    return this.company.modules.map(m => m.id);
  }


  /** Company object */
  get company(): M_Company { return this.einaDataService.company; };
  /** Update the company on the service. Emits 'onEinaDataChange' event */
  setNewCompany(d: M_Company) {
    this.einaDataService.setNewCompany(d);
  }
  hasModule(module: ModulesEnum) { return this.company.hasModule(module); }
  toggleModule(module: ModulesEnum) { this.company.toggleModule(module); }
  addCenter(c: M_Center) { this.company.addCenter(c); }
  removeCenter(c: M_Center) { this.company.removeCenter(c); }
  get companyFirstLogin(): boolean { return this.company.first_login; }
  get companyTax(): number { return this.company.iva; }
  get companyMissingInfo(): boolean { return this.company!.missing_info }
  /** The company has the 'Recambios' module */
  get recambiosModule(): boolean { return this.company!.recambios }
  /** The company has the 'VnVo' module */
  get vnvoModule(): boolean { return this.company!.vnvo }
  /** Is the company using  recambios module with the discount table ? */
  get discountTable(): boolean { return true; }
  showMissingCompanyDialog() {
    this.confirmD.showError("Todavía no se puede facturar", "Antes de poder facturar, debes rellenar toda la información sobre tu empresa");
  }
}
