import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { M_Model } from '../../models/M_Model';
import { VehicleType } from '../../enums/VehicleType';
import { M_Brand } from '../../models/M_Brand';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_ModelDetails } from '../../models/M_ModelDetails';
import { MatDialogRef } from '@angular/material/dialog';
import { RegistrationTaxEnum, registrationText } from '../../enums/RegistrationTaxEnum';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-brand-model-edit',
  templateUrl: './brand-model-edit.component.html',
  styleUrls: ['./brand-model-edit.component.css']
})
export class BrandModelEditComponent {

  public form: UntypedFormGroup;
  public editForm: UntypedFormGroup;
  onEdit = false;
  RTE = RegistrationTaxEnum;
  registrationText = registrationText;

  constructor(public dialogRef: MatDialogRef<BrandModelEditComponent>, private formBuilder: UntypedFormBuilder,
    private apiS: ApiService, private snackS: SnackService) {
    this.form = this.formBuilder.group({
      type: [VehicleType.bike],
      brand: [''],
      model: [''],
    });

    this.editForm = this.formBuilder.group({
      model_id : [''],
      price: ['', Validators.required],
      cost: ['', Validators.required],
      tax: [''],
      tax_registration : [],
      accounting: [''],
    });

  }

  goNext() {
    this.editForm.reset();
    this.onEdit = true;
    let model = this.model;
    if (model instanceof M_Model) {
      if (model.details){
        this.editForm.patchValue(model.details);
      }
      this.editForm.patchValue({'model_id' : model.id});
    }
  }

  goBack() {
    this.form.reset();
    this.onEdit = false;
    this.editForm.reset();
  }

  get isNextDisabled() {
    let brand = this.brand instanceof M_Brand;
    let model = this.model instanceof M_Model;
    return !brand || !model;
  }

  get brand() {
    return this.form.get('brand')?.value;
  }

  get model() {
    return this.form.get('model')?.value;
  }

  get editTitle() {
    let brand = this.brand instanceof M_Brand ? this.brand.name : this.brand;
    let model = this.model instanceof M_Model ? this.model.name : this.model;
    return brand + " " + model;
  }


  update() {
    let md: M_ModelDetails = new M_ModelDetails(this.editForm.value);
    this.apiS.vnvo.updateModel(md).then(res => {
      this.dialogRef.close();
      this.snackS.show("Marca y modelo actualizados con éxito")
    })
  }
}
