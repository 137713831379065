import { Component, Inject } from "@angular/core";
import { ResponsiveService } from "src/app/services/responsive.service";
import { DragAndDropGridComponent } from "../drag-and-drop-grid.component";

/** -------------- */
@Component({
    selector: 'app-week-grid',
    templateUrl: './week-grid.component.html',
    styleUrls: ['../drag-and-drop-grid.component.css']
  })
  export class WeekGridComponent {
    constructor(@Inject(DragAndDropGridComponent) public grid: DragAndDropGridComponent, public respoisiveS: ResponsiveService) { }
  }