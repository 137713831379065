<form [formGroup]="form" class="eina-form">
    <div class="eina-form-wrapper">
        <div class="eina-form-section">
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre" formControlName="name">
            </mat-form-field>
        </div>
        <div class="eina-form-section" *ngIf="isAdmin">
            <mat-form-field appearance="outline">
                <mat-label>Centro</mat-label>
                <mat-select formControlName="center">
                  <mat-option *ngFor="let center of einaDataS.company.centers" [value]="center">
                    {{ center.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="eina-form-section">
            <app-email-input [formCName]="'email'" [form]="form"></app-email-input>
        </div>
        <div class="eina-form-section" *ngIf="isAdmin">
            <mat-form-field appearance="outline">
                <mat-label>Rol</mat-label>
                <!-- Only the ADMIN can change the roles-->
                <mat-select formControlName="role">
                    <mat-option *ngIf="originalUser?.admin" [value]="1">Administrador</mat-option>
                    <mat-option [value]="2">Asesor</mat-option>
                    <mat-option [value]="4">Comercial</mat-option>
                    <mat-option [value]="5">Recambista</mat-option>
                    <mat-option [value]="3">Mecánico</mat-option>
                </mat-select>
                <mat-hint *ngIf="isAdmin" class="cp underline_hover c_p" (click)="openRolesExplanation()">¿Qué puede hacer cada rol?
                    <mat-icon class="c_p">open_in_new</mat-icon>
                </mat-hint>
            </mat-form-field>
        </div>
    </div>
</form>