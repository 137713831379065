import { M_Action } from "./M_Action";
import { M_Invoice } from "./M_Invoice";
import { M_Vehicle } from "./M_Vehicle";
import { IClassSearcher } from "../interfaces/IClassSearcher";
import { endpoints } from "../constants/Endpoints";
import { M_LastMovements } from "./M_LastMovement";
import { Country } from "@angular-material-extensions/select-country";
import { M_Product } from "./Products/M_Product";
import { M_AdminCenters } from "./M_AdminCenters";
import { M_Breakdown } from "./M_Breakdown";
import { M_ProductProvider } from "./M_ProductProvider";
import { getCountry } from "src/app/utils/functions";
import { ContactEnum } from "../enums/ContactEnum";
import { M_Order } from "./M_Order";
import { M_Reservation } from "./M_Reservation";
import { M_Fault } from "./M_Fault";
import { M_Address, M_ShippingAddress } from "./M_Address";
import { TypeOrder } from "../enums/TypeOrder";
import { M_CustomProduct } from "./Products/M_CustomProduct";
import { ClientTypeEnum } from "../enums/ClientTypeEnum";
import { M_Parser, parseOBJ } from "../custom-classes/ModelParser";
import { ISearchFiltrable } from "../interfaces/ISearchFiltrable";
import { CustomFile } from "../custom-classes/CustomFile";
import { getArrayOf } from "../utils/FunctionUtils";
import { match } from "../services/search.service";
import { M_Purchase } from "./M_Purchase";
import { ClientPaymentMethodEnum } from "../enums/ClientPaymentMethodEnum";

export class M_Contact implements M_Parser, ISearchFiltrable, IClassSearcher<M_Contact> {
    needsToBeParsed = true;
    client_id: number;
    /** Type of the contact. Can be : "Person", "Company" or "Provider" */
    type: ContactEnum;
    /** The contact have sales activated? */
    c_client: boolean;
    company_scope_id: number;
    company_id: number
    email: string | undefined;
    nif: string;
    phone: string | undefined;
    phone2: string | undefined;
    last_reception: Date | undefined;
    createdAt: Date | undefined;
    updatedAt: Date | undefined;
    dob: Date | undefined; //Date of birth
    name: string;
    surname: string;
    vehicles: M_Vehicle[] = [];
    
    billing_address : M_Address | undefined;
    extra_adress: M_ShippingAddress[] = [];
    selected_extra_adress: number | undefined;

    countable_num: string | undefined;
    observations: string | undefined;
    dni_front: CustomFile | undefined;
    dni_back: CustomFile | undefined;
    documentation: CustomFile[] | undefined;
    email_always: boolean = false;
    profile: boolean = false;
    marketing: boolean = false;
    newsletter: boolean = false;
    actions: M_Action[] = [];
    invoices: M_Invoice[] = [];
    last_movement: M_LastMovements[] = [];
    deleted: boolean = false;
    minify = false;
    activeSales = false
    to_invoice = 0;

    /** Client discount */
    discount: number;
    /** Client type discount */
    discount_cat: ClientTypeEnum;
    /** Provider discounts */
    provider_discount: number;
    provider_discount_urg: number;

    country: Country;
    tax_free: boolean = false;
    products_providers: M_ProductProvider[] = [];
    cli_is_invoice_e: boolean = false
    clients_admincenters: M_AdminCenters[] = []
    usual: boolean = false;
    orders: M_Order[] = [];
    reservations: M_Reservation[] = [];
    faults: M_Fault[] = [];
    purchases:M_Purchase [] =[];

    /** Bank and paymen method */
    account_num: string | undefined;
    payment_method: ClientPaymentMethodEnum | undefined;
    payment_term_1: number | undefined;
    payment_term_2: number | undefined;
    payment_term_3: number | undefined;
    payment_term_4: number | undefined;
    payment_term_5: number | undefined;
    payment_day_1: number | undefined;
    payment_day_2: number | undefined;
    payment_day_3: number | undefined;
    iban_prefix: string | undefined;
    swift: string | undefined;
    iban: string | undefined;

    constructor(d: any) {
        this.client_id = d.id ? d.id : d.client_id ? d.client_id : undefined;
        this.type = d.type ? d.type : ContactEnum.PERSON;
        this.c_client = d.c_client ? d.c_client : false;
        this.company_scope_id = d.countable_num;
        this.company_id = d.company_id;
        this.name = d.name;
        this.orders = getArrayOf(M_Order, d.orders);
        this.cli_is_invoice_e = d.cli_is_invoice_e;
        this.clients_admincenters = d.clients_admincenters ? getArrayOf(M_AdminCenters, d.clients_admincenters) : [];
        this.surname = d.surname;
        this.nif = d.nif;
        this.email = d.email;
        this.billing_address = d.address ? new M_Address(d) : undefined;
        this.extra_adress = d.extra_adress ? getArrayOf(M_ShippingAddress, d.extra_adress) : [];
        this.phone = d.phone;
        this.phone2 = d.phone2;
        this.to_invoice = d.to_invoice;
        this.countable_num = d.countable_num;
        this.observations = d.observations;
        this.deleted = d.deleted;
        this.last_reception = d.last_reception ? new Date(d.last_reception) : undefined;
        this.createdAt = d.created_at ? new Date(d.created_at) : undefined;
        this.updatedAt = d.updated_at ? new Date(d.updated_at) : undefined;
        this.dob = d.dob ? new Date(d.dob) : undefined;
        this.email_always = d.email_always ? d.email_always : false;
        this.discount = d.discount;
        this.discount_cat = d.discount_cat || ClientTypeEnum.NOTYPE;
        this.provider_discount = d.provider_discount ?? 0;
        this.provider_discount_urg = d.provider_discount_urg ?? 0;
        this.tax_free = d.tax_free;
        this.usual = d.usual ? d.usual : false;

        /** Bank and paymen method */
        this.account_num = d.account_num ? d.account_num : undefined;
        this.payment_method = d.payment_method ? d.payment_method : undefined;
        this.payment_term_1 = d.payment_term_1 ? d.payment_term_1 : undefined;
        this.payment_term_2 = d.payment_term_2 ? d.payment_term_2 : undefined;
        this.payment_term_3 = d.payment_term_3 ? d.payment_term_3 : undefined;
        this.payment_term_4 = d.payment_term_4 ? d.payment_term_4 : undefined;
        this.payment_term_5 = d.payment_term_5 ? d.payment_term_5 : undefined;
        this.payment_day_1 = d.payment_day_1 ? d.payment_day_1 : undefined;
        this.payment_day_2 = d.payment_day_2 ? d.payment_day_2 : undefined;
        this.payment_day_3 = d.payment_day_3 ? d.payment_day_3 : undefined;
        this.iban_prefix = d.iban_prefix ? d.iban_prefix : undefined;
        this.swift = d.swift ? d.swift : undefined;
        this.iban = d.iban ? d.iban : undefined;

        this.products_providers = d.products_providers ? getArrayOf(M_ProductProvider, d.products_providers) : [];
        if (d.last_movement) {
            this.last_movement = getArrayOf(M_LastMovements, d.last_movement);
        } else {
            this.last_movement = [];
        }
        
        if (d.faults) {
            this.faults = getArrayOf(M_Fault, d.faults);
        } else {
            this.faults = [];
        }

        /** Dni images */
        if (d.images) {
            for (let i = 0; i < d.images.length; i++) {
                if (d.images[i].name != undefined) {
                    if (d.images[i].name == "dni_front") {
                        this.dni_front = new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id);
                    }
                    else if (d.images[i].name == "dni_back") {
                        this.dni_back = new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id);
                    }
                    else if (d.images[i].name == "docu_img") {
                        if (!this.documentation) { this.documentation = [] }
                        this.documentation.push(new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id));
                    }
                }
            }
        }

        /** User consents */
        if (d.gdrps) {
            this.profile = d.gdrps.profile ? d.gdrps.profile : false
            this.marketing = d.gdrps.marketing ? d.gdrps.marketing : false
            this.newsletter = d.gdrps.newsletter ? d.gdrps.newsletter : false
        }
        else if (d.profile || d.marketing || d.newsletter) {
            this.profile = d.profile ? d.profile : false
            this.marketing = d.marketing ? d.marketing : false
            this.newsletter = d.newsletter ? d.newsletter : false
        }

        /** User vehicles */
        if (d.vehicles) {
            for (let i = 0; i < d.vehicles.length; i++) {
                this.vehicles.push(new M_Vehicle(d.vehicles[i]))
            }
        }

        if (d.actions) {
            for (let i = 0; i < d.actions.length; i++) {
                this.actions.push(new M_Action(d.actions[i]));
            }
        }

        if (d.invoices) {
            for (let i = 0; i < d.invoices.length; i++) {
                this.invoices.push(new M_Invoice(d.invoices[i]));
            }
        }
        if (d.purchases) {
            for (let i = 0; i < d.purchases.length; i++) {
                this.purchases.push(new M_Purchase(d.purchases[i]));
            }
        }
        if (d.reservations) {
            this.reservations = getArrayOf(M_Reservation, d.reservations);
        }
        this.last_reception = this.getLastReceptionByActions();
        this.country = getCountry(d.country);
    }

    getProducts(lines: any) {
        return lines.map((item: M_Product) => new M_Breakdown(item));
    }

    parse() {
        let obj = parseOBJ(this);
        if (obj.last_reception) {
            obj.last_reception = new Date(obj.last_reception).dataBaseFormat();
        }
        if (obj.dob) {
            obj.dob = new Date(obj.dob).dataBaseFormat();
        }
        if (obj.vehicles) {
            for (let i = 0; i < obj.vehicles.length; i++) {
                obj.vehicles[i] = obj.vehicles[i].parse();;
            }
        }
        return obj;
    }

    getPhoneIfExists() {
        return this.phone ? this.phone : this.phone2 ? this.phone2 : undefined;
    }

    /** The contact have sales activated? */
    get isClient() { return this.c_client; }

    /** Is the contact "Person" type?*/
    get isPerson() { return this.type == ContactEnum.PERSON; }

    /** Is the contact "Company" type?*/
    get isCompany() { return this.type == ContactEnum.COMPANY; }

    /** Is the contact "Provider" type?*/
    get isProvider() { return this.type == ContactEnum.PROVIDER; }

    /** Is the contact "Agent" type= */
    get isAgent() { return this.type == ContactEnum.AGENT; }

    getName() {
        return this.isCompany || this.isProvider ? this.name : this.name + " " + this.surname;
    }

    getDiscount() {
        return this.discount + "%";
    }

    hasVehicles() {
        return this.vehicles.length != 0;
    }

    hasDniFront() {
        return this.dni_front != undefined;
    }

    hasDniBack() {
        return this.dni_back != undefined;
    }

    getAllProductsFromProviders(providers: M_ProductProvider[]): M_Product[] {
        return providers.map(provider => provider.getProduct()).filter(product => product !== undefined) as M_Product[];
    }

    get lastOr() {
        return this.actions.filter(action => action.isOr())
            .sort((a, b) => a.created_at > b.created_at ? -1 : 1)[0];
    }

    get orPendingArray() {
        return this.actions.filter(action => action.isOr() && action.hasPendingToInvoiceGroups() && !action.status.invoiced);
    }

    get paymentPendingArray() {
        return this.invoices.filter(invoice => !invoice.state.isPayed && !invoice.isInternoInvoice);
    }

    get advancesTotal() {
        return this.last_movement.filter(move => !move.deleted)
            .reduce((sum, current) => sum + current.import, 0);
    }

    get paymentsTotal() {
        return this.invoices.filter(invoice => !invoice.state.isPayed)
            .reduce((sum, current) => sum + current.total, 0);
    }

    get ORtotal() {
        return this.actions.reduce((sum, current) => sum + current.totalToInvoice, 0);
    }

    getClientDiscount(p: M_Product | M_CustomProduct): number | null {
        if (this.isClient) {
            if (p instanceof M_Product && p.discount_group && this.discount_cat) {
                let v = 0;
                switch (this.discount_cat) {
                    case ClientTypeEnum.TYPE1:
                        v = p.discount_group.v_cat_1;
                        break;
                    case ClientTypeEnum.TYPE2:
                        v = p.discount_group.v_cat_2;
                        break;
                    case ClientTypeEnum.TYPE3:
                        v = p.discount_group.v_cat_3;
                        break;
                    case ClientTypeEnum.TYPE4:
                        v = p.discount_group.v_cat_4;
                        break;
                    case ClientTypeEnum.TYPE5:
                        v = p.discount_group.v_cat_5;
                        break;
                }
                if (v) { return v; }
            }
            if (!this.discount) { return null }
            return this.discount;
        }
        return null;
    }

    /** Apply provider discount. TO DO : https://siniwin.atlassian.net/browse/EINA-1289 */
    getDiscountByProduct(p: M_Product): number | null;
    getDiscountByProduct(p: M_Product, type_order: TypeOrder): number | null;
    getDiscountByProduct(p: M_Product, type_order?: TypeOrder): number | null {
        let uses_provider_discount = false;
        /** User uses provider-discount */
        if (uses_provider_discount) {
            if (!this.products_providers.length) { return null; }
            let provDiscount = this.products_providers.find(prodprov => prodprov.product && prodprov.product.product_id == p.product_id);
            if (provDiscount) {
                if (type_order !== undefined) {
                    return type_order == TypeOrder.NORMAL ? provDiscount.discount : provDiscount.discount_urg;
                } else {
                    return provDiscount.discount;
                }
            }
            return null;
        }
        else {
            if (!p.discount_group) { return null }
            if (type_order !== undefined) {
                return type_order == TypeOrder.NORMAL ? p.discount_group.p_type_1 : p.discount_group.p_type_2;
            } else {
                return p.discount_group.p_type_1;
            }
        }
    }


    defaultSearchFilter(text: string): boolean {
        text = text.toLocaleLowerCase();
        return match(text,
            this.getName(),
            this.nif,
            this.email ? this.email : '',
            this.phone ? this.phone : '',
            this.billing_address ? this.billing_address.toText() : '',
            this.last_reception ? this.last_reception.dayMonthYearFormat() : '')
    }

    getLastReceptionByActions() {
        if (!this.actions) { return undefined; }
        return this.actions
            .filter(a => a.type_id.isOr)
            .sort((a, b) => a.created_at > b.created_at ? -1 : 1)[0]?.created_at;
    }

    /** CLASS SEARCHER INTERFACE */
    get endpoint() { return this.isProvider ? endpoints.providers : this.activeSales ? endpoints.c_clients : !this.minify ? endpoints.clients : endpoints.clientsminify }
    get cs_id() { return this.client_id; }
    get icon() { return this.isAgent ? 'work' : this.isProvider ? "local_shipping" : this.isCompany ? "business" : "person"; }
    get typeof() { return M_Contact }
    createNew(d: any) { return new M_Contact(d) }
    getInputText(): string { return this.getName(); }
    getOptionText(): [string, string] { return ([this.getName(), this.nif]) }
}