import { M_Concept } from "./M_Concept";
import { M_Brand } from "./M_Brand";
import { M_Model } from "./M_Model";
import { M_Vehicle } from "./M_Vehicle";
import { M_Invoice } from "./M_Invoice";
import { M_Product } from "./Products/M_Product";
import { M_CustomProduct } from "./Products/M_CustomProduct";
import { ISearchFiltrable } from "../interfaces/ISearchFiltrable";
import { or_status, or_status_open } from "../custom-classes/or_states";
import { getArrayOf } from "../utils/FunctionUtils";
import { match } from "../services/search.service";
import { BudgetReserveEnum } from "../enums/BudgetReserveEnum";
import { M_Breakdown } from "./M_Breakdown";

export class M_ComercialBudget implements ISearchFiltrable {

    id: number;
    status: or_status = or_status_open;
    budget_id: string | undefined;
    brand: M_Brand;
    model: M_Model;
    price: number;
    token: string | undefined;
    client_id: number;
    vehicle_id:number | undefined;
    reserve : boolean = false;
    num_budget: string | undefined;
    concepts: M_Concept[] = [];
    products: (M_Product | M_CustomProduct)[] = [];
    created_at: Date | undefined;
    updated_at: Date | undefined;
    vehicle: M_Vehicle | undefined;
    invoice: M_Invoice | undefined;

    constructor(d: any) {
        this.id = d.id;
        if (d.brand.id && d.model.id) {
            this.brand = new M_Brand(d.brand);
            this.model = new M_Model(d.model);
        }
        else {
            throw Error("Brand and model are not a Object")
        }
        this.status = d.closed ? new or_status(d.closed) : or_status_open;
        this.price = d.price;
        this.concepts = getArrayOf(M_Concept, d.concepts);
        let prods = d.products;
        if (Array.isArray(prods)) {
            prods.forEach(p => {
                this.products.push(p.custom ? new M_CustomProduct(p) : new M_Product(p))
            })
        }
        if(d.vehicle_id){
            this.vehicle_id = d.vehicle_id;
        }
        if(d.reserve){
            this.reserve = d.reserve;
        }
        if(d.num_budget){
            this.num_budget = d.num_budget;
        }
        this.token = d.token || undefined;
        this.client_id = d.client_id;
        this.budget_id = d.budget_id || undefined;

        if (d.vehicle) {
            this.vehicle = new M_Vehicle(d.vehicle);
            this.vehicle.budget_id = this.id;
        }

        if (d.invoice) {
            this.invoice = new M_Invoice(d.invoice);
        }

        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
    }

    /** Don't remove this */
    isBudget() {
        return true;
    }
    get titleBudget(){
        console.log(this.num_budget);
        return this.num_budget != undefined ? this.num_budget : "Nuevo presupuesto"
    }
    getBrandModel() {
        return this.brand.name && this.model.name ? this.brand.name + " " + this.model.name :
            this.brand.name || this.model.name || "";
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.id.toString(), this.brand.name, this.model.name, (this.brand.name + " " + this.model.name));
    }
}
