import { Component } from '@angular/core';

@Component({
  selector: 'app-advanced-main-actions',
  template: `
  <div class="df gap10"><ng-content></ng-content></div>
`
})
export class AdvancedMainActionsComponent {

}
