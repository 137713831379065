<form class="eina-form create-appoitnment-form" [ngClass]="{ 'is-edit': isEdit }" style="max-width: unset"
  (keydown.enter)="$event.preventDefault()" [formGroup]="form">
  <div class="container" [ngClass]="{ 'no-client': tabsGroup.selectedIndex == 1 }">

    <div class="back">
      <app-go-back [text]="'Listado de citas'" [v]="v.appointments"></app-go-back>
    </div>

    <div class="top_card" *ngIf="isEdit">
      <app-card [contentLoaded]="loaded" class="bc_b1">

        <app-advanced-details *ngIf="appointment">

          <app-advanced-title>
            <!-- EDIT APPOINTMENT TITLE -->
            <div class="df aic" *ngIf="isEdit">
              <span class="mr10">{{appointment.id_appointment}}</span>
              <lib-appointment-status-label [menuIconOpen]="isFormOk" [status]="appointment.status"
                [mat-menu-trigger-for]="isFormOk ? status: null"></lib-appointment-status-label>
              <mat-menu #status="matMenu">
                <button *ngIf="!appointment.isCanceled" mat-menu-item class="chip-option"
                  (click)="changeStatus(app_status.canceled)">
                  <lib-appointment-status-label [pointer]="true"
                    [status]="app_status.canceled"></lib-appointment-status-label>
                </button>
                <button *ngIf="!appointment.isPending" mat-menu-item class="chip-option"
                  (click)="changeStatus(app_status.pending)">
                  <lib-appointment-status-label [pointer]="true"
                    [status]="app_status.pending"></lib-appointment-status-label>
                </button>
                <button *ngIf="!appointment.isDone" mat-menu-item class="chip-option"
                  (click)="changeStatus(app_status.done)">
                  <lib-appointment-status-label [pointer]="true"
                    [status]="app_status.done"></lib-appointment-status-label>
                </button>
              </mat-menu>
            </div>
          </app-advanced-title>

          <app-advanced-subtitle>
            {{subtitle}}
          </app-advanced-subtitle>

          <app-advanced-main-actions>
            <div class="df fdc">
              <div [matTooltip]="!appointment.canRA ? 'La cita esta cancelada' : ''">
                <button mat-flat-button (click)="toOr()" color="primary" [disabled]="!isFormOk || !appointment.canRA">
                  {{appointment.or ? 'Generar otra OR' : 'Pasar a OR'}}
                </button>
              </div>
              <p *ngIf="appointment.or" class="nmb tac" style="margin-top: 5px;">
                <span class="fsi">Ya se ha pasado a OR</span>
                <span class="ml5 c_b c_p underline_hover fw600" (click)="goOr(appointment.or.id)">Ver</span>
              </p>
            </div>
          </app-advanced-main-actions>

          <app-advanced-menu-actions>
            <button mat-menu-item (click)="goWokrload()">
              <mat-icon>equalizer</mat-icon>
              Ver en la carga de taller
            </button>
          </app-advanced-menu-actions>

        </app-advanced-details>

      </app-card>
    </div>

    <div class="interventions">
      <app-card [contentLoaded]="loaded">

        <!-- TABS -->
        <mat-tab-group mat-align-tabs="center" [color]="'primary'">
          <!-- REGISTERED CLIENT TAB -->
          <mat-tab [labelClass]="'onboarding-create-appointment-client'">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">how_to_reg</mat-icon>
              Cliente registrado
            </ng-template>
            <app-card-subtitle>Cliente</app-card-subtitle>

            <div class="eina-form-wrapper">
              <app-class-searcher [canRemove]="appointment == undefined" class="w100" #vehicleSearcher
                [masterClass]="vehicle" searchPlaceHolder="Buscar vehículo o propietario"
                [noSelectionPlaceHolder]="'Ningún vehículo seleccionado'" [specialRow]="''"
                (onSelect)="changeClient($event)" [required]="true" [createData]="{
                  text : 'Nuevo vehículo',
                  by : 'dialog'
              }">
              </app-class-searcher>
            </div>
          </mat-tab>

          <!-- NO REGISTERED CLIENT TAB -->
          <mat-tab [labelClass]="'onboarding-create-appointment-no-client'">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">no_accounts</mat-icon>
              Cliente no registrado
            </ng-template>
            <div>
              <app-card-subtitle>Datos cliente no registrado</app-card-subtitle>
              <div class="eina-form-wrapper">
                <div class="eina-form-section">
                  <mat-form-field appearance="outline">
                    <mat-label>Matricula</mat-label>
                    <app-async-validator-spinner matSuffix
                      [control]="form.get('license')"></app-async-validator-spinner>
                    <input matInput placeholder="Matricula" formControlName="license" />
                    <mat-error *ngIf="form.get('license')?.hasError('license-exists')">
                      La matrícula ya existe.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Nombre de cliente</mat-label>
                    <input matInput placeholder="Introduce el nombre del cliente" [(ngModel)]="textInput"
                      (input)="checkForTindr()" formControlName="name_client" required />
                  </mat-form-field>
                </div>

                <div class="eina-form-section">
                  <app-email-input [form]="form" formCName="email"></app-email-input>
                  <app-phone-input [form]="form" formCName="phone"></app-phone-input>
                </div>

                <div class="eina-form-section">
                  <mat-form-field appearance="outline">
                    <mat-label>Marca</mat-label>
                    <input matInput placeholder="Introduce la marca" formControlName="vehicle_brand" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Modelo</mat-label>
                    <input matInput placeholder="Introduce el modelo" formControlName="vehicle_model" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

        <app-card-subtitle>Fecha y hora</app-card-subtitle>
        <div class="onboarding-create-appointment-hour eina-form-wrapper">
          <app-hour-picker [isEdit]="isEdit" [matFormFieldsClasses]="'w50'" [form]="form"></app-hour-picker>
        </div>

        <app-card-subtitle>Intervenciones</app-card-subtitle>
        <div class="onboarding-create-appointment-reparations eina-form-section eina-form-wrapper">
          <app-comentarios-moto [isEina]="true" #moto style="display: none"></app-comentarios-moto>
          <app-comentarios [showEnter]="true" [removable]="!appointment?.isDone" [form]="form"
            [label]="'Introduce las intervenciones'" [placeHolder]="'Intervenciones'" [moto]="moto"></app-comentarios>


          <!-- Estimated time-->
          <mat-form-field appearance="outline" (click)="!form.disabled ? openEstimatedTimeDialog() : {}">
            <mat-label>Tiempo estimado</mat-label>
            <input [readonly]="!form.disabled" [disabled]="form.disabled" type="string" matInput [value]="formattedTime()">
            <button mat-icon-button matSuffix [disabled]="form.disabled">
              <mat-icon>schedule</mat-icon>
            </button>
          </mat-form-field>

          <div class="dn_i">
            <mat-form-field appearance="outline">
              <input type="number" matInput formControlName="estimated_time_hours">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <input type="number" matInput formControlName="estimated_time_minutes">
            </mat-form-field>
          </div>

        </div>

        <div class="mt10 mb-20 df jcc">
          <button subscribed-user mat-raised-button type="submit" [disabled]="!isFormOk" color="primary" *ngIf="!isEdit"
            class="onboarding-create-appointment-finish" style="width: 130px !important; margin: auto"
            (click)="createAppointment()">
            Crear cita
          </button>
          <button *ngIf="isEdit" mat-flat-button color="primary" (click)="editAppointment()"
            [disabled]="!hasChanges || !isFormOk">
            Guardar
          </button>
        </div>

      </app-card>
    </div>
  </div>
</form>

<!-- MISSING COMPANY INFO COMPONENT-->
<ng-template #notfound>
  <app-model-not-found [title]="'No se ha encontrado la cita'" [goText]="'Ir al listado de citas'"
    [view]="v.appointments">
  </app-model-not-found>
</ng-template>