import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TryProDialogComponent } from '../../components/try-pro-dialog/try-pro-dialog.component';
import { EnumSubscription } from '../../enums/EnumSubscription';
import { M_Subscription } from '../../models/M_Subscription';
import { UserService } from './user.service';
import { ViewPath } from 'src/app/app-routing.module';
import { EinaDataService } from './eina-data.service';
import { ConfirmDialogService } from '../confirm-dialog.service';
import { CardComponent } from 'src/app/components/card/card.component';
import { CreateTicketDialog } from 'src/app/components/create-ticket-dialog/create-ticket-dialog';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {


  proPlanRequiredTxt = "Se requiere el plan PRO";

  /** Subect for notify when the user subscribe / unsubscribe */
  onChangeSubscription: Subject<M_Subscription | undefined> = new Subject();
  constructor(private einaDataS: EinaDataService, private d: MatDialog, private userS: UserService, private confirmD: ConfirmDialogService) { }

  /** User's subscription state */
  get status(): M_Subscription { return this.einaDataS.subscription; }

  /**Change the user's subscription status. Also emitts changes */
  setSubscriptionStatus(status: M_Subscription) {
    this.einaDataS.setNewSubscription(status);
    this.onChangeSubscription.next(this.status);
  }

  /** Open dialog to pay */
  openTryProDialog(text?: string, card?: CardComponent) {
    this.d.open(TryProDialogComponent, { data: text, autoFocus: false })
  }

  genericSubscriptionError(title: string, body: string) {
    this.confirmD.show({
      title: title,
      body: body,
      type: "danger",
      confirmTxt: "Contactar",
      showCancel: true
    }).afterClosed().subscribe(res => {
      if (res) {
        this.d.open(CreateTicketDialog, { data: { view: ViewPath.issues, showGoIssues: true }, autoFocus: false });
      }
    });
  }

  get isTrial() {
    return this.status?.type == EnumSubscription.TRIAL;
  }

  get isBasic() {
    return this.status?.type == EnumSubscription.BASIC;
  }

  get isPro() {
    return this.status != undefined &&
      this.status.type == EnumSubscription.PRO;
  }

  get hasProPermissions() {
    return this.status != undefined &&
      (this.status.type == EnumSubscription.PRO ||
        this.status.type == EnumSubscription.TRIAL);
  }

  /** Show the top banner ? 
   *  - When the user has the free trial (green)
   *  - When the user finished the free trial (red)
   *  - When the user canceled the subscription and already have remaining days of pro (blue)
   */
  get showBanner() {
    if (this.status == undefined || !this.userS.userIsAdmin) { return false; }
    return this.isTrial || this.isBasic || (this.isPro && this.status.will_cancel);
  }

  get someTimeHasPro() {
    return this.status != undefined && (this.isPro || (this.isBasic && !this.status.is_demo))
  }
}