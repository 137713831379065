import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from 'src/app/models/M_Contact';
import { ActionCreateEditParentComponent } from '../or-create-edit-parent/or-create-edit-parent.component';
import { MatDialog } from '@angular/material/dialog';
import { OrFormComponent } from 'src/app/components/or-form/or-form.component';
import { RaGroupsComponent } from './ra-groups/ra-groups.component';
import { OrNotesAndImagesComponent } from '../or-notes-and-images/or-notes-and-images.component';
import { OrService } from 'src/app/services/or.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { RouterService } from 'src/app/services/router.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { endpoints } from 'src/app/constants/Endpoints';
import { M_Vehicle } from 'src/app/models/M_Vehicle';

@Component({
  selector: 'app-or',
  templateUrl: './create-or.component.html',
  styleUrls: ['./create-or.component.css', '../create-edit-or-styeles.css']
})
export class CreateOr extends ActionCreateEditParentComponent implements OnInit {
  @ViewChild(OrFormComponent, { static: true }) formComponent!: OrFormComponent;
  @ViewChild(RaGroupsComponent) raGroups!: RaGroupsComponent;
  @ViewChild(OrNotesAndImagesComponent) notesAndImages!: OrNotesAndImagesComponent;
  e = endpoints;
  resizeSignatureSize = 530;
  loaded = true;

  constructor(routerS: RouterService, public cs: CalendarService, apiS: ApiService, d: MatDialog, route: ActivatedRoute,
    public companyS: CompanyService, private chdRef: ChangeDetectorRef, confirmDialogS: ConfirmDialogService,
    private orS: OrService) {
    super(apiS, routerS, route, ["or", "startDate", 'vehicle'], d, confirmDialogS);
  }

  ngOnInit(): void {
    this.chdRef.detectChanges();
    this.fillSchedule(new Date());
    this.formComponent.vehicleSearcher?.onSelect.subscribe(v=>{
      if(v instanceof M_Vehicle && v.isVnVo){
        this.raGroups.onlyInternal();
      }else{   
        this.raGroups.standarStatus();
      }
    });
  }

  checkInterno(v : M_Vehicle | undefined){
    if (v instanceof M_Vehicle && v.isVnVo) {
     
      this.raGroups.onlyInternal();
    } else {
      this.raGroups.standarStatus();
    }
  }


  override onParam(k: string, v: string) {
    if (k == "vehicle") {
      this.loaded = false;
      this.formComponent.fillFormWithVehicle(v.getNumber());
    }
    else if (k == "startDate") {
      this.fillSchedule(new Date(v));
    }
  }

  get missingCompanyInfo() {
    return this.companyS.companyMissingInfo;
  }

  createOr() {
    if(this.isInternal){
      this.formComponent.form.patchValue({comercial_type:2});
    }
    this.orS.create({
      orForm: this.formComponent,
      raGroups: this.raGroups.groups,
      notesAndImages: this.notesAndImages
    });
  }

  override fillSchedule(v: Date) {
    this.formComponent.form.patchValue({ "schedule": v.datePickerFormat() })
  }

  disableNoVehicles(v: M_Contact) {
    return !v.hasVehicles();
  }
 get isInternal(){
  return this.formComponent.vehicleSearcher?.selected?.client == undefined;
 }
  goCompany() {
    this.routerS.goTo(this.v.settings);
  }
}


