import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { M_Center } from 'src/app/models/M_Center';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { HolidaysCompanyComponent } from 'src/app/views/settings/holidays-company/holidays-company.component';
import { StorehousesComponent } from 'src/app/views/storehouses/storehouses.component';

@Component({
  selector: 'app-center-form',
  templateUrl: './center-form.component.html',
  styleUrls: ['./center-form.component.css']
})
export class CenterFormComponent implements AfterViewInit {

  @ViewChild(HolidaysCompanyComponent) holidaysC!: HolidaysCompanyComponent;
  @ViewChild(StorehousesComponent) storehouses!: StorehousesComponent;
  @Input({ required: true }) center: M_Center | undefined;
  form: FormGroup;


  constructor(private fb: FormBuilder, private companyS: CompanyService) {
    this.form = this.fb.group({

      /** Center */
      id: [''],
      name: ['', Validators.required],
      address: ['', [Validators.required]],
      address2: [''],
      zip: ['', [Validators.required]],
      city: ['', [Validators.required]],
      ccaaObject: ['', [Validators.required]],
      ccaa: ['', [Validators.required]],
      province: ['', [Validators.required]],

      /** Workshop and appointments subform */
      workshopConfig: this.fb.group({

        /** Workshop */
        default_vehicle: [1],
        places: ['', [Validators.required]],
        price_hour: ['', [Validators.required]],
        iva: [0],

        /** Appointments */
        morning_work_schedule_start: ['', [Validators.required]],
        morning_work_schedule_finish: ['', [Validators.required]],
        afternoon_work_schedule_start: [''],
        afternoon_work_schedule_finish: [''],
        range_of_days: ['1', [Validators.required]],
        granularity: ['', [Validators.required]],
        total_appointment: [''],
        id: ['']
      }),
      holidays: [''],
      storehouses: []
    });
  }

  ngAfterViewInit(): void {
    if (this.center) {
      this.form.patchValue(this.center);
      this.holidaysC.initCenter(this.center);
    }
  }

  get WC(): UntypedFormGroup {
    return this.form.get('workshopConfig') as UntypedFormGroup;
  }

  get SC(): UntypedFormGroup {
    return this.form.get('workshopConfig') as UntypedFormGroup;
  }

  saveCenter() {
    this.form.get('holidays')?.setValue(this.holidaysC.holidays.companyHolidays)
    this.form.get('storehouses')?.setValue(this.storehouses.storehouses);
    console.log(this.form)
    console.log(this.form.value)
    console.log(new M_Center(this.form.value));
    let center = new M_Center(this.form.value);
    this.companyS.addCenter(center)
  }

}
