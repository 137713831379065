<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Seleccionar usuario</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <div *ngFor="let u of data.users" class="user-item" [ngClass]="{'selected' : u == current}" (click)="current = u">
        <app-circular-letter [user]="u"></app-circular-letter>
    </div>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="current" [disabled]="!current">Ok</button>
</div>