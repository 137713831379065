import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Smpt } from 'src/app/models/M_Smpt';
import { FormService } from 'src/app/services/form.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-smtp',
  templateUrl: './smtp.component.html',
  styleUrls: ['./smtp.component.css']
})
export class SmtpComponent {
  form: UntypedFormGroup;
  smtp_obj: M_Smpt | undefined;
  loaded = false;
  @Output() changeTab : EventEmitter<number> = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder, private fs: FormService, private snackS: SnackService, private apiS: ApiService) {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      port: ['', [Validators.required]],
      protocol: [null],
      host: ['', [Validators.required]],
      footer: ['', [Validators.required]],
    });
  }

  sendTest() {
    return this.apiS.sendSMTPTestEmail();
  }

  initTab(smtp: M_Smpt | undefined) {
    if (smtp) {
      this.form.patchValue(smtp);
      this.smtp_obj = smtp;
    }
    this.loaded = true;
  }

  makeCall() {
    if (this.fs.isOk(this.form)) {
      this.smtp_obj = new M_Smpt(this.form.value);
      this.apiS.saveSMTP(this.smtp_obj).then(_res => {
        this.snackS.show("Configuración guardada con éxito")
      })
    }
  }

  goGeneral() {
    this.changeTab.emit(0);
  }

}
