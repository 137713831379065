import { environment } from "src/environments/environment";

/**
 * App endpoints
 * @suffix : Endpoint route
 * @appendHeader : Append auth header / token ? 
 * @showLoading : Show the loading panel ?
 * @customError : When set to true, the httpListener don't show any error dialog
 */
export class Endpoint {
    private baseUrl: string;
    constructor(private suffix: string, public appendHeader: boolean = true, public showLoading: boolean = true, public customError: boolean = false) {
        this.baseUrl = environment.apiUrl;
    }
    get url() {
        return this.baseUrl + this.suffix;
    }
}