import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MY_STRING } from 'src/app/app.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Action } from 'src/app/models/M_Action';
import { ViewPath } from 'src/app/app-routing.module';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { RouterService } from 'src/app/services/router.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';

@Component({
  template: ``
})

export class ActionCreateEditParentComponent extends ParameterStateComponent {

  actionLoaded = false;
  actionFailedToLoad = false;
  action: M_Action | undefined;
  startDate: Date | undefined;
  v = ViewPath;

  constructor(public apiS: ApiService, routerS: RouterService, route: ActivatedRoute, @Inject(MY_STRING) parameters: string[], public d: MatDialog, public confirmD: ConfirmDialogService) {
    super(routerS, route, parameters);
  }

  override onParam(k: string, v: any) {
    if (k == "or" || k == "budget") {
      this.apiS.getAction(v).then(res => {
        if (res != undefined && res instanceof M_Action) {
          this.action = res;
          this.actionLoaded = true;
          this.fillViewWithAction(this.action);
        }
        else {
          this.actionFailedToLoad = true;
        }
      })
    }
    else if (k == "vehicle") {
      this.actionLoaded = true;
      this.fillVehicle(v);
    }

  }

  deleteAction() {
    if (this.action != undefined) {
      let title_ = this.action.isOr() ? "Eliminar OR" : "Eliminar presupuesto"
      let body_ = this.action.isOr() ? '¿Está seguro de que desea eliminar esta orden de reparación?' : '¿Está seguro de que desea eliminar este presupuesto?'
      this.confirmD.show({ title: title_, body: body_, type: "danger" }).afterClosed().subscribe(res => {
        if (res == true) {
          this.apiS.rmAction(this.action!.id).then(res => {
            if (res == "success") {
              if (this.action) {
                this.action.deleted = true; // avoid showing confirm save dialog on exit the page
              }
              this.routerS.back();
            }
          });
        }
      })
    }
  }

  /** To implement on child */
  fillViewWithAction(_a: M_Action) { }

  /** To implement on child */
  fillVehicle(vehicleId : number | undefined) { }

  /** To implement on child */
  fillSchedule(_a: Date) { }

}
