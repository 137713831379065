import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { M_Brand } from '../../models/M_Brand';
import { M_Model } from '../../models/M_Model';
import { VehicleType } from '../../enums/VehicleType';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SearchService } from 'src/app/services/search.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';

@Component({
  selector: 'app-brand-model-input',
  templateUrl: './brand-model-input.component.html',
  styleUrls: ['./brand-model-input.component.css']
})
export class BrandModelInputComponent implements OnInit {

  loaded = false;
  loadingModels = false;
  MAX_RESULTS = 10;

  @Input({ required: true }) form!: UntypedFormGroup;
  @Input({ required: false }) showPrice!: boolean;
  @Input({ required: false }) required!: boolean;
  @Input({ required: false }) customBrandModel: boolean = true;
  @Input({ required: false }) budgetComercial: boolean = false; // Nueva propiedad
  @Output() onSelectModel: EventEmitter<M_Model> = new EventEmitter();
  @Output() onFieldClear: EventEmitter<void> = new EventEmitter<void>();

  showingBrands: M_Brand[] = [];
  filteredBrands: M_Brand[] = [];
  filteredModels: M_Model[] = [];
  showingModels: M_Model[] = [];
  savedBrands: [VehicleType, M_Brand[]][] = [];

  constructor(
    private apiS: ApiService,
    private searchS: SearchService,
    private chdRef: ChangeDetectorRef,
    private confirmD: ConfirmDialogService
  ) { }

  ngOnInit(): void {
    this.brandControl.valueChanges.subscribe(val => {
      this.filterBrands(val);
    });

    this.modelControl.valueChanges.subscribe(val => {
      this.filterModels(val);
    });

    this.form.get("type")?.valueChanges.subscribe(val => {
      this.initByVehicleType(val);
    });

    this.initByVehicleType(this.form.get("type")?.value).then(res => {
      this.loaded = true;
      this.brandControl?.setValue(this.brandControl.value);
    });

    if (this.required) {
      this.brandControl.addValidators(Validators.required);
      this.modelControl.addValidators(Validators.required);
    }

    if (!this.customBrandModel) {
      this.brandControl.addValidators(this.realBrand());
      this.modelControl.addValidators(this.realModel());
    }
  }

  // Validador para la marca
  realBrand(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isBrandValid = control.value instanceof M_Brand || (typeof control.value === 'string' && control.value.trim().length > 0);
      return isBrandValid ? null : { isOk: false };
    };
  }
  onBrandBlur() {
    const brandValue = this.brandControl.value;

    // Verificar si la marca está vacía (sin selección o sin texto)
    if (!brandValue || (typeof brandValue === 'string' && brandValue.trim() === '')) {
      this.clearModel();  // Limpiar el modelo si la marca está vacía
    }
  }
  // Validador para el modelo
  realModel(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isModelValid = control.value instanceof M_Model || (typeof control.value === 'string' && control.value.trim().length > 0);
      return isModelValid ? null : { isOk: false };
    };
  }

  initByVehicleType(vt: VehicleType) {
    return new Promise<any>(resolve => {
      let isSaved = this.isbrandSaved(vt);
      if (isSaved) {
        this.initShowingBrands(isSaved[1]);
        resolve(true);
      } else {
        this.apiS.brands(vt === VehicleType.bike ? 0 : 1).then(res => {
          this.initShowingBrands(res);
          this.savedBrands.push([vt, res]);
          resolve(true);
        });
      }
    });
  }

  isbrandSaved(vt: VehicleType) {
    return this.savedBrands.find(bf => bf[0] === vt);
  }

  initShowingBrands(brands: M_Brand[]) {
    this.showingBrands = brands;
    this.refreshModelsBasedOnBrand(this.brandControl.value);
  }

  refreshModelsBasedOnBrand(val: MatAutocompleteSelectedEvent | M_Brand | undefined) {
    const brand = val instanceof M_Brand ? val : (val?.option?.value instanceof M_Brand ? val.option.value : undefined);

    // Limpia el modelo antes de buscar modelos asociados a la nueva marca seleccionada
    this.clearModel();

    if (brand) {
      this.loadingModels = true;
      this.apiS.brandModel(brand.id).then(res => {
        this.showingModels = res;
        this.filterModels(undefined);
        this.loadingModels = false;
        this.chdRef.detectChanges();
      });
    } else {
      this.showingModels = [];
    }
  }

  clearModel() {
    this.form.patchValue({ model: undefined });
    this.filteredModels = []; // Limpia las opciones filtradas
    this.onFieldClear.emit();
  }

  // Muestra el mensaje de advertencia solo al desenfocar
  showModelWarning() {
    if (this.budgetComercial) {
      return; // Si budgetComercial es verdadero, no se muestran mensajes
    }

    const currentModel = this.modelControl.value;
    const currentBrand = this.brandControl.value;

    if (!(currentModel instanceof M_Model) && currentModel) {
      // Si no hay una marca válida
      if (!(currentBrand instanceof M_Brand)) {
        this.confirmD.show({
          title: "¡Atención!",
          body: "Se van a crear una marca y un modelo personalizados.",
          type: "danger",
          showCancel: false,
          confirmTxt: "OK"
        });
      } else {
        // Si hay una marca válida
        this.confirmD.show({
          title: "¡Atención!",
          body: "Se va a crear un modelo personalizado.",
          type: "danger",
          showCancel: false,
          confirmTxt: "OK"
        });
      }
    }
  }

  filterBrands(val: string | undefined) {
    if (typeof val !== "string") { return; }
    this.filteredBrands = val ? this.showingBrands.filter(option => this.searchS.match(val, option.name)) : this.showingBrands;
    this.selectByName("brand", val);
  }

  filterModels(val: string | undefined) {
    if (typeof val !== "string") { return; }
    this.filteredModels = val ? this.showingModels.filter(option => this.searchS.match(val, option.name)) : this.showingModels;
    this.selectByName("model", val);
  }

  selectByName(bm: "brand" | "model", val: string) {
    if (bm === "brand") {
      const foundBrand = this.filteredBrands.find(b => b.name.toLowerCase() === val.toLowerCase());
      if (foundBrand) { this.brandControl.setValue(foundBrand); }
    }
    if (bm === "model") {
      const foundModel = this.filteredModels.find(m => m.name.toLowerCase() === val.toLowerCase());
      if (foundModel) { this.modelControl.setValue(foundModel); }
    }
  }

  displayBrand(brand: M_Brand | string): string {
    return brand instanceof M_Brand ? brand.name : brand;
  }

  displayModel(model: M_Model | string): string {
    return model instanceof M_Model ? model.name : model;
  }

  get brandControl() { return this.form.get("brand")!; }
  get modelControl() { return this.form.get("model")!; }
}
