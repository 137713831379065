<!-- WEEK AND MONTH -->
<div class="dragable-box" [ngClass]="
  { 
    'draggable-item' : canDrag, 
    'no-draggable-item' : !canDrag, 
    'week-or' : isWeek, 
    'needsHighlight' : needH(), 
    'month-draggable': isMonth,
    'or-month-draggable': isMonth && isOrItem(item),
    'appointment-month-draggable': isMonth && isAppointmentItem(item), 
    'gray-out' : !onFilters(), 
    'opacity-drag-filters' : disabledUserDragging()
  }" cdkDrag [cdkDragDisabled]="!canDrag" [cdkDragData]="item" (cdkDragStarted)="onDrag($event)"
  (mouseenter)="onHover()" (touchend)="onHover()">

  <div class="placeholder" *cdkDragPlaceholder [ngClass]="
    {
      'week' : isWeek, 
      'month': isMonth,
      'placeholder-or' : isOrItem(item),
      'placeholder-appointment' : isAppointmentItem(item),
    }">
  </div>


  <div *ngIf="isWeek" class="w100">
    <!-- WEEK OR -->
    <lib-or-week-card *ngIf="isOrItem(item)" [item]="item" [allUsers]="allUsers" [day]="day"
      [canAssign]="workloadData?.data?.or?.assignAction != undefined && workloadData?.canUserAssign != false"
      (onAssignUser)="assignNewUser($event)" (onGoOR)="goEdit(item.id)" [onFilters]="onFilters()"
      [canContact]="workloadData?.canContact"></lib-or-week-card>

    <!-- WEEK APOINTMENT -->
    <lib-appointment-week-card *ngIf="!isOrItem(item)" [item]="item" (click)="goEdit(item.id)"
      (onModifyAppointment)="onModifyAppointment.emit($event)"></lib-appointment-week-card>
  </div>


  <!-- MONTH OR CARD SAME HTML FOR OR AND APPOINTMENTS -->
  <div *ngIf="isMonth" style="width: 100%;" (click)="goEdit(item.id)">
    <div class="df jcc aic">
      <mat-chip class="small-chip c_w">
        <span class="df aic">
          {{isOrItem(item) ? item.title_to_show: item.title_appointment}}
          <app-or-status-label *ngIf="isOrItem(item)" class="circular-status-month" [circularStatus]="true"
            [status]="item.status" [showText]="false" style="min-width: fit-content"></app-or-status-label>
          <lib-appointment-status-label class="appoinment-status-month" *ngIf="!isOrItem(item)" [circularStatus]="true"
            [status]="item.status" style="min-width: fit-content"></lib-appointment-status-label>
        </span>
      </mat-chip>
    </div>
    <p *ngIf="isOnMatMenu && isOrItem(item)" class="sub-month-text workload-overflow-text">{{item.vehicleLicense()!}}
    </p>
  </div>
</div>