import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkloadService } from 'src/app/services/workload.service';
import { DragTutorialComponent } from '../carga-taller/drag-tutorial/drag-tutorial.component';
import { ChangeDeliveryOrDialogComponent } from './change-delivery-or-dialog/change-delivery-or-dialog.component';
import { M_Appointment } from 'src/app/models/M_Appointment';
import { ApiService } from 'src/app/services/Api/api.service';
import { ModifyAppointmentHourComponent } from 'src/app/components/modify-appointment-hour/modify-appointment-hour.component';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { RouterService } from 'src/app/services/router.service';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Action } from 'src/app/models/M_Action';
import { PeriodEnum } from 'src/app/enums/PeriodEnum';
import { CoreCargaTallerComponent } from 'src/app/components/carga-taller/core-carga-taller.component';
import { WorkloadDay } from 'src/app/custom-classes/Workload/WorkloadDay';

@Component({
  selector: 'app-carga-taller-parent',
  templateUrl: './carga-taller-parent.component.html',
  styleUrls: ['./carga-taller-parent.component.css']
})
export class CargaTallerParentComponent {

  R = RolesEnum;
  v = ViewPath;

  @ViewChild(CoreCargaTallerComponent) public cargaTaller!: CoreCargaTallerComponent;

  constructor(public userS: UserService, private d: MatDialog, public wS: WorkloadService, private confirmD: ConfirmDialogService,
    private apiS: ApiService, public subS: SubscriptionService, public routerS: RouterService) {

    this.wS.refresh();

    if (!this.userS.userWorkloadTutorial) {
      this.d.open(DragTutorialComponent, { disableClose: true });
      this.userS.setuserWorkloadTutorial = true;
    }
  }

  onModifyAppointment(change: [M_Appointment, ("hour")]): void {
    var appointment = change[0];
    var changeName = change[1];
    if (changeName == "hour") {
      this.onChangeHour(appointment as M_Appointment);
    }
  }

  /** OR delivery Warn */
  openWarnDialog(data: M_Action) {
    this.d.open(ChangeDeliveryOrDialogComponent, { data: data });
  }

  onChangeHour(appointment: M_Appointment) {
    const dialogRef = this.d.open(ModifyAppointmentHourComponent, {
      data: appointment,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //A partir del resultado del dialogo, modificamos la fecha de la cita
        appointment.day = new Date(result);
        this.apiS.editAppointmentClient(appointment!, appointment!.hash).then(_res => {
          this.regenerateCalendar();
        })
      }
    });
  }

  regenerateCalendar() {
    if (!this.cargaTaller.workloadData.isOrView) {
      switch (this.cargaTaller.selectedPeriod) {

        case (PeriodEnum.WEEK): {
          this.cargaTaller.currentWeek = this.cargaTaller.getweekDaysByDay(new Date(this.cargaTaller.refDay));
          this.sortByHour(this.cargaTaller.currentWeek.days);
          break;
        }

        case (PeriodEnum.MONTH): {
          this.cargaTaller.currentMonth = this.cargaTaller.getMonthByDay(new Date(this.cargaTaller.refDay));
          this.cargaTaller.currentMonth.weeks.forEach(week => { this.sortByHour(week.days) })
          break;
        }
      }
      this.cargaTaller.chdRef.detectChanges();
    }
  }

  sortByHour(wd: WorkloadDay[]) {
    wd.forEach(day => {
      day.data.sort((a, b) => {
        if (a instanceof M_Appointment && b instanceof M_Appointment) {
          return a.appointment_date > b.appointment_date ? 1 : -1
        }
        return 0;
      })
    })
  }

}