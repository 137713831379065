import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { VehicleOwnerSearchCreateComponent } from 'src/app/components/vehicle-owner-search-create/vehicle-owner-search-create.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { getPrimaryColor, getWarnColor, getAccentColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-link-vehicle',
  templateUrl: './link-vehicle.component.html',
  styleUrls: ['./link-vehicle.component.css']
})
export class LinkVehicleComponent extends ParameterStateComponent implements OnInit {
  primary = getPrimaryColor;
  warn = getWarnColor;
  accent = getAccentColor;
  
  @ViewChild(VehicleOwnerSearchCreateComponent) ownerComponent!: VehicleOwnerSearchCreateComponent;
  ve: M_Vehicle | undefined;
  v = ViewPath;
  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService,
    private formS: FormService, public params: ParamsService) {
    super(routerS, route, ["vehicle"]);
  }

  ngOnInit(): void { }

  override onState(state: any) {
    if (state instanceof M_Vehicle) {
      this.ve = state;
    }
  }
  override onParam(_param: string, value: any) {
    this.apiS.getVehicleById(value).then(resp => {
      this.ve = resp;
    })
  }

  returnClient() {
    if (this.ve && this.ve.client) {
      this.routerS.goWithQueryParams(ViewPath.vehicleDetails, { vehicle: this.ve.vehicle_id })
    }
    else {
      this.routerS.goTo(ViewPath.contacts);
    }
  }

  linkVehicle() {
    /** Cliente ya existente */
    if (this.ownerComponent.ch1?.checked) {
      if (this.formS.isOk(this.ownerComponent.searchForm.form)) {
        this.apiS.linkVechicle(this.ve!.vehicle_id, this.ownerComponent.cSelected!.client_id).then(_resp => {
          this.params.go(this.v.vehicleDetails, this.ve!.vehicle_id)
        })
      }
    }
    /**Creación de un nuevo cliente */
    else {
      if (this.formS.isOk(this.ownerComponent!.clientForm.form)) {
        let c = new M_Contact(this.ownerComponent.clientForm.form.value);
        this.apiS.linkVechicleWithNewClient(this.ve!.vehicle_id, c).then(res => {
          if (res.client_id) {
            this.params.go(this.v.contactDetails, res.client_id)
          }
          else {
            this.routerS.goTo(this.v.dashboard);
          }
        })
      }
    }
  }
}
