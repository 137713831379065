<div class="advanced-details-container" *ngIf="ve != undefined || (ve == undefined && !loaded); else notfound">

    <app-go-back class="back" [text]="'Listado vehículos'" [v]="v.vehicles" [show]="loaded"></app-go-back>

    <div class="vehicle">
        <app-card [contentLoaded]="loaded" class="bc_b1">
            <app-advanced-details *ngIf="ve">

                <app-advanced-title class="df aic">
                    {{ve.getName()}}
                    <img class="imgBrand" [src]="ve.getBrandImageUrl()" (error)="onImgError($event)">
                </app-advanced-title>

                <app-advanced-subtitle>
                    {{ve.license}}
                </app-advanced-subtitle>

                <app-advanced-main-actions>
                    <app-main-or-button [roles]="[R.ADMIN, R.ADVISER]" [vehicle]="ve"
                            [client]="undefined"></app-main-or-button>

                        <!-- Adviser-->
                        <app-main-appointment-button adviser [client]="undefined"
                            [vehicle]="ve"></app-main-appointment-button>

                        <!-- Recambista -->
                        <app-main-normal-budget-button recambista [client]="undefined"
                            [vehicle]="ve"></app-main-normal-budget-button>

                        <!-- Comercial-->
                        <app-main-budget-comercial-button comercial *ngIf="ve.client"
                            [c]="ve.client"></app-main-budget-comercial-button>
                </app-advanced-main-actions>

                <app-advanced-menu-actions>
                    <button mat-menu-item (click)="params.go(v.createvehicle, ve!.vehicle_id!)">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>
                    <app-main-or-button [client]="undefined" [vehicle]="ve"
                        [style]="'menu-option'"></app-main-or-button>
                    <app-main-appointment-button [client]="undefined" [vehicle]="ve"
                        [style]="'menu-option'"></app-main-appointment-button>
                    <app-main-normal-budget-button [client]="undefined" [vehicle]="ve"
                        [style]="'menu-option'"></app-main-normal-budget-button>
                    <app-main-budget-comercial-button *ngIf="ve.client" [c]="ve.client"
                        [style]="'menu-option'"></app-main-budget-comercial-button>
                    <button mat-menu-item (click)="params.go(v.linkvehicle, ve.vehicle_id)"> 
                        <mat-icon>sync_alt</mat-icon>
                        Cambiar propietario
                    </button>
                    <button mat-menu-item (click)="params.go(v.sellvehicleclient, ve.vehicle_id)" vnvo>
                        <mat-icon>shopping_cart</mat-icon>
                        Comprar vehículo
                    </button>
                    <button admin mat-menu-item (click)="deleteS.delete(ve)">
                        <mat-icon>delete</mat-icon>
                        Eliminar
                    </button>
                </app-advanced-menu-actions>

            </app-advanced-details>
            
            <app-advanced-details-footer *ngIf="ve">
                <!-- Title-->
                <app-footer-title>Ver más datos de vehículo</app-footer-title>
                <!-- Content on title right -->
                <app-footer-right>
                    <app-contact-client-rectangle [client]="ve.client"
                        *ngIf="ve.client;"></app-contact-client-rectangle>
                </app-footer-right>

                <!--Footer content-->
                <app-footer-content>

                    <app-section>
                        <app-section-title>Información general</app-section-title>
                        <app-section-content>
                            <app-card-row-content *ngIf="ve != undefined" [data]="
                            [['Kilómetros', ve.km],
                            ['Serie', ve.getSerieName],
                            ['Chasis', ve.chassis],
                            ['Color', ve.color],
                            ['Cilindrada', ve.cc],
                            ['Tipo de motor', ve.engineType],
                            ['Matriculación', ve.license_date?.humanFormat()]]">
                            </app-card-row-content>
                        </app-section-content>
                    </app-section>

                    <app-section>
                        <app-section-title>Propietario</app-section-title>
                        <app-section-content>
                            <app-card-row-content [noPaddingLeft]="true" [data]="[
                            ['Nif', ve.client!.nif],
                            ['Teléfono', ve.client!.phone],
                            ['Email', ve.client!.email]]">
                            </app-card-row-content>
                        </app-section-content>
                    </app-section>

                    <app-section>
                        <app-section-title>Documentación</app-section-title>
                        <app-section-content>
                            <div class="documentos">
                                <div>
                                    <app-card-subtitle [first]="true" [noMarginBottom]="true"
                                        class="df aic">Documentación
                                        <button mat-icon-button (click)="documentation.clickInputTrigger()">
                                            <mat-icon [filled]="true">add_circle</mat-icon>
                                        </button>
                                    </app-card-subtitle>
                                    <p *ngIf="!hasDocuImages()" class="c_t2">Aún no has subido imágenes</p>
                                    <lib-file-upload #documentation class="hidde_add" [uploadtype]="'multiple'"
                                        [maxImagesLength]="5" [maxSize]="6"
                                        (stateChanged)="uploadImages($event, 'docu_img')"
                                        (onRemoveImage)="removeImage($event)">
                                    </lib-file-upload>
                                </div>
                                <div>
                                    <app-card-subtitle [first]="true" [noMarginBottom]="true" class="df aic">Imágenes
                                        vehículo
                                        <button mat-icon-button (click)="vehicleImages.clickInputTrigger()">
                                            <mat-icon [filled]="true">add_circle</mat-icon>
                                        </button>
                                    </app-card-subtitle>
                                    <p *ngIf="!hasVehicleImages()" class="c_t2">Aún no has subido imágenes</p>
                                    <lib-file-upload #vehicleImages class="hidde_add" [phoneMultipleUpload]="true"
                                        [uploadtype]="'multiple'" [maxImagesLength]="5" [maxSize]="6"
                                        (stateChanged)="uploadImages($event, 'vehicle_img')"
                                        (onRemoveImage)="removeImage($event)">
                                    </lib-file-upload>
                                </div>
                            </div>
                        </app-section-content>
                    </app-section>
                </app-footer-content>
            </app-advanced-details-footer>
        </app-card>
    </div>
    <div class="or">
        <app-card [contentLoaded]="loaded" [noData]="ve && !ve.actions.length">
            <app-card-title>Historial reparaciones</app-card-title>
            <app-action-history *ngIf="ve && ve.actions.length" [clientSide]="false" [actions]="ve.actions" [purchases]="ve.purchases" [invoices]="ve.invoices"
                [invoices]="ve!.invoices" [isVehicle]="true"></app-action-history>
        </app-card>
    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el vehículo'" [goText]="'Ir al listado de vehículos'"
        [view]="v.vehicles">
    </app-model-not-found>
</ng-template>