import { Injectable } from '@angular/core';
import { CheckVersionService } from './check-version.service';
import { ListenerService } from './listener.service';
import { RouterService } from './router.service';
import { AuthGuard } from '../guards/auth.guard';
import { Route, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

/**
 * Funciones útiles para llamar en el punto de entrada de la aplicación
 * Nota : Al importar como servicio, se deshabilitan los 'logs' si se trata del entorno de producción
 */
@Injectable({
  providedIn: 'root'
})
export class EntryPointService {
  noToolbarViews: Route[] = []

  constructor(private routerS: RouterService, private router: Router, private versionS: CheckVersionService, private listenerS: ListenerService) {
    this.versionS.checkVersion();
    this.listenerS.addBeforeInput();
    this.generateNoToolbarViews();
    this.disableAppLogs();
    console.log("** CORE ENTRY POINT **")
  }

  disableAppLogs() {
    if (environment.production) {
      if (window) {
        window.console.log = function () { };
      }
    }
  }

  generateNoToolbarViews() {
    this.router.config.forEach(element => {
      if (!this.routerS.hasGuard(element, AuthGuard)) {
        this.noToolbarViews.push(element)
      }
    });
  }

  currentViewNeedToolbar(): boolean {
    let url = this.routerS.route_;
    let r : Route | undefined = this.routerS.getViewFromRoute(url);
    if (!r){return false}
    else {return !this.noToolbarViews.some(route => route.path === r!.path);}
  }
}