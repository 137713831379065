<div>
    <div class="container" [ngClass]="{'dn' : albaranFialedToLoad}">
        <!-- ACTION GENERAL INFO -->
        <div class="title" *ngIf="loaded">
            <app-go-back class="title" *ngIf="albaran != undefined" [text]="'Listado albaranes'" [v]="v.albaranes"
                [show]="albaran != undefined"></app-go-back>
            <app-view-title *ngIf="albaran == undefined">Crear albarán</app-view-title>
        </div>


        <div class="general_info">
            <app-card [contentLoaded]="loaded" class="bc_b1">
                <form [formGroup]="form" class="eina-form">

                    <!-- Albaran not created-->
                    <div *ngIf="albaran == undefined">

                        <div class="df jcsb w100">
                            <app-card-title>Información general</app-card-title>
                        </div>
                        <div>
                            <div *ngIf="!albaran">
                                <p class="c_t2">Selecciona un cliente para crear un nuevo albarán 🆕📝 </p>
                            </div>

                            <!-- <div class="df jcfe mt0" style="align-items: baseline;" *ngIf="albaran">
                                    <mat-checkbox [formGroup]="form" formControlName="block"
                                        [(ngModel)]="albaran.block">Bloquear</mat-checkbox>
                                </div> -->
                            <div class="eina-form-section">
                                <app-class-searcher class="w50 onboarding-albaran-select-client" #clientSearcher
                                    [masterClass]="client" searchPlaceHolder="Buscar cliente"
                                    (onSelect)="createAlbaran($event)"
                                    [noSelectionPlaceHolder]="'Ningún cliente seleccionado'"
                                    [required]="true" [canRemove]="!albaran"
                                    [createData]="{
                                        text : 'Nuevo cliente',
                                        by : 'dialog'
                                    }">
                                </app-class-searcher>
                                <div [ngTemplateOutlet]="realization"></div>
                            </div>

                        </div>
                    </div>

                    <ng-template #realization>
                        <mat-form-field appearance="outline" class="w50">
                            <mat-label>Realización</mat-label>
                            <input matInput placeholder="dd/mm/aaaa" [matDatepicker]="picker_1"
                                (click)="picker_1.open()" formControlName="date_user">
                            <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                            <mat-datepicker #picker_1></mat-datepicker>
                        </mat-form-field>
                    </ng-template>

                    <!-- Created albaran -->
                    <div *ngIf="albaran">
                        <app-advanced-details>

                            <app-advanced-title>
                                <div class="df aic gap5">
                                    {{albaran.title_id}}
                                    <app-albaran-status-label class="ml5"
                                        [status]="albaran.status"></app-albaran-status-label>
                                </div>
                            </app-advanced-title>

                            <app-advanced-subtitle>
                                Creado el {{albaran.created_at.dayMonthYearFormat()}}
                            </app-advanced-subtitle>

                            <app-advanced-main-actions>
                                <div class="df jcc" style="gap: 10px;"
                                    [ngClass]="{'dn' : (albaran && albaran.invoiced)}">
                                    <!-- ACTIONS ON OPEN ALBARAN -->

                                    <button mat-flat-button color="primary" [disabled]="!screenChanges"
                                        (click)="saveAlbaran()" *ngIf="opened ||albaran == undefined">
                                        Guardar
                                    </button>

                                    <div *ngIf="canInvoiceS.data(albaran, this) as invocieData"
                                        [matTooltip]="invocieData.tooltip">
                                        <button *ngIf="albaran && closed" mat-flat-button color="primary"
                                            (click)="invoiceAlbaran()" [disabled]="invocieData.disable"
                                            [matBadge]="invocieData.badge" [matBadgeColor]="invocieData.badgeColor">
                                            <mat-icon>receipt_long</mat-icon>
                                            {{!albaran.type.interno ? 'Facturar' : 'Tramitar'}}
                                        </button>
                                    </div>

                                    <button *ngIf="opened ||albaran == undefined" mat-raised-button color="primary"
                                        [disabled]="closeDisabled" (click)="changeAlbaranStatus(al_c)">
                                        <mat-icon>assignment_turned_in</mat-icon>
                                        Cerrar
                                    </button>

                                    <button *ngIf="closed" mat-flat-button color="primary"
                                        (click)="changeAlbaranStatus(al_o)">
                                        <mat-icon>content_paste_go</mat-icon>
                                        Abrir
                                    </button>
                                </div>
                                <!-- DOCUMENTATION !-->
                                <app-documentation-pdf-menu [albaran]="albaran"></app-documentation-pdf-menu>
                            </app-advanced-main-actions>

                            <app-advanced-menu-actions>
                                <div
                                    [matTooltip]="albaran.someProductRequested ? 'El albarán tiene productos en camino' : ''">
                                    <button mat-menu-item (click)="deleteAlbaran()"
                                        [disabled]="albaran.someProductRequested">
                                        <mat-icon>delete</mat-icon> Eliminar
                                    </button>
                                </div>
                            </app-advanced-menu-actions>

                        </app-advanced-details>

                        <app-advanced-details-footer [starOpen]="missingDir">

                            <app-footer-title>Ver más datos de albarán</app-footer-title>

                            <app-footer-right>
                                <div class="df aic gap10">
                                    <button mat-flat-button color="ice"
                                        [matTooltip]="'Al bloquearlo, no se tendrá en cuenta en la facturación masiva'">
                                        <mat-checkbox [disabled]="!albaran.canModify" [formControlName]="'block'"></mat-checkbox>
                                        Bloquear
                                    </button>
                                    <app-contact-client-rectangle *ngIf="albaran.client"
                                        [client]="albaran.client"></app-contact-client-rectangle>
                                </div>
                            </app-footer-right>

                            <app-footer-content>

                                <app-section>
                                    <app-section-title>Información general</app-section-title>
                                    <app-section-content>
                                        <div class="eina-form-section">
                                            <div [ngTemplateOutlet]="realization"></div>
                                            <mat-form-field class="w100" appearance="outline">
                                                <mat-label>Comentarios</mat-label>
                                                <textarea [formControlName]="'comment'" matInput cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                                    cdkAutosizeMaxRows="3">
                                                    </textarea>
                                            </mat-form-field>
                                        </div>
                                    </app-section-content>
                                </app-section>

                                <app-section [starOpened]="missingDir">
                                    <app-section-title>Dirección</app-section-title>
                                    <app-section-content>
                                        <div class="eina-form-section" *ngIf="albaran && albaran.client">
                                            <app-contact-sending-address-selector [contact]="albaran.client"
                                                [fc]="form.get('extra_addres')!" [canChange]="opened"
                                                (onChangedAddress)="albaran.extra_addres = $event;">
                                            </app-contact-sending-address-selector>
                                        </div>
                                    </app-section-content>
                                </app-section>
                            </app-footer-content>

                        </app-advanced-details-footer>
                    </div>
                </form>
            </app-card>
        </div>
    </div>

    <div class="albaran-products-container" [ngClass]="{'dn' : albaranFialedToLoad}">

        <!-- ALBARÁN PRODUCTS  class="bc_b0"-->
        <div class="albaran-products" [ngClass]="{'dn' : albaranFialedToLoad}">
            <app-card [contentLoaded]="loaded"
                [locked]="albaran == undefined ? 'Crea un albarán para poder realizar acciones' : ''">
                <div class="dg">
                    <app-card-title>
                        <div class="df fww">
                            Albarán
                            <button mat-button *ngIf="albaran && albaran.invoicePdf" color="primary" class="mla"
                                (click)="billPreview()">
                                <span>{{!albaran.type.interno ? 'Ver factura' : 'Ver trámite'}}</span>
                                <span class="pdf-label"></span>
                            </button>
                        </div>
                    </app-card-title>

                    <div class="df" style="gap: 10px;">
                        <app-or-type-label *ngIf="albaran" [menuIconOpen]="albaran.canModify"
                            (click)="preventDropDownOpening($event)" [showText]="false" [tipo]="[albaran.type]"
                            [mat-menu-trigger-for]="albaran.canModify ? albaranType : null" [pointer]="true">
                        </app-or-type-label>
                    </div>
                </div>
                <app-product-line-table #plt [products]="albaran? albaran.products : []"
                    [lineTypes]="['product', 'custom']" [canModifyTable]="albaran ? albaran.canModify : false"
                    [extendedInfoConfig]="{showColumn:true, actions:['manual-fault', 'remove-reservation']}"></app-product-line-table>
            </app-card>
        </div>

        <mat-menu #albaranType="matMenu">
            <app-or-type-selector [isAlbaran]="true" (onClickOption)="onTypeChange($event)"></app-or-type-selector>
        </mat-menu>

    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [ngClass]="{'dn' : !albaranFialedToLoad}" [title]="'No se ha encontrado el albarán'"
        [goText]="'Ir al listado de albaranes'" [view]="v.albaranes">
    </app-model-not-found>
</ng-template>