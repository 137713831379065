type action_types_names = "Cita" | "Recepción Activa" | "Presupuesto" | "Orden de reparación" | "Presupuesto de recambios";

const types : [number , action_types_names][] = [
    [0, "Cita"],
    [1, "Recepción Activa"], 
    [2, "Presupuesto"], 
    [3, "Orden de reparación"],
    [4, "Presupuesto de recambios"]
]

export class action_types{
    num : number = -1;
    name : action_types_names = "Orden de reparación";
    constructor(tipo : action_types_names | number) {
        types.map(t => {
            if (typeof tipo == "number") {
                if (t[0] == tipo){
                    this.num = t[0];
                    this.name = t[1];
                }
            }
            else{
                if (t[1] == tipo){
                    this.num = t[0];
                    this.name = t[1];
                }
            }
        })
    }

    
    get isOr(){
        return this.num == 3;
    }

    get isBudget(){
        return this.num == 2;
    }

    get isRa(){
        return this.num == 1;
    }
}


export const action_type_cita = new action_types("Cita");
export const action_type_ra = new action_types("Recepción Activa");
export const action_type_budget = new action_types("Presupuesto");
export const action_type_or = new action_types("Orden de reparación");
export const action_type_recambios_budget = new action_types("Presupuesto de recambios");
