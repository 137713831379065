<div [formGroup]="form">
    <mat-form-field appearance="outline" [class]="'white cp class-searcher-mat-form-field'"
        [style.width]="width100? '100%' : null">

        <app-circular-letter class="mat-prefix-cicrular-letter" matPrefix *ngIf="isUser(selected)"
            [showFullName]="false" [showTooltip]="false" [user]="selected"></app-circular-letter>


        <mat-label>{{searchPlaceHolder}}</mat-label>

        <input autocomplete="off" [disabled]="this.disabled" [required]="required" #input matInput
            [placeholder]="searchPlaceHolder" [formControlName]="'value'" [matAutocomplete]="auto"
            (keyup)="refresh(input.value)">

        <mat-autocomplete [displayWith]="getInputText.bind(this)" autoActiveFirstOption #auto="matAutocomplete">
            <mat-option (onSelectionChange)="onSelectionChange($event)"
                *ngFor="let option of options | slice:0:MAX_OPTIONS" [value]="option" [disabled]="getDisableIF(option)">
                <div>
                    <app-circular-letter [showTooltip]="false" *ngIf="isUser(option); else text"
                        [user]="option"></app-circular-letter>
                    <ng-template #text>
                        <p style="margin-bottom : 0px;">
                            <mat-icon class="vam" *ngIf="option.icon == 'barcode'" [svgIcon]="'barcode'"></mat-icon>
                            <mat-icon class="vam" *ngIf="option.icon != 'barcode'">{{option.icon}}</mat-icon>
                            <span *ngFor="let fv of option.getOptionText(companyS.getModules()); let last = last">
                                <span class="option-text" [ngClass]="!last ? 'fw500' : 'fw300'">{{fv}}</span>
                                <span style="white-space: break-spaces;"
                                    *ngIf="!last && option.getOptionText(companyS.getModules())[1] != undefined">
                                    {{separator ? separator : "-"}} </span>
                            </span>
                        </p>
                        <p *ngIf="option.extraOptionText" class="extra-option-text">
                            {{option.extraOptionText}}</p>
                    </ng-template>
                </div>
                <ng-container *ngIf="showLeftNumber && option.getLeftNumber">
                    <p [class]="'nmb ' + option.getLeftNumber(companyS.getModules()).class_">
                        {{option.getLeftNumber(companyS.getModules()).value}}</p>
                </ng-container>
            </mat-option>

            <mat-option *ngIf="createData != undefined && loaded"
                (onSelectionChange)="createNew($event)">
                <p style="margin-bottom : 0px;">
                    <mat-icon>add_circle</mat-icon>
                    {{createData.text}}
                </p>
            </mat-option>
            <!-- Si el buscador no esta cargado -->
            <mat-option class="loading-progress" *ngIf="!loaded" [disabled]="true">
                <mat-progress-bar mode="buffer"></mat-progress-bar>
            </mat-option>
            <!-- Si el buscador esta cargado pero la array de coincidencias-->
            <mat-option *ngIf="loaded && options.length == 0" [disabled]="true">Sin coincidencias</mat-option>
        </mat-autocomplete>

        <mat-icon *ngIf="!selected" matSuffix class="cp" (click)="refresh(input.value)">arrow_drop_down</mat-icon>

        <button type="button" [matTooltip]="'Cambiar'" mat-icon-button matSuffix *ngIf="selected && canRemove"
            (click)="remove()">
            <mat-icon [filled]="true">change_circle</mat-icon>
        </button>

        <mat-hint *ngIf="hint" [class]="getHintClass">{{getHint}}</mat-hint>

    </mat-form-field>
</div>