<app-card-title>{{center?.name}}</app-card-title>

<form class="eina-form" [formGroup]="form">
    <div class="eina-form-section">
        <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input type="string" matInput formControlName="name">
        </mat-form-field>
    </div>
    <app-full-address [form]="form" [city]="center?.city"></app-full-address>

    <div class="eina-form-wrapper">
        <mat-tab-group [color]="'primary'" mat-stretch-tabs="false">
            <mat-tab>
                <ng-template mat-tab-label>
                    Taller y citas
                </ng-template>
                <app-workshop-and-appointments [form]="WC"></app-workshop-and-appointments>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Calendario
                </ng-template>
                <app-holidays-company></app-holidays-company>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Almacenes
                </ng-template>
                <app-storehouses [form]="WC"></app-storehouses>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div class="df jcc mt10">
        <button [color]="'primary'" mat-flat-button [disabled]="!form.valid" (click)="saveCenter()">
            Guardar
        </button>
    </div>

</form>