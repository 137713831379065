import { AddressToString, getTextAddress } from "./M_Address";
import { M_AppointmentConfig } from "./M_AppointmentConfig";
import { M_StoreHouse } from "./M_StoreHouse";
import { getArrayOf } from "../utils/FunctionUtils";

export class M_Center implements AddressToString {

    id: number;
    name : string;
    address: string;
    address2: string;
    zip: string;
    city: number;
    province: string;
    workshop: boolean;
    storehouse: boolean;
    boutique: boolean;
    created_at: Date | undefined;
    updated_at: Date | undefined;

    workshopConfig : M_AppointmentConfig | undefined;
    holidays : Date[] = [];
    storehouses: M_StoreHouse[] = [];
    
    /** To do centers  */
    /** El calendario y la configuración de taller citas va por centro! */

    constructor(d: any) {
        this.id = d.id;
        this.name = d.name;
        this.address = d.address;
        this.address2 = d.address2;
        this.zip = d.zip;
        this.city = d.city;
        this.province = d.province;
        this.workshop = d.workshop;
        this.storehouse = d.storehouse;
        this.boutique = d.boutique;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
        this.workshopConfig = d.workshopConfig ?  new M_AppointmentConfig(d.workshopConfig) : undefined;
        this.holidays = d.holidays ? getArrayOf(Date, d.holidays) : [];
        this.storehouses = d.storehouses ?  getArrayOf(M_StoreHouse, d.storehouses) : [];
    }

    toText(): string {
        return getTextAddress(this)
    }
}


export const EXAMPLECENTER = new M_Center({
    "id": 1,
    "name" : "Align Items Center",
    "country": {
        "name": "Spain",
        "alpha2Code": "ES",
        "alpha3Code": "ESP",
        "numericCode": "724",
        "callingCode": "+34"
    },
    "address": "Calle Gran Vía, 20",
    "address2": "Edificio A, Planta 3",
    "zip": "08700",
    "city": 3846,
    "province": "Madrid",
    "workshop": true,
    "storehouse": false,
    "boutique": true,
    "created_at": "2023-09-01T10:30:00.000Z",
    "updated_at": "2023-09-15T14:00:00.000Z"
})

export const EXAMPLECENTER2 = new M_Center({
    "id": 2,
    "name" : "Align Items Left",
    "country": {
        "name": "Spain",
        "alpha2Code": "ES",
        "alpha3Code": "ESP",
        "numericCode": "724",
        "callingCode": "+34"
    },
    "address": "Calle Gran Vía, 20",
    "address2": "Edificio A, Planta 3",
    "zip": "08700",
    "city": 3846,
    "province": "Madrid",
    "workshop": true,
    "storehouse": false,
    "boutique": true,
    "created_at": "2023-09-01T10:30:00.000Z",
    "updated_at": "2023-09-15T14:00:00.000Z"
})
