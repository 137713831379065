
<div class="df" [ngClass]="{'box-shadow' : boxShadow, 'cp' : imageUpload, 'circle-disabled' : disabled}" (click)="uploadNewImage()">
    <!-- Circle -->
    <div [class]="'circle ' + (!hasImage || loadImgError? '_' + circleBackground : '')" 
         [ngClass]="{'grayOut' : grayOut, 'small' : small, 'big' : big, 'medium' : medium}" 
         [matTooltip]="this.showTooltip ? this.imageUpload ?  ('Subir nueva imagen') : this.userName : ''">
        <!-- If the user has image -->
        <img *ngIf="hasImage && !loadImgError" [src]="user!.img" (error)="imgError()">
        
        <!-- First charater of the user -->
        <span *ngIf="!hasImage || loadImgError " class="singleLetter" [ngClass]="{'me' : isMe && checkIsMe}" >
            {{firstLetter}}
        </span>
    </div>
    <!-- By default, the component shows the user full name -->
    <div class="df aic ml5" *ngIf="showFullName">
        <p class="nmb nametag">{{userName}}</p>
    </div>
</div>

<lib-file-upload style="display: none" [crop]="true" *ngIf="imageUpload" (stateChanged)="processNewUpload($event)"></lib-file-upload>