import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';

@Component({
  selector: 'app-main-appointment-button',
  templateUrl: './main-appointment-button.component.html',
  styleUrls: ['./main-appointment-button.component.css']
})
export class MainAppointmentButtonComponent {
  v = ViewPath;
  
  @Input({ required: true }) client: M_Contact | undefined;
  @Input({ required: true }) vehicle?: M_Vehicle | undefined;
  @Input() style: "flat" | "menu-option" = "flat";

  constructor(public paramsS: ParamsService) { }

  get singleVehicle() : M_Vehicle | undefined {
    if (this.client) {
      return this.client.vehicles.length == 1 ? this.client.vehicles[0] : undefined;
    }
    return this.vehicle;
  }

  get multipleVehicles() : M_Vehicle[] | undefined {
    if (this.client) {
      return this.client.vehicles.length > 1 ? this.client.vehicles : undefined;
    }
    return undefined;
  }

  get show(){
    if (this.client){
      return this.client.hasVehicles();
    }
    return this.vehicle != undefined;
  }

}
