import { Component, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-estimated-time',
  templateUrl: './estimated-time.component.html',
  styleUrls: ['./estimated-time.component.css']
})
export class EstimatedTimeComponent {

  form: UntypedFormGroup;

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: { hours: number | undefined, minutes: number | undefined },
    public dRef: MatDialogRef<EstimatedTimeComponent>) {
    this.form = this.fb.group({
      estimated_time_hours: data.hours,
      estimated_time_minutes: data.minutes,
    });
  }

  onClickOk() {
    console.log(this.form.value)
    this.dRef.close(this.form.value);
  }

}
