<div class="advanced-details-container">
    <div class="title" style="grid-area: title;">
        <app-view-title>
            Crear factura <span class="c_t2" *ngIf="recoveredDraft"> (borrador) </span>
        </app-view-title>
    </div>

    <div class="ig onboarding-invoice-selec-user" [formGroup]="form">
        <!-- INFORMACIÓN GENERAL -->
        <app-card [contentLoaded]="contentLoaded" class="bc_b1" [cornerMatMenu]="deleteTemplate">
            <app-card-title>Información general</app-card-title>
            <form [formGroup]="form" class="eina-form">
                <div class="eina-form-wrapper">
                    <app-class-searcher [showLeftNumber]="false" #csearcher class="onboarding-invoice-select-client"
                        [masterClass]="client" [allData]="" [width100]="true" searchPlaceHolder="Buscar cliente"
                        [extendedInfo]="true" [form_]="form" [formCname]="'client_id'" (onSelect)="formChanged = true"
                        (onRemove)="formChanged = true" [required]="classSearcherRequired()"
                        [createData]="{
                            text : 'Nuevo cliente / empresa',
                            by : 'dialog'
                        }">
                    </app-class-searcher>
                    <mat-form-field appearance="outline" class="w100">
                        <mat-label>Comentarios</mat-label>
                        <textarea (input)="formChanged = true" matInput formControlName="comments" cdkTextareaAutosize
                            cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" [placeholder]="'Comentarios'">
                        </textarea>
                    </mat-form-field>
                </div>
            </form>
            <ng-template #deleteTemplate>
                <button mat-icon-button class="mt10"
                    [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #deleteMenu="matMenu">
                    <ng-template></ng-template>
                    <button mat-menu-item [disabled]="isDeleteDisaled" (click)="disacrdDraft()">
                        <mat-icon>delete</mat-icon> Eliminar
                    </button>
                </mat-menu>
            </ng-template>
        </app-card>
    </div>

    <div class="products">
        <app-card [contentLoaded]="contentLoaded" class="pSection mt10">
            <!-- PRODUCTOS -->
            <app-card-title>Productos</app-card-title>
            <app-product-line-table *ngIf="currentBill" [products]="currentBill.breakdown.all"
                [lineTypes]="['product', 'custom']"
                [extendedInfoConfig]="{showColumn:true, actions:['manual-fault','remove-reservation']}"></app-product-line-table>
            <mat-divider></mat-divider>
            <table class="breakdown no-pointer" *ngIf="currentBill && currentBill.getTotalBreakdown() as totals">
                <tr>
                    <td class="fw400 total">
                        Subtotal
                        <p class="fss fsi nmb" *ngIf="totals.client_excent_iva">Cliente exento de IVA</p>
                    </td>
                    <td><span class="c_p fw600" money [val]="totals.subtotal"></span></td>
                </tr>
                <tr *ngFor="let row of totals.iva_rows">
                    <td *ngIf="row.iva != 0" class="fw400 total">IVA ({{row.iva}}%)</td>
                    <td *ngIf="row.iva == 0" class="fw400 total">EXENTO</td>
                    <td><span class="c_p fw600" money [val]="row.iva == 0? row.subtotal : row.total"></span></td>
                </tr>
                <!-- change iva code-->
                <!--
                    <tr>
                        <td class="fsm fw500 total">
                        <span class="c_p fsxs mr5 cp" (click)="openChangeIva()">Modificar</span> IVA ({{iva.value}}%) :
                        </td>
                        <td><span class="c_p fw400">{{bc.iva}} €</span></td>
                    </tr>
                -->
                <tr>
                    <td class="fsm fw500 total">Total</td>
                    <td><span class="c_p fw600" money [val]="totals.total"></span></td>
                </tr>
            </table>

            <div *ngIf="canInvoiceS.data(currentBill, this) as invocieData" [matTooltip]="invocieData.tooltip" class="df jcc">
                <button class="onboarding-invoice-finish" [disabled]="!canCreateInvoice() || invocieData.disable"
                    [matBadge]="invocieData.badge" [matBadgeColor]="invocieData.badgeColor" subscribed-user
                    mat-flat-button color="primary" (click)="createBill()">
                    Crear factura
                </button>
            </div>

        </app-card>
    </div>
</div>


<button class="general-save onboarding-invoice-save" [ngClass]="saveButton.disabled ? 'hide' : 'show'" #saveButton
    [disabled]="!invoiceHasChanges || !isClientOk" subscribed-user mat-flat-button color="primary"
    (click)="saveInvoice()">
    Guardar
</button>