import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_User } from 'src/app/models/M_User';

@Component({
  selector: 'app-worker-selector',
  templateUrl: './worker-selector.component.html',
  styleUrls: ['./worker-selector.component.css']
})
export class WorkerSelectorComponent {
  current : M_User | undefined;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { users: M_User[], selected: M_User }, public dRef: MatDialogRef<WorkerSelectorComponent>) {
    this.current = this.data.selected;
  }
}
