<div class="ad-first-section">

    <div>
        <!-- Title -->
        <p class="advanced-title df aic">
            <ng-content select="app-advanced-title"></ng-content>
        </p>

        <!-- Subtitle -->
        <p class="advanced-subtitle">
            <ng-content select="app-advanced-subtitle"></ng-content>
        </p>

        <!-- Heading -->
        <p class="advanced-heading">
            <ng-content select="app-advanced-heading"></ng-content>
        </p>
    </div>

    <!-- Main actions-->
    <div class="ad-first-section-actions">
        <ng-content select="app-advanced-main-actions"></ng-content>
        <!-- Side actions -->
        <button mat-mini-fab [color]="'white'" [mat-menu-trigger-for]="sideActions" *ngIf="showButton">
            <mat-icon>more_horiz</mat-icon>
        </button>
    </div>

    <!-- Side actions menu -->
    <mat-menu #sideActions>
        <ng-content select="app-advanced-menu-actions"></ng-content>
    </mat-menu>

</div>