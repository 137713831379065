<div>
    <mat-toolbar color="primary">

        <!-- <button [color]="'primary'" *ngIf="showMenuButton" #btn (click)="onclick.emit();" onclick="this.blur()"
            mat-icon-button class="df aic cp br5" [ngClass]="{'onboarading-menu' : mc.hiddenToUser}">
            <mat-icon>{{mc.lockmenu && mc.isDesktop ? 'menu_open' : 'menu'}}</mat-icon>
        </button> -->

        <app-menu-trigger *ngIf="showMenuButton" [mc]="mc" [isOn]="'toolbar'"
            (onclick)="onclick.emit()"></app-menu-trigger>

        <!-- CURRENTLY WORKING OR-->
        <div class="df aic" [ngClass]="timerS.refOr != undefined ? 'or-time-appear' : 'or-time-disappear'">
            <div class="timer-container" *ngIf="timerS.refOr" (click)="timerS.goOr(timerS.refOr)" mat-ripple>
                <p class="first-text"><span class="or-prefix">OR</span> {{timerS.refOr.id_to_show}} </p>
                <p class="elapsed-time cp nmb">{{timerS.elapsed}}</p>
            </div>
            <p class="more-or-text" *ngIf="timerS.hasMore" [matMenuTriggerFor]="otherOr">{{timerS.moreNumber}} más</p>
        </div>
        <mat-menu #otherOr="matMenu">
            <div *ngFor="let or of timerS.moreOr" class="timer-container" style="margin: 5px;"
                (click)="timerS.goOr(or)">
                <p class="first-text"><span class="or-prefix">OR</span> {{or.id_to_show}} </p>
                <p class="elapsed-time cp nmb">{{timerS.orElapsed(or)}}</p>
            </div>
        </mat-menu>

        <app-big-mama *ngIf="f.bigMama && showBigMamaByRoleAndView()" class="gsearcher"
            (onclick)="onclick.emit();"></app-big-mama>

        <span class="example-spacer"></span>


        <div class="df aic" style="gap:5px">


            <!-- Incidencias -->
            <button mat-mini-fab color="primary" class="issues" [matTooltip]="'Reportar incidencia'" [go]="v.issues">
                <mat-icon [filled]="false">support_agent</mat-icon>
            </button>

            <!-- Help -->
            <button [matTooltip]="'Ayuda'" class="help onboarading-help-toolbar" mat-mini-fab color="primary"
                (click)="onboarding.open()">
                <mat-icon [filled]="false">help_outline</mat-icon>
            </button>

            <!-- Notification -->
            <app-notifications [class_]="'mr-20'" [autoUpdate]="false" [getAll]="apiS.getNotifications.bind(apiS)"
                [readAll]="apiS.readAllNotifications.bind(apiS)" [setReaded]="apiS.setNotificationReaded.bind(apiS)"
                (onClickNotification)="mc.close(); notificationAction($event)">
            </app-notifications>


            <!-- Profile -->
            <button mat-icon-button [matMenuTriggerFor]="profileMenu" class="onboarding-profile">
                <app-circular-letter class="absolute-center" [checkIsMe]="false" [showTooltip]="false"
                    [user]="userS.user" [showFullName]="false"></app-circular-letter>
                <ng-template #loadingProfile>
                    <app-skeleton class="absolute-center" [circle]="true" [transparency]="true" [height]="34"
                        [width]="34"></app-skeleton>
                </ng-template>
            </button>

            <mat-menu #profileMenu>
                <button mat-menu-item (click)="goProfile()">
                    <span>Perfil</span>
                </button>
                <button class="help-on-profile" mat-menu-item (click)="onboarding.open()">Ayuda</button>
                <button class="issues-on-profile" mat-menu-item (click)="routerS.goTo(v.issues)">Incidencias</button>
                <button mat-menu-item (click)="logOut()">
                    <span>Cerrar sessión</span>
                </button>
            </mat-menu>

        </div>
    </mat-toolbar>
</div>