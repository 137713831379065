import { M_Product } from "./Products/M_Product";
import { M_Contact } from "./M_Contact";
import { M_CustomProduct } from "./Products/M_CustomProduct";
import { M_Fault } from "./M_Fault";
import { M_PDFTemplate } from "./M_PDFTemplate";
import { ISearchFiltrable } from "../interfaces/ISearchFiltrable";
import { or_types, or_types_normal } from "../custom-classes/or_types";
import { albaran_status, albaran_status_open } from "../custom-classes/albaran_status";
import { getArrayOf } from "../utils/FunctionUtils";
import { match } from "../services/search.service";


export class M_Albaran implements ISearchFiltrable {

    id: number | undefined; //Database id
    // deliverynote_id: number; //Company related id
    title_id: string; //AC000...
    client_id: number;
    vehicle_id: number;
    type: or_types
    status: albaran_status = albaran_status_open;
    client: M_Contact | undefined;
    comment: string;
    products: (M_Product | M_CustomProduct)[] = [];
    sended: boolean = false;
    block: boolean = false;
    date_user: Date | undefined;
    faults: M_Fault[] = [];
    extra_addres: number | null;
    urlpdf: M_PDFTemplate[];

    /** Prevent the dialog from asking if you want to save before exiting */
    deleted: boolean;

    created_at: Date;
    updated_at: Date;

    total: number = 0; //massive

    constructor(d: any) {
        this.id = d.id ? d.id : d.dNote_id ? d.dNote_id : undefined;
        this.deleted = d.deleted
        // this.deliverynote_id = d.deliverynote_id;
        this.title_id = d.title_id || d.AC;
        this.urlpdf = d.urlpdf ? getArrayOf(M_PDFTemplate, d.urlpdf) : [];
        this.type = d.type_id ? new or_types(d.type_id) : or_types_normal;
        this.comment = d.comment;
        this.block = d.block;
        this.sended = d.sended;
        this.status = d.state != undefined ? new albaran_status(d.state) : albaran_status_open;
        this.client_id = d.client_id;
        this.vehicle_id = d.vehicle_id;
        this.client = d.client ? new M_Contact(d.client) : undefined;
        this.date_user = d.date_user ? new Date(d.date_user) : undefined;
        this.extra_addres = d.extra_addres ? d.extra_addres : null;

        let prods = d.products;
        if (Array.isArray(prods)) {
            prods.forEach(p => {
                this.products.push(p.custom ? new M_CustomProduct(p) : new M_Product(p))
            })
        }

        if (d.faults) {
            this.faults = getArrayOf(M_Fault, d.faults);
        }

        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);
        this.total = d.total;
    }


    get canModify() {
        return this.status.open;
    }

    get unsavedProducts(): (M_Product | M_CustomProduct)[] {
        return this.products.filter(p => p.line_hasChanges);
    }

    get hasChanges() {
        return this.unsavedProducts.length != 0;
    }

    get closed() {
        return this.status.closed;
    }

    get opened() {
        return this.status.open;
    }

    get invoiced() {
        return this.status.invoiced;
    }

    get canClose() {
        return this.products.length && this.status.open;
    }

    deleteProduct(product: M_Product | M_CustomProduct) {
        this.products.removeElement(product);
    }

    setUpAlbaran(client: M_Contact, d: Date | undefined) {
        this.block = false;
        this.client = client;
        this.date_user = d ? d : new Date();
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.title_id, this.client?.getName());
    }

    get albaranPdf() {
        return this.urlpdf.find(d => d.type == "AC");
    }

    get invoicePdf(): M_PDFTemplate | undefined {
        return this.urlpdf.find(d => d.type == "I");
    }

    get someProductRequested() {
        return this.products.some(p => p instanceof M_Product && p.isRequested);
    }


}