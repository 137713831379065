import { CdkDragDrop, transferArrayItem, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit, Input, Output, Inject, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { projectConfiguration } from "src/app/app.module";
import { PeriodEnum } from "src/app/enums/PeriodEnum";
import { M_Action } from "src/app/models/M_Action";
import { M_Appointment } from "src/app/models/M_Appointment";
import { RouterService } from "src/app/services/router.service";
import { SnackService } from "src/app/services/snack.service";
import { WorkloadDataGetterService } from "src/app/services/workload-data-getter.service";
import { CoreCargaTallerComponent } from "../../core-carga-taller.component";

/** ----------------------------------- */
@Component({
    selector: 'app-drag-and-drop-grid',
    templateUrl: './drag-and-drop-grid.component.html',
    styleUrls: ['./drag-and-drop-grid.component.css']
  })
  export class DragAndDropGridComponent implements OnInit {
  
    /** Period enum of filter selector */
    @Input() periodEnum!: PeriodEnum;
    pe = PeriodEnum;
    @Output() onDragFinished: EventEmitter<M_Action | M_Appointment> = new EventEmitter();
    @Output() onModifyAppointment: EventEmitter<[M_Appointment, ("hour")]> = new EventEmitter();
    @Output() deliveryWarn: EventEmitter<M_Action> = new EventEmitter();
    onFinishDrag: Subject<boolean> = new Subject()
    draggingCard: M_Action | M_Appointment | undefined;
    core = projectConfiguration;
  
    constructor(@Inject(CoreCargaTallerComponent) public parent: CoreCargaTallerComponent, private snackS: SnackService, private routerS: RouterService, private dataGetter: WorkloadDataGetterService) { }
  
    ngOnInit(): void { }
  
    getTemporalLineClass(d: Date) {
      if (this.draggingCard && "images" in this.draggingCard) {
        let or: M_Action = this.draggingCard;
        if (or.delivery && or.schedule) {
          // Schedule, deelivery and the calendar day are the same
          if (or.delivery.isEquals(or.schedule) && d.isEquals(or.delivery)) {
            return "temporal-line-mixed"
          }
          // If the calendar day is the same as the or schedule
          else if (d.isEquals(or.schedule)) {
            return "temporal-line-schedule"
          }
          // If the calendar day is the same as the or delivery
          else if (d.isEquals(or.delivery)) {
            return "temporal-line-delivery"
          }
          //If the caledar day is between the delivery and the schedule
          else if (!d.isEquals(or.schedule)) {
            let minDate: Date = or.delivery < or.schedule ? or.delivery : or.schedule;
            let maxDate: Date = or.delivery > or.schedule ? or.delivery : or.schedule;
            if (d > minDate && d < maxDate) {
              return "temporal-line-schedule";
            }
          }
        }
      }
      return undefined;
    }
  
    /** Yes, yes, getting the user holidays on the user object parent. */
    getOrUserAssignedHolidays(date: Date) {
      if (this.draggingCard && "images" in this.draggingCard && this.draggingCard.assigned) {
        let draggingObj: M_Action = this.draggingCard;
        let user = this.parent.users.find(u => u.id == draggingObj?.assigned?.id)
        if (user) {
          return user.holidays.find(d => d.isEquals(date)) != undefined;
        }
      }
      return false;
    }
  
    isOrDelivery(date: Date) {
      if (this.draggingCard && "images" in this.draggingCard && this.draggingCard.delivery) {
        return this.draggingCard.delivery.isEquals(date);
      }
      return false;
    }
  
    isOrSchedule(date: Date) {
      if (this.draggingCard && "images" in this.draggingCard && this.draggingCard.schedule) {
        return this.draggingCard.schedule.isEquals(date);
      }
      return false;
    }
  
    isCompanyHolidays(date: Date) {
      if (!this.parent.company) { return false }
      return this.parent.company.holidays.find(h => h.isEquals(date)) != undefined
    }
  
  
    drop(event: CdkDragDrop<M_Action[] | M_Appointment[]>, to: Date) {
  
      this.draggingCard = undefined;
  
      if (to.getDay() == 0) {
        this.snackS.show(this.parent.workloadData.isOrView ?
          "No se puede mover una OR a un Domingo" :
          "No se puede mover una cita a un Domingo"
        )
        return;
      }
  
      if (!to.todayOrBigger()) {
        this.snackS.show(this.parent.workloadData.isOrView ?
          "No se puede mover una OR a un día anterior al de hoy" :
          "No se puede mover una cita a un día anterior al de hoy"
        )
        return;
      }
  
      /** Dragged or sorted ITEM */
      let item = event.item.data;
  
      /** Day change */
      if (event.previousContainer != event.container) {
        if (item) {
          /** OR */
          if (item instanceof M_Action) {
            if (item.status.invoiced) {
              this.snackS.show("No puedes mover de día una OR ya facturada")
            }
            /** In Sinicloud, closed ORs cannot be moved by day either*/
            else if (((item.status.pending || item.status.sinicloudFinished) && this.core.projectName == "sinicloud")) {
              if (item.status.pending) { this.snackS.show("No puedes mover de día una OR ya cerrada") }
              if (item.status.sinicloudFinished) { this.snackS.show("No puedes mover de día una OR ya finalizada") }
            }
            else {
              var updateSchedule = this.parent.workloadData.data.or.updateSchedule;
              if (updateSchedule) {
                this.dataGetter.updateSchedule(updateSchedule, item.id, to).then(res => {
                  transferArrayItem(
                    event.previousContainer.data as M_Action[],
                    event.container.data as M_Action[],
                    event.previousContainer.data.indexOf(event.item.data),
                    event.currentIndex,
                  );
                  if (item instanceof M_Action) {
                    item.schedule = to;
                    this.reorderCall(event as CdkDragDrop<M_Action[]>, false, false);
                    this.onDragFinished.emit(item);
                  }
  
  
                  let aux = item as M_Action;
                  if (aux.delivery && aux.schedule && (aux.delivery.isEquals(aux.schedule) || aux.delivery <= aux.schedule)) {
                    this.deliveryWarn.emit(item);
                  }
                })
              }
            }
          }
  
          /** APPOINTMENT */
          else {
            var updateAppoEndpoint = this.parent.workloadData.data.appointments.updateAppointment;
            if (updateAppoEndpoint) {
              /** Avoid the new date be 00:00:00 */
              to.setHours(item.appointment_date.getHours(), item.appointment_date.getMinutes(), item.appointment_date.getSeconds())
              this.dataGetter.updateAppointment(updateAppoEndpoint, item.id, to).then(res => {
                transferArrayItem(
                  event.previousContainer.data as M_Appointment[],
                  event.container.data as M_Appointment[],
                  event.previousContainer.data.indexOf(event.item.data),
                  event.currentIndex,
                );
                if (item instanceof M_Action) { } else {
                  item.day = to;
                  this.onDragFinished.emit(item);
                  /** Appointments need to sort by hour */
                }
              })
            }
          }
        }
        else {
          this.snackS.show("Algo salió mal al mover la OR de día")
        }
      }
      /** Sort */
      else {
        /** Only sort on OR items */
        if (event.previousIndex != event.currentIndex) {
          if (item && item instanceof M_Action) {
            this.actionReorder(event as CdkDragDrop<M_Action[]>); // Screen reorder
            this.reorderCall(event as CdkDragDrop<M_Action[]>, true, true);
          }
          else {
            this.snackS.show("Las citas se ordenan automáticamente según su hora")
          }
        }
      }
  
    }
  
    /** ONLY ON OR !! */
    reorderCall(event: CdkDragDrop<M_Action[]>, rollBackOnFail: boolean, showLoading: boolean) {
      var reorder = this.parent.workloadData.data.or.reorderAction;
      if (reorder) {
        let action_ids = event.container.data.map(a => a.id);
        this.dataGetter.reorderActions(reorder, action_ids, showLoading).then(res => {
          if (res == -1) {
            if (rollBackOnFail) { this.actionReorder(event, true); } //Rollback the card to its previous position
            this.snackS.show("El orden de la OR no se ha podido guardar");
          }
        })
      }
    }
  
    actionReorder(event: CdkDragDrop<M_Action[]>, reverse = false) {
      moveItemInArray(event.container.data, reverse ? event.currentIndex : event.previousIndex,
        reverse ? event.previousIndex : event.currentIndex);
    }
  
    onScroll(e: any) {
    }
  
    goCreateOnDay(d: Date) {
      if (this.parent.workloadData.isOrView) {
        var createOrView = this.parent.workloadData.data.or.views.createOr;
        if (createOrView) {
          this.routerS.goWithQueryParams(createOrView, { "startDate": d.toString() })
        }
      }
      else {
        var createAppoView = this.parent.workloadData.data.appointments.views.createAppointment;
        if (createAppoView) {
          this.routerS.goWithQueryParams(createAppoView, { "appointmentDay": d.toString() })
        }
      }
  
    }
  }
  