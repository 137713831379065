<p mat-dialog-title *ngIf="!data || (data && data.title)">{{data && data.title ? data.title : 'Firma digital'}}</p>
<div mat-dialog-content>
    <div class="firmaDigital">
        <span *ngIf="!data || (data && data.subtitle)">{{data && data.subtitle ? data.subtitle : 'Firme en el siguiente recuadro :'}}</span>
        <div id="firmaParent" #firmaParent class="signature-container ma">
            <canvas id="signature-canvas" (mouseup)="drawComplete()" (touchend)="drawComplete()"
                (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" (touchstart)="onMouseDown($event)"
                (touchmove)="onMouseMove($event)" #signPad>
            </canvas>
        </div>
    </div>
</div>
<mat-dialog-actions>
    <div class="df fdc">
        <div class="firmaDigitalBtns">
            <button (click)="clearSignature()" mat-flat-button class="c_p cbw mtb-20">
                {{data && data.repeatText ? data.repeatText : 'Repetir firma'}}
                <mat-icon class="dg">restart_alt</mat-icon>
            </button>
        </div>
        <div class="df mb20">
            <mat-checkbox #tyc *ngIf="showTermsLogic">
                He leído y acepto los <span class="cp c_a tdu" (click)="openTerminosyCondiciones($event, tyc)">términos
                    y condiciones</span>
            </mat-checkbox>
        </div>
        <div class="mat-dialog-actions-center"
            *ngIf="!data || data && (data.showActions == undefined || data.showActions)">
            <div class="df jcc">
                <button mat-button mat-dialog-close>Cancelar</button>
                <button [disabled]="getDisabled(tyc)" mat-flat-button color="primary" [mat-dialog-close]="dialogResult"
                    cdkFocusInitial>Aceptar</button>
            </div>
        </div>
    </div>

</mat-dialog-actions>