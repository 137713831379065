<app-page-structure #ps [pageTitle]="'Equipo'" class="users-table"
    [masterClass]="'user'" [autocompleteID]="'users'" searchLabel="Buscar usuario"
    listTitleText="Listado de usuarios"
    [data]="[]"
    [displayedHeader]=" ['Nombre',  'Email',    'Rol',      'Centro']" 
    [displayedColumns]="['name',    'email',    'role',     'center']"
    [specialText]="     [undefined, undefined,  roleName,   center]" 
    [specialClass]="    [undefined, undefined,  undefined,  undefined]"
    [circularLetter]="true"
    [showPagination]="false" [filter]="f"
    [createButton]="
        {
            text : 'Crear usuario' , 
            icon : 'groups', 
            view : v.createEditUser, 
            disabled : disableCircle,
            tooltip : circleTooltip,
            loading : !subS.status || loading,
            cssClass: 'onboarding-company-users-create'
        }"
    (onclickRow)="params.go(v.userdetails, $event.id)">
</app-page-structure>