import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { SessionService } from "../services/session.service";
import { LoggerService } from "../services/logger.service";
import { projectConfiguration } from "../app.module";

/**
 * In all those views that implement this guard, 
 * it is checked if they have a `session token` and if they have `permissions` according to their `role`
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private sessionS: SessionService, private router: Router, private loggerS: LoggerService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    /** If ther is no session, go to login */
    if (!this.sessionS.hasSession()) {
      this.loggerS.log("The user is not authenticated");
      this.router.navigate([projectConfiguration.loginView.path]);
      return false;
    }
    /**
    else if (!this.roleS.hasPemrissions(route.url.toString())) {
      let url = this.core.errorView.path;
      this.loggerS.log("The user can't acces this view with this role");
      this.router.navigate([url]);
      return false;
    } */
    return true;
  }

}
