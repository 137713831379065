export enum Calls {

    globalSearch =              "/api/search",

    login =                 "/api/auth/login",
    logout =                "/api/auth/logout",

    validateEmail =         "/api/auth/confirmEmail",
    getUsersById =          "/api/user/details",
    /** Images  */
    uploadImage =               "/api/images",
    deleteImage =               "/api/deleteImage",


    /** Delete */
    deleteId =                  "/api/deleteId",


    /** Dashboard */
    dashboard =                 "/api/dashboard",

    /**Logged user */
    profile =                   "/api/user",
    updatePass =                "/api/updatePass",
    updatePassword =            "/api/auth/updatePassword",
    resendConfirmation =        "/api/reSendConfirm",
    pickUpMail =                "/api/pickUpMail",
    requestPDF =                "/api/requestPDF",
    forgotPassword =            "/api/auth/password/forgot",
    getInvoice =                "/api/getInvoice",

    /*Appointments*/
    getAppointmentId =      "/api/appointment",
    appointment =           "/api/show/appointments",
    showFirstDay =          "/api/show/day",
    getBookedHours =        "/api/show/appointment/booked",
    getBookedHoursByCompanyId = "/api/show/appointment/booked/rider",
    getHolidays =               "/api/show/holidays",
    getHolidaysRider =          "/api/show/holidays/rider",
    showRider =                 "/api/show/rider",
    appointemtsRange =          "/api/date/range/appointments",
    getCitaByDay =              "/api/show/appointment/day",
    setHolidays =               "/api/holidays/worker",
    setHolidaysCompanies =      "/api/holidays/companies",
    getTaskAppointment =        "/api/tasksAppointment",
    createAppointmentClient =   "/api/create/appointment",
    createAppointmentClientSide ="/api/create/rider/appointment",
    changeAppointmentDate =     "/api/change/date/appointment",
    editAppointmentClient =     "/api/show/appointment/update/",
    updateAppointmentClient =     "/api/appointment/update",
    configCompanyAppointment =  "/api/config/appointment/companies",
    updateConfigAppointment =   "/api/update/settings/appointment",
    getCompanyInfoClientSide =  "/api/config/appointmentById",
    getAppointmentsSide =       "/api/show/appointment/day",

    /*/VERSION DOS HOLIDAYS CHECKHOLIDAY*/
    checkHolidays =             "/api/check/holiday",

    /*Clients*/
    clients =               "/api/clients",
    c_clients =             "/api/c_clients",
    providers =             "/api/providers",
    clientAdmincenters =    "/api/clientAdmincenters",
    clientsminify =         "/api/clients/1",
    createUpdateClient =    "/api/createUpdateClient",
    getClientById =         "/api/client",
    addExtraAddress =       "/api/addExtraAddress",


    /** Check plate-dni */
    checkPlateDni =         "/api/checkExist",

    /*Vehicles*/
    clientsAdvances =       "/api/clientsAdvances",
    linkVehicle =           "/api/vehicleClient",
    sellVehicleClient =     "/api/sellVehicleClient",
    sellArticles =          "/api/storeBuyTransacArticles",
    createUpdateVehicle =   "/api/createUpdateVehicle",
    vehicles =              "/api/vehicles",
    vehicleType =           "/api/vehiclesType",
    vehiclesminify =        "/api/vehicles/1",
    vehiclesminify2 =       "/api/vehicles/2",
    getVehicletById =       "/api/vehicle",
    clientsForVehicles =    "/api/clientsForVehicles",

    /*Products*/
    products =                      "/api/products",
    productsOnlyProviders =         "/api/productsForOrder",
    storehouses =                   "/api/stores",
    checkDuplicatedStoreHouseDocu = "/api/getDNoteProvNum",
    storehouseCUD =                 "/api/storesInsertUpdateDelete",
    createProduct =                 "/api/createUpdateProduct",
    editProductProviderDiscount =   "/api/editProductProviderDiscount",
    getProductById =                "/api/product",
    editStock =                     "/api/editStock",
    productProvider =               "/api/productProviderInsertUpdateDelete",
    alternativeProducts =           "/api/alternativesInsertUpdateDelete",
    faults =                        "/api/showFaults",
    manualFault =                   "/api/insertManualFault",
    deleteFault =                   "/api/deleteManualFault",
    stockMove =                     "/api/stockMove",
    getProductItemQuants =          "/api/getProductItemQuants",
    getLinesByDiscountGroup =       "/api/getGroupDiscount",
    addLinesToDiscountGroup =       "/api/addLinesToDiscountGroup",
    discountGroups =                "/api/discount_group",


    /** Facturas */
    bills =                 "/api/invoices",
    bill =                  "/api/invoice",
    createBill =            "/api/createInvoice",
    saveInvoice =           "/api/InvoiceSave",
    delProdInvoice =        "/api/delProdInvoice",
    templatePreview =       "/getPreviews/",
    payedBill =             "/api/payedInvoice",
    confirmInvoice =        "/api/confirmInvoice",
    discardInvoice =        "/api/discardInvoice",
    downloadInvoices =      "/api/downloadInvoices",
    hasDraft =              "/api/hasDraft",
    exportInvoices =        "/exportinvoices",
    lastInvoiceDate =       "/api/lastInvoice",
    payedInvoice =          "/api/payedInvoice",

    /** Empresa */
    company =               "/api/company",
    createCompany =         "/api/auth/createCompany",
    updateCompany =         "/api/updateCompany",
    deleteCompany =         "/api/deleteCompany",
    welcomeCompany =        "/api/updateCompanyNamePlaces",
    emailPromo =            "/api/sendPromo",
    saveColors =            "/api/updateColors",
    saveFooters =           "/api/updateFooters",
    saveCompanyFiles =      "/api/saveTemplateFiles",
    smtp =                  "/api/smtp",
    testEmail =             "/api/testEmail",
    presentationMail =      "/api/presentationMail",
    pdfExample =            "/api/pdfExample",
    getLocationByZip =      "/api/zip/",
    getFieldsByCCAA =       "/api/CTAddFields_Show/",
    buyTransac =            "/api/storeBuyTransac",
    /**RESERVER */
    createReserve =         "/api/reserve/products",
    getReservation =         "/api/reservations",
    /** Usuarios */
    users  =                "/api/users",
    createUser =            "/api/createUser",
    updateUser =            "/api/updateUser",
    updateUserName =        "/api/updateUserName",

    /** OR */
    actionTypes =           "/api/actionTypes",
    actions =               "/api/actions",
    createOR =              "/api/createOR",
    addClientVehicleAction= "/api/addClientVehicleAction",
    getAction =             "/api/getAction",
    addEditGroup =          "/api/addEditGroup",
    addTask =               "/api/addTask",
    editDelivery =          "/api/editDelivery",
    assignAction =          "/api/assignAction",
    assignClient =          "/api/assignClient ",
    updateSchedule =        "/api/updateSchedule",
    addEditTasks =          "/api/addEditTasks",
    schedules =             "/api/schedules",
    rmGroup =               "/api/rmGroup",
    rmTask =                "/api/rmTask",
    rmAction =              "/api/rmAction",
    invoiceOR =             "/api/invoiceOR",
    sortTask =              "/api/sortTask",
    changeTask =            "/api/changeTask",
    editKm =                "/api/editKm",
    editNotes =             "/api/editNotes",
    editTitle =             "/api/editTitle",
    closeGroup =            "/api/closeGroup",
    openGroup =             "/api/openGroup",
    reorderActions =        "/api/reorderActions",
    editAction =            "/api/editAction",
    stateInvoice =          "/api/stateInvoice",
    showTypePayment =       "/api/show/TypePayment",
    CreateLastPayment =     "/api/create/movementInvoice",
    CreateAdvancesClient =     "/api/create/advancesClient",
    DeleteAdvancesClient =     "/api/delete/advancesClient",
    EditAdvancesClient =     "/api/update/advancesClient",
    DeleteMovementInvoice = "/api/delete/movementInvoice",
    DeleteTypePayment =     "/api/delete/TypePayment",

    /** Presupuesto */
    createBudget =          "/api/createBudget",
    closeBudget =           "/api/closeBudget",
    copyAction =            "/api/copyAction",

    /** Recambios budget */
    createRecambiosBudget = "/api/createRecambiosBudget",

    /** Data import */
    importStorage =         "/api/importStorage",
    importClients =         "/api/importClients",
    importTarifas =         "/api/importTarifas",

    /** Notifications */
    getNotifications =          "/api/show/notification",
    updateNotifications =       "/api/show/update/notification",
    readAllNotifications =      "/api/show/update/all/notification",

    /** Cloud tickets */
    cloudtickets =              "/api/cloudtickets",
    createTicket =              "/api/createTicket",
    addcomment =                "/api/addcomment",

    /** Frontend error report */
    reportError =           "/api/insertLogs",

    /**Orders */
    getOrder =              "/api/orderById",
    getOrders =             "/api/orders",
    createOrder =           "/api/createOrder",
    editOrder =             "/api/updateOrder",
    saveGroupDetails =      "/api/saveGroupDetails",
    deleteOrderProduct =    "/api/deleteOrderProduct",
    changeSended =          "/api/orderUpdate",

    /** Reservations */
    manualReservation =     "/api/reserveSingle",

    /** Abono */
    abono =                 "/api/createPayOut",

    /** Albaranes */
    albaranes =             "/api/deliveryNotes",
    albaranById =           "/api/getDeliveryNote",
    createAlbaran =         "/api/createDeliveryNote",
    saveAlbaranInfo=        "/api/addEditDNoteShipment",
    saveAlbaranProducts =   "/api/addEditDNoteProducts",
    removeAlbaranProduct =  "/api/removeDNoteProduct",
    changeAlbaranStatus =   "/api/changeDeliveryNoteStatus",
    changeAlbaranType =     "/api/changeDeliveryNoteType",
    deleteAlbaran =         "/api/deleteDNote",
    invoiceAlbaran =        "/api/invoiceDNote",

    /** Templates */
    invoice =               "/getInvoice/",
    eInvoice =              "/downloadInvoiceE/",
    budgetPDF =             "/budgetPDF/",
    ra =                    "/getOrPDF/",
    createAdvancePdf =      "/createAdvancePdf/",
    ORalbaranPDF =          "/getAlbaranPdf/",
    albaranPDF =            "/getDNotePdf/",
    storehousePDF =         "/getEntryStorePDF/",
    comercialBudgetPDF =    "/budgetComercialPDF/",

    /** No id Objects URL */
    previewRA =            "/api/preview/RA",
    previewCV =            "/api/getBuySaleVehiclePDF",
    previewFC =            "/api/getBuyInvoiceVehiclePDF",

    /** Brand and models */
    brand =                    "/api/brands",
    brand_models =             "/api/brand_models",

    /** Paycomet */
    payComet =             "/api/payComet",
    payCometInvoices =     "/api/checkInvoices",
    generatePaycometUrl =  "/api/CreateSubscriptionUrl",
    checkSubscription  =    "/api/checkSubscription",
    paymentByCompany  =     "/api/paymentByCompany",

    /** Templates */
    getFieldsFromDoc  =            "/api/CTAddFields_Show_transac/",
    saveTemplateFields =           "/api/CTAddFields_Insert_Transac",
    saveTemplateImage =            "/api/CTAddFields_Images_Transac",

    /** Flotas */
    getForMassiveInvoiceOR =        "/api/getForMassiveInvoiceOR",
    getForMassiveInvoiceDNote =     "/api/getForMassiveInvoiceDNote",
    invoiceMassiveOR =              "/api/invoiceMassiveOR",
    invoiceMassiveDNote =           "/api/invoiceMassiveDNote",

    /** Fichje */
    currentlyWorking =              "/api/dashboard/show/tasks/time",
    startTime =                     "/api/action/time/start",
    endTime =                       "/api/action/time/stop",
    addManualTime =                 "/api/custom/time/action",
    deleteTimeHistory =             "/api/delete/action/time",

    /** Sotehouse entry */
    getEntryIntoStore =             "/api/getEntryIntoStore",
    entryIntoStore  =               "/api/entryIntoStore",


    /** VN and VO */
    vehiclesC =                     "/api/vehiclesC",
    vehicleC =                      "/api/vehicleC",
    createVNVO =                    "/api/addStock",
    sellVehicle =                   "/api/sellVehicle",
    getAgents =                     "/api/getAgents",
    updateModel =                   "/api/updateModel",
    getModel =                      "/api/getModel",
    createUpdateOption =            "/api/createUpdateOption",
    getOptionsByModel =             "/api/getOptionsByModel",
    createUpdateConcept =           "/api/createUpdateConcept",
    getConcepts =                   "/api/getConcepts",
    getConceptById =                "/api/getConceptById",
    addStock =                      "/api/addStock",
    createComercialBudget =         "/api/createBudgetComercial",
    getComercialBudget =            "/api/getBudgetComercial",
    getComercialBudgets =           "/api/getBudgetsComercial",
    closeComercialBudget =          "/api/closeBudgetC",
    deleteConmercialBudget =        "/api/deleteBudgetC",
    getPurchases =                  "/api/getAllBuyTransac",
    getPurchaseById =               "/api/buyTransacById",
    getStockBrandAndModel =         "/api/getVehicleInfoStock",

    /** Eina data  */
    einaData =                      "/api/eina/data",


    /** Modules  */
    recanviosClient =                "/api/reservation/client/product",
    modules =                        "/api/modules",
    toggleModule =                   "/api/AssignModuleCompany",

    dateTest =                      "/api/dateTest"
}
