<!-- WEEK APPOITMENT -->
<div (click)="onGoAppointment.emit()" class="week-appo-card">
    <mat-chip class="small-chip c_w">{{item.title_appointment}}</mat-chip>

    <div class="mt10">
        <p class="fsm fw600 ttu overflow-text">{{item.vehicleName()}}</p>
        <p class="fss fw600 overflow-text">{{item.clientName()}}</p>
    </div>


    <div class="middle-contents mt10">
        <p class="c_t2 max2lines fsxs">
            <span *ngFor="let r of item.reparations; let last = last">
                {{r}}{{!last ? ', ' : ''}}
            </span>
        </p>
    </div>

    <div class="df aic">
        <div class="edit-hour-container" prevent [ngClass]="{'editable' : item.status == 0}"
            (click)="item.status == 0 ? onModifyAppointment.emit([item,'hour']) : {}">
            <div class="edit-icon" *ngIf="item?.status == 0">
                <mat-icon class="edit-icon-content c_w" [filled]="false">edit</mat-icon>
            </div>
            <span class="appointment-hour">
                {{item.appointment_date.hourFormat()}}
            </span>
        </div>
        <div class="df mla">
            <lib-appointment-status-label [circularStatus]="true" style="transform: scale(0.8); margin-left: -5px;"
                [status]="item.status">
            </lib-appointment-status-label>
        </div>
    </div>
</div>