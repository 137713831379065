<div class="advanced-details-container invoices-details">

    <div class="back">
        <app-go-back [v]="v.invoices" [text]="'Listado de facturas'"></app-go-back>
    </div>

    <div class="details">

        <app-card [contentLoaded]="invoice != undefined" class="bc_b1">
            <ng-container *ngIf="invoice">


                <app-advanced-details>

                    <app-advanced-title>
                        <div class="df aic gap5">
                            {{invoice.company_scope_id}}
                            <app-invoice-status [showText]="false" [status]="invoice.state"
                                [obj]="invoice"></app-invoice-status>
                        </div>
                    </app-advanced-title>

                    <app-advanced-subtitle>
                        {{invoice.created_at?.dayMonthYearFormat()}}
                    </app-advanced-subtitle>

                    <app-advanced-heading>
                        <div class="df aic">
                            <span style="margin-right: 5px;"
                                *ngIf="invoice.invoice_type_or_type[0]?.name">{{invoice.invoice_type_or_type[0]?.name}}</span>
                            <app-or-type-label *ngIf="orType" [wrapOn]="1" [showText]="false"
                                [tipo]="[orType]"></app-or-type-label>
                        </div>
                    </app-advanced-heading>

                    <app-advanced-main-actions>
                        <!-- Not payed invoicde-->
                        <div class="df fdc">
                            <button *ngIf="!invoice.state.isPayed" mat-flat-button (click)="openPayCheckControl()"
                                color="primary">
                                <mat-icon>price_check</mat-icon>
                                <div>{{ invoice.type == 5 ? 'Pagar' : 'Cobrar' }}</div>
                            </button>
                            <p *ngIf="invoice.abono_of" class="nmb tac" style="margin-top: 5px;">
                                <span class="fsi">Abono generado a partir de la factura</span>
                                <span class="ml5 c_b c_p underline_hover fw600"
                                    (click)="previsualize(invoice.abono_of)">Nº{{invoice.id}}</span>
                            </p>

                        </div>

                    </app-advanced-main-actions>

                    <app-advanced-menu-actions>

                        <button *ngIf="!invoice.aboned && !invoice.isAbono" mat-menu-item [go]="v.abono"
                            [param]="{invoice : invoice.id}" class="w100" [disabled]=" invoice.total ==0">
                            <mat-icon>currency_exchange</mat-icon>
                            Generar abono
                        </button>

                        <button *ngIf="invoice.aboned" mat-menu-item [disabled]="true" class="w100">
                            <mat-icon class="c_p">check</mat-icon>
                            Abono generado
                        </button>

                        <button mat-menu-item (click)="previsualize(invoice)" color="primary">
                            <mat-icon>find_in_page</mat-icon>
                            Vista previa
                        </button>

                        <app-layout-send-email
                            *ngIf="invoice && invoice.client && !invoice.client.deleted && invoice.type != 5 && !invoice.isInternoInvoice"
                            [butontype]="'mat-menu-item'" [temaplte]="'invoice'"
                            [disabled]="invoice.client.email == undefined"
                            [endpoint]="reSendInvoice.bind(this)"></app-layout-send-email>

                        <app-layout-send-email
                            *ngIf="invoice && invoice.client && !invoice.client.deleted && invoice.type == 5 && !invoice.isInternoInvoice"
                            [butontype]="'mat-menu-item'" [temaplte]="'abono'"
                            [disabled]="invoice.client.email == undefined"
                            [endpoint]="reSendInvoice.bind(this)"></app-layout-send-email>
                    </app-advanced-menu-actions>

                </app-advanced-details>


                <app-advanced-details-footer [showTitle]="false">
                    <app-footer-right>
                        <app-contact-client-rectangle *ngIf="invoice && invoice.client  && !invoice.isInternoInvoice"
                            [client]="invoice.client"></app-contact-client-rectangle>
                    </app-footer-right>

                </app-advanced-details-footer>

            </ng-container>
        </app-card>
    </div>

    <div class="pay">
        <app-card [contentLoaded]="invoice != undefined">
            <ng-container *ngIf="invoice">
                <!-- Cobros section-->
                <app-card-subtitle [first]="true">Cobros</app-card-subtitle>
                <p class="c_t2" *ngIf="!invoice.last_movement.length">
                    Sin cobros
                </p>
                <app-invoice-movements [movements]="invoice.last_movement" [isTotalPay]="invoice.isTotalPay"
                    (onDeleteMovement)="deleteLastPayment($event)" [showLastCircle]="false">
                </app-invoice-movements>
                <!-- <app-invoice-payement-form *ngIf="invoice.type !=5" [invoice]="invoice" [resetImport]="false"
                    (onDeleteTypePayment)="deleteTypePayment($event)"></app-invoice-payement-form> -->
            </ng-container>
        </app-card>
    </div>


    <div class="summary">
        <app-card [contentLoaded]="invoice != undefined">
            <ng-container *ngIf="invoice">
                <app-card-subtitle [first]="true">Resumen</app-card-subtitle>
                <p *ngIf="!invoice.state.isPayed">
                    Pendiente <span class="liquidation" money [val]="invoice.pending_import"></span>
                </p>
            </ng-container>
        </app-card>
    </div>

</div>