import { Country } from "@angular-material-extensions/select-country";
import { getCountry } from "src/app/utils/functions";
import { M_Vehicle } from "./M_Vehicle";



export class M_StockVehicles {

    quantity: number;
    quant_reserved: number;
    quant_available: number;
    available_vehicles: M_Vehicle [] = [];

    constructor(d: any) {
        this.quantity = d.quantity;
        this.quant_reserved = d.quant_reserved;
        this.quant_available = d.quant_available;

        if (d.available_vehicles) {
            for (let i = 0; i < d.available_vehicles.length; i++) {
                this.available_vehicles.push(new M_Vehicle(d.available_vehicles[i]))
            }
        }
        console.log('que paxa',this);
    }

   

}
