import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { Filter, FilterOption, TagFilter } from 'src/app/custom-classes/Filter';
import { getWarnColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { ApiService } from 'src/app/services/Api/api.service';

export enum VehiclesPageFiltersEnum {
  VEHICLE_TYPE = 1,
  OWNER_TYPE = 2,
}

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Vehicle>;
  @Input() newTab: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  filters : Filter[] = [
    new TagFilter("Vehículo", undefined, new FilterOption("Moto", "two_wheeler "), new FilterOption("Coche", "directions_car_filled")).setId(VehiclesPageFiltersEnum.VEHICLE_TYPE),
    new TagFilter("Tipo de propietario", undefined, new FilterOption("Persona", "person"), new FilterOption("Compañía", "business"), new FilterOption("Proveedor", "local_shipping")).setId(VehiclesPageFiltersEnum.OWNER_TYPE),
  ]
  v = ViewPath;
  vehicles: M_Vehicle[] = [];
  f = filter;
  warn = getWarnColor
  constructor(apiS: ApiService, public routerS: RouterService, public params: ParamsService, public subS: SubscriptionService) {
    apiS.getVehicles().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == VehiclesPageFiltersEnum.VEHICLE_TYPE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  vehicleType(v: M_Vehicle) {
    return v.typeName;
  }

  preIcon(v: M_Vehicle) {
    return v.icon;
  }

  getLicenseDate(v: M_Vehicle) {
    return v.license_date?.noHourFormat();
  }

  getOwner(v: M_Vehicle) {
    return v.client?.getName();
  }
  
}

