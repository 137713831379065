import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CompanyTabComponent } from './company-tab/company-tab.component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/Api/api.service';
import { HolidaysCompanyComponent } from './holidays-company/holidays-company.component';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { MemoryParamsService } from 'src/app/services/memory-params.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent extends ParameterStateComponent implements OnInit {
  primary = getPrimaryColor;
  warn = getWarnColor;
  loaded = false;
  selectedTab = 0;
  @ViewChild(CompanyTabComponent) companyTab!: CompanyTabComponent;
  @ViewChild(HolidaysCompanyComponent) holidaysTab!: HolidaysCompanyComponent;


  constructor(routerS: RouterService, route: ActivatedRoute, private mps: MemoryParamsService, private apiS: ApiService) {
    super(routerS, route, ['tab']);
  }

  /** If the 'tab' param exist, select corresponding tab  */
  override onParam(param: string, value: string): void {
    if (param == 'tab' && value.getNumber()) {
      this.selectedTab = value.getNumber();
    }
  }

  /** Initialzie all tabs */
  ngOnInit(): void {
    this.apiS.company().then(res => {
      //GENERAL TAB
      this.companyTab.companyForm.initTab(res); 
      //DOCUMENTATION TAB
      this.companyTab.documentationTab.initTab({
        templates : res.template_fields,
        footers : res.getAllFootersByOrder()
      })
      //SMTP TAB
      this.companyTab.smtpTab.initTab(res.smtp);
      this.loaded = true;

      //(The users tab initializes itself)

    })
  }

  /** Necessary to update the selected tab */
  tabChanged(event: MatTabChangeEvent) {
    this.selectedTab = event.index;
    this.mps.add(['tab', event.index.toString()]);
    if (event.index == 2) {
      this.companyTab.documentationTab.tabGroup.realignInkBar();
    }
  }
}
