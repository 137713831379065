import { Component, ViewChild } from '@angular/core';
import { calendarEnabled } from 'src/app/constants/constants';
import { HolidaysCalendarComponent } from 'src/app/components/holidays-calendar/holidays-calendar.component';
import { M_Center } from 'src/app/models/M_Center';

@Component({
  selector: 'app-holidays-company',
  templateUrl: './holidays-company.component.html',
  styleUrls: ['./holidays-company.component.css']
})
export class HolidaysCompanyComponent {
  enabledCalender = calendarEnabled;
  @ViewChild(HolidaysCalendarComponent) holidays!: HolidaysCalendarComponent;

  constructor() { }
  
  initCenter(center: M_Center) {
    this.holidays.initComponent({ companyH: center.holidays, userH: [] });
  }
}
