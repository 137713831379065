import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateClientComponent } from 'src/app/views/create-client/create-client.component';
import { M_Contact } from '../models/M_Contact';
import { ClassSearcherComponent } from '../components/class-searcher/class-searcher.component';
import { ContactEnum } from '../enums/ContactEnum';
import { M_Vehicle } from '../models/M_Vehicle';
import { CreateVehicleComponent } from '../views/create-vehicle/create-vehicle.component';
import { M_Concept } from '../models/M_Concept';
import { CreateConceptComponent } from '../components/create-concept/create-concept.component';
import { CreateProductComponent } from '../views/create-product/create-product.component';
import { M_Product } from '../models/Products/M_Product';

@Injectable({
  providedIn: 'root'
})
export class CreateMasterInDialogService {

  constructor(private d: MatDialog) { }

  createClient(searcher: ClassSearcherComponent<M_Contact> | undefined, forceType?: ContactEnum) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateClientComponent, { data: forceType, maxWidth: 800, autoFocus: false })
    dialogRef.afterClosed().subscribe(res => {
      if (res instanceof M_Contact) {
        searcher?.addAndSelectNewOption(res);
      }
    })
    return dialogRef;
  }

  createVehicle(searcher: ClassSearcherComponent<M_Vehicle> | undefined) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateVehicleComponent, { maxWidth: 800, autoFocus: false })
    dialogRef.afterClosed().subscribe(res => {
      if (res instanceof M_Vehicle) {
        searcher.addAndSelectNewOption(res);
      }
    })
    return dialogRef;
  }

  createConcept(searcher: ClassSearcherComponent<M_Concept> | undefined) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateConceptComponent)
    dialogRef.afterClosed().subscribe(res => {
      if (res instanceof M_Concept) {
        searcher.addAndSelectNewOption(res);
      }
    })
    return dialogRef;
  }

  createProduct(searcher: ClassSearcherComponent<M_Product> | undefined) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateProductComponent);
    dialogRef.afterClosed().subscribe(res => {
      if (res instanceof M_Product) {
        searcher.addAndSelectNewOption(res);
      }
    });
    return dialogRef;
  }
}
