import { EnrtryTypeEnum, entranceTypeIcon, entranceTypeName } from "../enums/EnrtryTypeEnum";
import { M_Order } from "./M_Order";
import { M_Product } from "./Products/M_Product";
import { M_Contact } from "./M_Contact";
import { mainPrice } from "./Products/M_BaseProduct";
import { ISearchFiltrable } from "../interfaces/ISearchFiltrable";
import { getArrayOf } from "../utils/FunctionUtils";
import { match } from "../services/search.service";

export class M_StoreHouseEntry implements ISearchFiltrable {

    id: number | undefined;
    number: number | undefined; //EA-000001
    provider_id: number | undefined;
    provider: M_Contact | undefined;
    entranceType: EnrtryTypeEnum = EnrtryTypeEnum.PMP;
    dnote_prov_num: string;
    dnote_prov_date: Date;
    entry_num: number | undefined;
    created_at: Date | undefined;
    updated_at: Date | undefined;
    products: M_Product[] = [];
    products_file: M_Product[] = [];
    orders: M_Order[] = [];
    token: string = "";
    total : number;

    constructor(d: any) {
        if (d.id) { this.id = d.id; }
        if (d.provider) { this.provider = new M_Contact(d.provider[0]); }
        if (d.number) { this.number = d.number; }
        if (d.entry_num) { this.entry_num = d.entry_num; }
        this.entranceType = d.type ? d.type : EnrtryTypeEnum.PMP;
        if (d.created_at) { this.created_at = new Date(d.created_at); }
        if (d.updated_at) { this.updated_at = new Date(d.updated_at); }
        if (d.products) { this.products = getArrayOf(M_Product, d.products); }
        this.total = d.total;
        this.dnote_prov_num = d.dnote_prov_num;
        this.dnote_prov_date = new Date();
    }

    get canEnter() {
        if (this.products_file.length != 0 && this.dnote_prov_num == "") { return false; }
        return this.provider_id && (this.products.length || this.products_file.length || (this.orders.length && this.orders.some(order => order.products.length)));
    }

    get prodLen() {
        return this.products.length;
    }


    get entranceTypeName() {
        return entranceTypeName(this.entranceType);
    }

    get entranceTypeIcon() {
        return entranceTypeIcon(this.entranceType);
    }

    getProductMainPrice(p: M_Product): mainPrice {
        return (p.enter_by_cost && this.entranceType == EnrtryTypeEnum.PMP) ||
            this.entranceType == EnrtryTypeEnum.COST ||
            this.entranceType == EnrtryTypeEnum.IMPORT ? 'buy_price' : "pvp_t"
    }

    get screenTotal() {
        return this.totalProds + this.totalProdsFile + this.totalOrders;
    }

    get totalProds() {
        return this.products.reduce((sum, p) => sum + p.getTotal(this.getProductMainPrice(p)), 0);;
    }

    get totalProdsFile() {
        return this.products_file.reduce((sum, p) => sum + p.getTotal(this.getProductMainPrice(p)), 0);
    }

    get totalOrders() {
        return this.orders.reduce((sum, order) => sum + order.geTotal(this), 0);
    }

    addProduct(p: M_Product, array: "products" | "products_file") {
        let arr = array == "products" ? this.products : this.products_file;
        if (!this.containsProduct(p, array)) {
            if (array == "products") {
                this.setProviderDiscount(p);
            }
            arr.push(p);
        }
    }

    addOrder(o: M_Order) {
        console.log(o)
        if (!this.containsOrder(o)) {
            this.setProviderDiscount(o);
            this.orders.push(o);
        }
    }

    removeProduct(p: M_Product, array: "products" | "products_file") {
        let arr = array == "products" ? this.products : this.products_file;
        arr.removeElement(p);
    }
    removeOrder(o: M_Order) { this.orders.removeElement(o); }
    containsOrder(o: M_Order) { return this.orders.some(order_ => order_.id == o.id) }
    containsProduct(p: M_Product, array: "products" | "products_file") {
        if (array == "products") {
            return this.products.some(prod_ => prod_.product_id == p.product_id)
        }
        else { return false; }
    }

    /** Apply provider discount. TO DO : https://siniwin.atlassian.net/browse/EINA-1289 */
    setProviderDiscount(item: M_Product | M_Order) {
        if (this.provider == undefined) { return; }
        /** Product */
        if (item instanceof M_Product) {
            item.discount = this.provider.getDiscountByProduct(item);
        }
        /** Order */
        else {
            item.products.forEach(p => {
                if (this.provider == undefined) { return; }
                p.discount = this.provider.getDiscountByProduct(p, item.type);
            })
        }
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.number?.toString(), this.dnote_prov_num?.toString());
    }

}