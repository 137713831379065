import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/Api/api.service';
import { ComentariosComponent } from 'src/app/components/comentarios/comentarios.component';
import { HourPickerComponent } from 'src/app/components/hour-picker/hour-picker.component';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { CommentManager } from 'src/app/custom-classes/Comentarios';
import { getAccentColor } from 'src/app/utils/FunctionUtils';
import { M_AppointmentConfig } from 'src/app/models/M_AppointmentConfig';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RouterService } from 'src/app/services/router.service';
import { environment } from 'src/environments/environment';

export interface I_AppointmentClientSide{
      company_id : number,
      license: string,
      name_client:string,
      phone: number,
      email: string,
      date_appointment: Date
      taks: string[];
}

@Component({
  selector: 'app-create-appointment-client-side',
  templateUrl: './create-appointment-client-side.component.html',
  styleUrls: ['./create-appointment-client-side.component.css']
})
export class CreateAppointmentClientSideComponent extends ParameterStateComponent implements OnInit {

  accent = getAccentColor;

  @ViewChild(HourPickerComponent) hourpciker?: HourPickerComponent;
  @ViewChild(ComentariosComponent) comments?: ComentariosComponent;

  created = false;
  checkingClient = false;
  invalidToken = false;
  loaded = false;

  cm = CommentManager;
  
  clientForm: UntypedFormGroup;
  dayHourForm: UntypedFormGroup;
  reparations: UntypedFormGroup;

  config: M_AppointmentConfig | undefined;

  constructor(route: ActivatedRoute, routerS: RouterService, private formBuilder: UntypedFormBuilder, public responsiveS: ResponsiveService, private apiS: ApiService) {
    super(routerS, route, ['company'])

    this.clientForm = this.formBuilder.group({
      license: [environment.local ? '9090LNK' : '', Validators.required],
      name_client: [environment.local ? 'Manelito' : '', Validators.required],
      phone: [environment.local ? '600000000' : '', Validators.required],
      email: [environment.local ? 'desarrollo2@sinigual.com' : '', Validators.required],
    });

    this.reparations = this.formBuilder.group({
      tasks: ['', Validators.required],
    });

    this.dayHourForm = this.formBuilder.group({
      day: ['', Validators.required],
      hour: ['', Validators.required],
    })
  }

  ngOnInit() {
    document.body.classList.add("create-appointment-client-side");
  }

  override noParams(): void {
    this.loaded = true;
    this.invalidToken = true;
  }

  override onParam(param: string, value: string): void {
    if (param == "company") {
      this.apiS.getCompanyInfoAppointmentClientSide(value).then(res => {
        this.config = res;
        this.loaded = true;
        this.hourpciker!.setFirstDay(new Date().plusDays(res.range_of_days), this.config.company_id);
        this.hourpciker?.setUpHoliDays(this.config.company_id);
        this.defineCompanyColors(this.config);
      })
    }
  }

  defineCompanyColors(config: M_AppointmentConfig) {
    if (this.config) {
      document.documentElement.style.setProperty('--companyP', this.config.primary_color);
      document.documentElement.style.setProperty('--companyS', this.config.secondary_color);
    }
  }

  createAppointment() {
    let appointment : I_AppointmentClientSide = {
      company_id : this.config!.company_id,
      license: this.clientForm.get('license')?.value,
      name_client: this.clientForm.get('name_client')?.value,
      phone: this.clientForm.get('phone')?.value,
      email: this.clientForm.get('email')?.value,
      date_appointment: this.hourpciker?.getDate()!,
      taks : this.comments!.getCommentsAsArray()
    }
    this.apiS.createAppointmentClientSide(appointment).then(res => {
      this.created = true;
    })
  }

  ngOnDestroy() {
    document.body.classList.remove("create-appointment-client-side")
  }

}
