import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { ComercialVehicleType } from 'src/app/enums/ComercialVehicleType';
import { M_Concept } from 'src/app/models/M_Concept';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { M_Vehicle } from 'src/app/models/M_Vehicle';
import { CanInvoiceService } from 'src/app/services/can-invoice.service';
import { PreviewService } from 'src/app/services/preview.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MASTER_CLIENT } from 'src/app/constants/masters';
import { M_Contact } from 'src/app/models/M_Contact';
import { CreateInvoiceDialogComponent, invoiceDialogData } from '../create-invoice/create-invoice-dialog/create-invoice-dialog.component';
import { M_ComercialBudget } from 'src/app/models/M_ComercialBudget';
import { RegistrationTaxEnum, registrationText } from 'src/app/enums/RegistrationTaxEnum';
import { M_Product } from 'src/app/models/Products/M_Product';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { M_GroupTask } from 'src/app/models/M_GroupTask';
import { IConceptLineTableComponent } from 'src/app/interfaces/IConceptLineTableComponent';
import { ConceptsLineTableComponent } from 'src/app/components/concepts-line-table/concepts-line-table.component';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { FormService } from 'src/app/services/form.service';
import { RouterService } from 'src/app/services/router.service';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { ParamsService } from 'src/app/services/params.service';


export type SellVehicleDialogData = {
  budget: M_ComercialBudget;
}

@Component({
  selector: 'app-sell-vehicle',
  templateUrl: './sell-vehicle.component.html',
  styleUrls: ['./sell-vehicle.component.css']
})

export class SellVehicleComponent extends ParameterStateComponent implements IProductLineTableComponent, IConceptLineTableComponent {
  loaded = false;
  ve: M_Vehicle | undefined;
  CVT = ComercialVehicleType;
  invoice: M_Invoice | undefined;
  v = ViewPath;
  isDialog: boolean = false;
  client = MASTER_CLIENT;
  select_client: M_Contact | undefined;
  bill: M_Invoice | undefined;
  recoveredDraft = false;
  group!: M_GroupTask;
  public form: UntypedFormGroup;
  RTE = RegistrationTaxEnum;
  registrationText = registrationText;
  classearcherChanged = false;

  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;
  @ViewChild("clientInvoice") clientInvoice?: ClassSearcherComponent<M_Contact>;
  @ViewChild("csearcher") csearcherC?: ClassSearcherComponent<M_Contact>;

  get blocksLine(): boolean { return true; }

  constructor(public d: MatDialog, private formBuilder: UntypedFormBuilder, private apiS: ApiService, private previewS: PreviewService,
    routerS: RouterService, route: ActivatedRoute, public fs: FormService, public canInvoiceS: CanInvoiceService,
    private chdRef: ChangeDetectorRef, private paramsS: ParamsService,
    @Optional() public dialogRef: MatDialogRef<SellVehicleComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SellVehicleDialogData | null) {

    super(routerS, route, ["vehicle"]);

    this.form = this.formBuilder.group({
      budget_id: [],
      vehicle_id: ['', Validators.required],
      client_id: ['', Validators.required],
      price: ['', Validators.required],
      registration_tax: [''],
      date: [''],
    });

    if (this.data?.budget) {
      this.isDialog = true;
      this.setUpForm(this.data.budget);
    }
    console.log("oke", this.data?.budget)
  }

  override onParam(_k: string, v: any) {
    this.apiS.vnvo.getVehicleById(v).then(res => {
      if (res) {
        this.setUpForm(res);
      }
    })
  }

  override noParams(): void {
    this.loaded = true;
  }

  addConcept(c: M_Concept): void {
    this.ve?.concepts.push(c);
  }

  setUpForm(object: M_Vehicle | M_ComercialBudget) {

    this.ve = object instanceof M_Vehicle ? object : object.vehicle;

    if (this.ve) {
      this.form.patchValue(this.ve);

      this.form.get('registration_tax')?.disable();
    }

    if (object instanceof M_ComercialBudget) {
      if (this.ve) {
        this.ve.concepts = [];
        object.concepts.forEach(c => {
          this.ve?.concepts.push(c);
        })
        this.ve.products = [];
        object.products.forEach(p => {
          this.ve?.products.push(p);
        })
      }
      this.form.patchValue({ budget_id: object.id });
      this.form.patchValue({
        vehicle_id: object.vehicle?.vehicle_id,
        price: object.price,
        client_id: object.client_id,
      });
    }

    this.loaded = true;

  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
  get isVn() {
    if (!this.form) { return false; }
    return this.form.get('comercialType')?.value == this.CVT.VN;
  }
  get clientInvoicePlaceholder(): string {
    return this.clientInvoice?.selected?.name || 'Cliente a facturar';
  }

  getClient() {
    return this.csearcherC?.selected;
  }
  getClientDiscount(p: M_Product | M_CustomProduct): number | null {
    let disc = this.csearcherC?.selected?.getClientDiscount(p);
    return disc ? disc : null;
  }

  selectClient(client: M_Contact) {
    if (client) {
      this.form.patchValue({ client_id: client.client_id });
    }
  }
  returnGarage() {
    this.routerS.goTo(ViewPath.garage);
  }

  selectClientInv(selectedClient: M_Contact) {
    this.select_client = selectedClient;
  }
  sellVehicle() {
    if (!this.ve) { return; }
    if (this.fs.isOk(this.form)) {
      console.log(this.form);
      this.d.open<CreateInvoiceDialogComponent, invoiceDialogData>(
        CreateInvoiceDialogComponent,
        {
          data: {
            contact: this.select_client
          }
        }).afterClosed().subscribe(res => {
          if (res.date) {
            let date = new Date(res.date);
            this.form.patchValue({ date: date });
            this.apiS.sellVehicle(this.form.value, this.ve!).then(res => {
              if (res.invoice && res.invoice.token) {
                this.invoice = res.invoice;
                if (this.isOnDialog) {
                  this.dialogRef.close(res)
                }
                else {
                  this.invoice!.created = true;
                  this.paramsS.go(this.v.invoiceDetails, this.invoice!.id!)
                }
                console.log("llega aqui ?");
                this.previewS.showPreview("I", res.invoice.token, undefined, undefined, undefined, undefined, this.bill);
              }
            });
          }
        })
    }
  }

  addProduct(p: M_Product | M_CustomProduct): void {
    this.ve?.products.push(p);
  }

  removeProduct(p: M_CustomProduct | M_Product): void {
    this.ve?.products.removeElement(p);
  }

  addTime(time: M_CustomProduct) {
    this.addProduct(time)
    this.chdRef.detectChanges();
  }

  addComment(comment: M_CustomProduct): void {
    this.addProduct(comment)
    this.chdRef.detectChanges();
  }

  get totalSell(): number {
    if (!this.ve) { return 0; }
    const vehiclePrice = this.form.get('price')?.value || 0;
    const vehicleTax = this.ve?.getTaxTotal(vehiclePrice, this.ve?.registration_tax!);

    const conceptsTotal = this.ve.concepts.reduce((acc, concept) => {
      if (!concept.in_invoice) {
        const conceptPriceWithTax = concept.tax > 0 ? concept.price + (concept.price * concept.tax / 100) : concept.price;
        return acc + conceptPriceWithTax;
      }
      return acc;
    }, 0);

    const conceptsExceptIvaTotal = this.ve.concepts.reduce((acc, concept) => {
      if (concept.tax === 0 && !concept.in_invoice) {
        return acc + concept.price;
      }
      return acc;
    }, 0);

    const conceptsRegistrationTax = this.ve.concepts
      .filter(concept => concept.registration_tax)
      .reduce((acc, concept) => acc + concept.getPriceAndDiscount(concept.price, this.ve?.registration_tax!), 0);

    return vehiclePrice + conceptsTotal + conceptsExceptIvaTotal + conceptsRegistrationTax + vehicleTax;
  }

  get subtotal(): number {
    if (!this.ve) { return 0; }
    const vehiclePrice = this.form.get('price')?.value || 0;
    const conceptsTotal = this.ve.concepts.reduce((acc, concept) => {
      if (!concept.in_invoice) {
        return acc + concept.price;
      }
      return acc;
    }, 0);
    return vehiclePrice + conceptsTotal;
  }

  get iva4Exists() {
    if (!this.ve) { return 0; }
    return this.ve.concepts.some(concept => concept.isIVA4);
  }

  get regTaxConcepts() {
    if (!this.ve) { return 0; }
    return this.ve.concepts.some(concept => concept.rgTax);
  }

  get regTaxV() {
    return this.ve?.registration_tax !== undefined;
  }

  get regTaxVehicle() {
    return this.ve?.registration_tax;
  }

  get iva10Exists() {
    if (!this.ve) { return 0; }
    return this.ve.concepts.some(concept => concept.isIVA10);
  }

  get iva21Exists() {
    if (!this.ve) { return 0; }
    return this.ve.concepts.some(concept => concept.isIVA21);
  }

  get ivaExentexists() {
    if (!this.ve) { return 0; }
    return this.ve.concepts.some(concept => concept.exento);
  }

  get totalIVA4() {
    if (!this.ve) { return 0; }
    return this.ve.concepts
      .filter(concept => concept.isIVA4 && !concept.in_invoice)
      .reduce((acc, concept) => acc + concept.getPriceAndDiscount(concept.price, concept.tax), 0);
  }

  get totalIVA10() {
    if (!this.ve) { return 0; }
    return this.ve.concepts
      .filter(concept => concept.isIVA10 && !concept.in_invoice)
      .reduce((acc, concept) => acc + concept.getPriceAndDiscount(concept.price, concept.tax), 0);
  }

  get totalIVA21() {
    if (!this.ve) { return 0; }
    return this.ve.concepts
      .filter(concept => concept.isIVA21 && !concept.in_invoice)
      .reduce((acc, concept) => acc + concept.getPriceAndDiscount(concept.price, concept.tax), 0);
  }

  get totalIVAExempt() {
    if (!this.ve) { return 0; }
    return this.ve.concepts
      .filter(concept => concept.exento && !concept.in_invoice)
      .reduce((acc, concept) => acc + concept.getPriceAndDiscount(concept.price, concept.tax), 0);
  }

  get totalIVATax() {
    if (!this.ve) { return 0; }
    const vehiclePrice = this.form.get('price')?.value || 0;
    const vehicleTax = this.ve?.getTaxTotal(vehiclePrice, this.ve?.registration_tax!);

    const taxConcepts = this.ve.concepts
      .filter(concept => concept.registration_tax)
      .reduce((acc, concept) => acc + concept.getPriceAndDiscount(concept.price, this.ve?.registration_tax!), 0);

    return vehicleTax! + taxConcepts;
  }

}
