import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterService } from './router.service';
import { StorageService } from './storage.service';
import { Subject } from 'rxjs/internal/Subject';
import { ConfirmDialogService } from './confirm-dialog.service';
import { ISessionService } from '../interfaces/ISessionService';
import { projectConfiguration } from '../app.module';

@Injectable({
  providedIn: 'root'
})

/**
 * [Service]
 * Manage the user session. Has the token and the ID.
 */
export class SessionService implements ISessionService {

  private user_id_key = "user_id";
  private tokenKey = "access_token";
  NO_SESSION: string = "Unauthenticated.";

  onLogOut = new Subject<any>()


  constructor(private routerServie: RouterService, private storageS: StorageService, private confirmD: ConfirmDialogService) { }

  /**Obtiene el token de sessión */
  getToken() {
    return this.storageS.get(this.tokenKey);
  }

  /**Genera el 'Header' de autorización para las llamadas http */
  geAuthorizationHeader() {
    let header = new HttpHeaders();
    header = header.set('Authorization', 'Bearer ' + this.getToken());
    return header;
  }

  /**Guarda el token de la sessión */
  setToken(token: string) {
    this.storageS.save(this.tokenKey, token)
  }

  hasSession(): boolean {
    return this.getToken() == null ? false : true;
  }

  isMe(user_id: number | string | undefined) {
    if (user_id == undefined) { return false }
    return this.getId() == user_id;
  }

  getId() {
    let id = this.storageS.get(this.user_id_key)
    if (id != null && id.getNumber()) {
      return id.getNumber();
    }
    return undefined;
  }

  setId(id: number) {
    this.storageS.save(this.user_id_key, id);
  }


  /** Deletes all the storage and go to login */
  logOut(checkOtherSession: boolean = false) {

    let token = this.getToken();

    this.storageS.clear();
    this.routerServie.goTo(projectConfiguration.loginView);
    this.onLogOut.next(true);

    if (checkOtherSession && token != null) {
      this.confirmD.show({
        title: "La sesión ha caducado",
        body: "Vuelve a iniciar sesión",
        confirmTxt: "Ok",
        showCancel: false,
        type: "info"
      })
    }
  }

  /** Set the token and the user id if exists */
  onUserLogin(token: string, id?: number) {
    this.storageS.clear();
    this.setToken(token);
    if (id) { this.setId(id); }
  }
}