import { Injectable } from '@angular/core';
import { Endpoint } from '../custom-classes/Endpoint';
import { projectConfiguration } from '../app.module';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor() { }
  getEnpointFromUrl(url: string): Endpoint | undefined {
    /**Miramos si la URL que se solicita coincide con los endpoints que tenemos establecidos */
    let endp = this.checkAllEndpoints(url);
    return endp == undefined ? this.splitEnpoint(url) : endp;
  }

  checkAllEndpoints(url: string): Endpoint | undefined {
    for (let i = 0; i < projectConfiguration.endpoints.length; i++) {

      var url_core = projectConfiguration.endpoints[i].url;

      if (url_core == url) {
        return projectConfiguration.endpoints[i];
      }

      /** Eliminación de una barra suelta al final de la url. */
      if (url_core[url_core.length - 1] == "/") {
        var finalE = url_core.slice(0, url_core.length - 1)
        if (finalE == url) {
          return projectConfiguration.endpoints[i];
        }
      }

    }
    return undefined;
  }

  /** Es posible que tengamos un endpoint establecido, pero que no sea posible detectarlo, ya que acepta parámetros */
  /** En este caso, hacemos un 'split' de todas las '/' y miramos si coincide */
  /** "https://....com/api/test/enpoint/72" --&gt; No coincide */
  /** "https://....com/api/test/enpoint" --&gt; Coincide */
  splitEnpoint(url: string): Endpoint | undefined {

    let originalUrl = url;
    let baseUrl = environment.apiUrl;
    let urlEnding = originalUrl.split(baseUrl).find(v => v.length > 0)
    let endpoint: Endpoint | undefined = undefined;

    if (urlEnding) {
      const segments = urlEnding.split("/");
      /** Why 3 ? --> The function will look at the endpoint until '/api/something' */
      for (let i = segments.length; i >= 3; i--) {
        const trimmedSegments = segments.slice(0, i).join("/");
        let urlToCheck = baseUrl + trimmedSegments;
        let check1 = this.checkAllEndpoints(urlToCheck);
        if (endpoint == undefined && check1) {
          endpoint = check1;
        }
      }
    }
    return endpoint;

  }
}
