<form class="form" style="max-width: unset;" *ngIf="!actionFailedToLoad; else notfound">

  <div class="container">
    <div class="title">
      <app-go-back [text]="'Listado OR'" [v]="v.or" [show]="actionLoaded && allInvoiced"></app-go-back>
    </div>
    <div class="or_abstract">
      <app-card [contentLoaded]="actionLoaded" class="bc_b1 full-height-or">
        <div [ngClass]="{'dn_i' : !action}">

          <app-advanced-details *ngIf="action">

            <app-advanced-title>
              <div class="df aic gap5">
                {{action.title_id}}
                <app-or-status-label class="ml5-or-status" [showText]="false" [action]="action"></app-or-status-label>
                <app-or-type-label [wrapOn]="responsiveS.w < 600 ? 1 :  responsiveS.w > 1200 ? 3 :2" class="ml5"
                  [showText]="false" [tipo]="action? action.type : undefined"></app-or-type-label>
              </div>
            </app-advanced-title>

            <app-advanced-subtitle>
              Creada el {{action.created_at.dayMonthYearFormat()}}
            </app-advanced-subtitle>

            <app-advanced-main-actions>
              <div
                [matTooltip]="!action.hasClosedGroups()? 'Debes cerrar alguna intervención para poder facturarla' : ''">
                <button subscribed-user mat-flat-button [roles]="[R.ADMIN, R.ADVISER]" color="primary"
                  [disabled]="!action.hasClosedGroups()" (click)="openInvoiceDialog()" class="mr25on368">
                  Facturar
                </button>
              </div>
              <app-documentation-pdf-menu class="mla" [action]="action"></app-documentation-pdf-menu>
            </app-advanced-main-actions>

            <app-advanced-menu-actions>

              <div [ngClass]="{'trasnferAllInvoiced' : action.isDone()}" *ngIf="userS.userIsAdminOrAdviser;">
                <button mat-menu-item matTooltipPosition="left" matTooltip="Se creará una OR a partir de esta"
                  *ngIf="!this.dragComponent?.action?.isVnVoOr" (click)="trasnferTo(drag, 'or')">
                  <mat-icon>build</mat-icon>
                  Nueva OR
                </button>

                <div [matTooltipPosition]="'left'"
                  [matTooltip]="toBudgetButton.disabled ? 'La OR tiene grupos internos' : ''"
                  *ngIf="!this.dragComponent?.action?.isVnVoOr">
                  <button #toBudgetButton mat-menu-item (click)="trasnferTo(drag, 'budget')"
                    [disabled]="hasSomeInterno()">
                    <mat-icon>calculate</mat-icon>
                    Nuevo presupuesto
                  </button>
                </div>
              </div>

              <div class="envioMails" [roles]="[R.ADMIN, R.ADVISER]">
                <div class="w100" style="display: contents;">
                  <app-layout-send-email [butontype]="'mat-menu-item'" *ngIf="action.status.num == 1"
                    [temaplte]="'recogida'" [endpoint]="pickUpMail.bind(this)"
                    style="justify-content: start;"></app-layout-send-email>
                </div>
                <div class="w100">
                  <div [matTooltip]=" action.client?.email == undefined ? 'El cliente no tiene correo' : ''"
                    [matTooltipPosition]="'left'">
                    <app-layout-send-email [butontype]="'mat-menu-item'"
                      *ngIf="action.client  && !action.client.deleted" [temaplte]="'resend-or'"
                      [endpoint]="reSendOR.bind(this)"
                      [disabled]="action.client.email == undefined"></app-layout-send-email>
                  </div>
                </div>
              </div>

              <button mat-menu-item [disabled]="!action.canDelete"
                (click)="deleteAction()">
                <mat-icon>delete</mat-icon> Eliminar
              </button>

            </app-advanced-menu-actions>
          </app-advanced-details>

          <app-advanced-details-footer>

            <app-footer-title>Ver más datos de OR</app-footer-title>

            <app-footer-right *ngIf="action">
              <div class="df gap5">
                <app-contact-client-rectangle *ngIf="action.client"
                  [client]="action.client"></app-contact-client-rectangle>
                <app-vehicle-rectangle *ngIf="action.vehicle" [vehicle]="action.vehicle"></app-vehicle-rectangle>
              </div>
            </app-footer-right>

            <app-footer-content>

              <!-- NOTES AND IMAGES -->
              <app-section>
                <app-section-title>Datos adicionales</app-section-title>
                <app-section-content>
                  <app-or-notes-and-images [orFormComponent]="orForm"></app-or-notes-and-images>
                </app-section-content>
              </app-section>
            </app-footer-content>
          </app-advanced-details-footer>
        </div>
      </app-card>
    </div>

    <div class="timelapse">
      <app-card [contentLoaded]="actionLoaded" class="different-padding-top">
        <app-card-subtitle [first]="true">
          <div class="df aic">
            Paso de taller
            <button mat-icon-button [disabled]="true"></button>
          </div>

        </app-card-subtitle>
        <div class="card-contents-185 card-wrapper">
          <app-timelapse *ngIf="action" [timelapse]="action.timelapse" [currentItemId]="action.id"
            [currentItemType]="TLE.OR"></app-timelapse>
        </div>
      </app-card>
    </div>

    <div class="worker">
      <app-card [contentLoaded]="actionLoaded" class="different-padding-top">
        <app-card-subtitle [first]="true">
          <div class="df aic gap10">
            <span>Operario</span>
            <app-circular-letter [user]="orForm.workerSearcher?.selected" [showFullName]="false"></app-circular-letter>
            <mat-icon *ngIf="workerChange" class="unsavedDot vam" [filled]="true"
              [matTooltip]="'Cambio de operario no guardado'">circle</mat-icon>
            <div class="mla">
              <button mat-mini-fab color="primary" *ngIf="orForm.workerSearcher"
                (click)="changeWorker(orForm.workerSearcher.allData, orForm.workerSearcher.selected)">
                <mat-icon>change_circle</mat-icon>
              </button>
            </div>
          </div>
        </app-card-subtitle>
        <div class="card-contents-185 card-wrapper">
          <app-or-form #orForm [isEdit]="true" [loaded]="actionLoaded" [showWokerInput]="false"
            [wrapper]="false"></app-or-form>
        </div>
      </app-card>
    </div>

    <div class="time">
      <app-card [contentLoaded]="actionLoaded" class="different-padding-top">
        <div *ngIf="action">
          <app-card-subtitle [first]="true">
            <div class="df aic">
              <span>Fichaje <span *ngIf="otd.someTimeFinished">{{otd.getTotalTime()?.format}}</span></span>
              <div class="or_time_actions">
                <div class="df jcc fww aic gap10" [ngClass]="{'vh' : !action.canAddTime()}">
                  <!--Work button -->
                  <button color="primary" (click)="startStopTime()" mat-flat-button class="work-or custom-button">
                    <mat-icon>{{isOperatorWorking() ? 'stop_circle' : 'play_circle'}}</mat-icon>
                    {{isOperatorWorking() ? isOperatorWorking()!.elapsed : 'Trabajar'}}
                  </button>
                  <!--Add manual time button -->
                  <div (click)="addTime()">
                    <button mat-mini-fab color="primary" [matTooltip]="'Añadir fichaje manualmente'"
                      [matTooltipPosition]="'above'">
                      <mat-icon>add_circle</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </app-card-subtitle>
          <div class="card-contents-185 card-wrapper">
            <app-history-time-or-dialog #otd [or]="action" [workers]="formComponent?.workerSearcher?.allData"
              [canRemove]="!action.isDone()">
            </app-history-time-or-dialog>
          </div>
        </div>
      </app-card>
    </div>


  </div>
</form>


<div class="or" [ngClass]="{'dn' : actionFailedToLoad}">
  <app-card [contentLoaded]="actionLoaded" [userNeedSubscription]="true">
    <app-card-title>Intervenciones</app-card-title>
    <app-drag #drag [action]="action" [isBudgetPage]="false" (onRemoveChildGroupTask)="checkOthersInvoiced($event)"
      (onInvoiceChildTask)="openDialog($event)">
    </app-drag>
  </app-card>
</div>


<button class="general-save" [ngClass]="saveButton.disabled ? 'hide' : 'show'" #saveButton subscribed-user
  mat-flat-button [disabled]="!drag.hasChanges() && !unsavedChanges" (click)="saveScreen(drag)" color="primary">
  Guardar
</button>



<ng-template #notfound>
  <app-model-not-found [title]="'No se ha encontrado la orden de reparación'"
    [goText]="'Ir al listado de órdenes de reparación'" [view]="v.or">
  </app-model-not-found>
</ng-template>