import { TIME_G, timeBreakdown, timeDiffConfig, timeDifferenceType } from "../types/time-difference";


export function timeDifference(v1: Date | undefined, v2: Date | undefined, config?: timeDiffConfig): timeDifferenceType {

  if (v1 == undefined || v2 == undefined) {
    return {
      breakdown: {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      format: "0"
    }
  }

  var startTime = v1;
  var endTime = v2;

  // get total seconds between the times
  var delta = Math.abs(startTime.getTime() - endTime.getTime()) / 1000;

  years = 0;
  months = 0;
  days = 0;

  if (!config?.timeSeparation) {
    // Calculate (and subtract) whole years
    var years = Math.floor(delta / 31536000);
    delta -= years * 31536000;

    // Calculate (and subtract) whole months
    var months = Math.floor(delta / 2592000) % 12;
    delta -= months * 2592000;

    // Calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

  }

  // Calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600);
  hours = !config?.timeSeparation ? hours % 24 : hours; // Only module on timeseparation config
  delta -= hours * 3600;

  // Calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // What's left is seconds
  var seconds = Math.floor(delta % 60);  // in theory the modulus is not required

  var breakdown: timeBreakdown = {
    years: years,
    months: months,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }

  var format = formatStr(breakdown, config)

  var returnVlue: timeDifferenceType = {
    breakdown,
    format
  }

  return returnVlue;
}

export function sumTimes(diff: timeDifferenceType | timeDifferenceType[], config?: timeDiffConfig): timeDifferenceType {
  var y: number = 0
  var m: number = 0;
  var d: number = 0;
  var h: number = 0;
  var min: number = 0;
  var s: number = 0;

  if (!Array.isArray(diff)) {
    diff = [diff];
  }

  diff.forEach(diff => {
    y += diff.breakdown.years;
    m += diff.breakdown.months;
    d += diff.breakdown.days;
    h += diff.breakdown.hours;
    min += diff.breakdown.minutes;
    s += diff.breakdown.seconds;
  })

    // Transformar segundos en minutos si superan los 60
    if (s >= 60) {
      const minutesToAdd = Math.floor(s / 60);
      min += minutesToAdd;
      s = s % 60;
    }
  
    // Transformar minutos en horas si superan los 60
    if (min >= 60) {
      const hoursToAdd = Math.floor(min / 60);
      h += hoursToAdd;
      min = min % 60;
    }
  
    // Transformar horas en días si superan las 24
    if (h >= 24) {
      const daysToAdd = Math.floor(h / 24);
      d += daysToAdd;
      h = h % 24;
    }
  
    // Transformar días en meses si superan los 30
    if (d >= 30) {
      const monthsToAdd = Math.floor(d / 30);
      m += monthsToAdd;
      d = d % 30;
    }
  
    // Transformar meses en años si superan los 12
    if (m >= 12) {
      const yearsToAdd = Math.floor(m / 12);
      y += yearsToAdd;
      m = m % 12;
    }

  var breakdown: timeBreakdown = {
    years: y,
    months: m,
    days: d,
    hours: h,
    minutes: min,
    seconds: s
  }

  var format = formatStr(breakdown, config)

  var returnVlue: timeDifferenceType = {
    breakdown,
    format
  }

  return returnVlue;

}

export function formatStr(values: timeBreakdown, config?: timeDiffConfig) {
  var finalstr = "";
  finalstr += setUpgranularityFormat(values.years, [TIME_G.years, "año"], config);
  finalstr += setUpgranularityFormat(values.months, [TIME_G.months, "mes"], config);
  finalstr += setUpgranularityFormat(values.days, [TIME_G.days, "día"], config);
  finalstr += setUpgranularityFormat(values.hours, [TIME_G.hours, "hora"], config);
  finalstr += setUpgranularityFormat(values.minutes, [TIME_G.minutes, "minuto"], config);
  finalstr += setUpgranularityFormat(values.seconds, [TIME_G.seconds, "segundo"], config, (allIsZero(values) ? true : false));
  return finalstr;
}

function allIsZero(values: timeBreakdown){
  return (values.years == 0 && values.months == 0 && values.days ==0 && values.hours ==0 && values.minutes == 0 && values.seconds == 0);
}

/**
 * To document :)
 * @param value 
 * @param info 
 * @param config 
 * @param getZeroValue Incluir los valores que sean 0 (siempre que no se desee el formato de timeSeparation)
 * @returns 
 */
export function setUpgranularityFormat(value: number, info: [TIME_G, string], config?: timeDiffConfig, getZeroValue? : boolean) {

  var finalv = value.toString();


  /** On time separation we need 20:00:01 for example (notice thath hours are 0) */
  if (value == 0 && !config?.timeSeparation && !getZeroValue) {
    return ""
  }

  if (config?.timeSeparation) {
    if (info[0] == TIME_G.years || (info[0] == TIME_G.months) || (info[0] == TIME_G.days)){
      return ""
    }
  }

  if (config?.leadingzero || config?.timeSeparation) {
    finalv = '' + (value < 10 ? '0' : '') + value;
  }

  if ((config?.prefix || !config) && !config?.timeSeparation) {
    finalv +=
      config?.prefix == "large" || !config ?
        ' ' + info[1] + (value >= 2 ? 's' : '') :
        info[1].substring(0, 1)
  }


  finalv += config?.timeSeparation ? ":" : " "

  return finalv;
}
