import { Component, ComponentRef, ElementRef, HostListener, Input, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { M_Concept } from '../../models/M_Concept';
import { ConceptSearcherComponent } from '../concept-searcher/concept-searcher.component';
import { M_Vehicle } from '../../models/M_Vehicle';
import { ComercialConceptType } from '../../enums/ComercialConceptType';
import { IConceptLineTableComponent } from '../../interfaces/IConceptLineTableComponent';
import { VehicleformComponent } from '../vehicleform/vehicleform.component';
import { CreateComercialBudgetComponent } from 'src/app/views/create-comercial-budget/create-comercial-budget.component';
import { SellVehicleComponent } from 'src/app/views/sell-vehicle/sell-vehicle.component';
import { VnvoDetailsComponent } from 'src/app/views/vnvo-details/vnvo-details.component';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-concepts-line-table',
  templateUrl: './concepts-line-table.component.html',
  styleUrls: ['./concepts-line-table.component.css']
})
export class ConceptsLineTableComponent {

  CTP = ComercialConceptType;
  /** Array of he current {@link ConceptSearcherComponent} on this component */
  searchers: ComponentRef<ConceptSearcherComponent>[] = [];
  parent: IConceptLineTableComponent;

  @ViewChild('searchConceptContainerHTML', { read: ViewContainerRef }) searchProviderContainer!: ViewContainerRef;
  inInvoice: boolean = false;
  @Input({ required: true }) concepts: M_Concept[] = [];
  @Input() vehicle!: M_Vehicle | undefined;
  @Input() showSave: boolean = false;
  @Input() isDialog: boolean = false;
  @Input() showPVP: boolean = true;
  @Input({ required: true }) isInfo: boolean = true;
  /** Can the user modify this table? */
  @Input() canModifyTable: boolean = true;
  @ViewChild('checkInv', { static: true }) checkInv!: ElementRef;
  /** CTRL + SPACE shortcut listener */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && (event.key === 'C' || event.key === 'c')) {
      if (this.canModifyTable) {
        if (this.searchers.length) {
          this.searchers[0].instance.focus();
        }
        else {
          this.addConceptSearcher();
        }
      }
    }
  }

  constructor(public responsiveS: ResponsiveService,
    @Optional() private createvehicle: VehicleformComponent,
    @Optional() private vnvoDetails: VnvoDetailsComponent,
    @Optional() private createComercialBudget: CreateComercialBudgetComponent,
    @Optional() private sellVehicle: SellVehicleComponent
  ) {
    this.parent = this.checkAndSetParent();
  }

  checkAndSetParent() {
    if (this.createvehicle || this.vnvoDetails || this.createComercialBudget || this.sellVehicle) {
      return this.createvehicle || this.vnvoDetails || this.createComercialBudget || this.sellVehicle;
    } else {
      throw new Error("ConceptLineTableComponent parent not implement 'I_ConceptLineTableComponent' or is not one of the optional constructor parents");
    }
  }

  private isRequestedConcept(concept: M_Concept) {
    return this.isBlock(concept);
  }

  isBlock(c: M_Concept) {
    return c.lineBlock;
  }

  isLineBlocked(concept: M_Concept) {
    return this.isRequestedConcept(concept);
  }

  addConceptSearcher() {
    let ref = this.searchProviderContainer.createComponent(ConceptSearcherComponent);
    ref.instance.ref = ref;

    ref.instance.onSelectConcept.subscribe(v => {
      v.line_hasChanges = true;
      this.parent.addConcept(v);
      this.removeConceptSearcher(ref);
    })

    ref.instance.onDestroy.subscribe(v => {
      this.removeConceptSearcher(ref);
    })

    this.searchers.push(ref)
  }
  isPVPDisabled(c: M_Concept) {
    return !this.canModifyTable || this.isLineBlocked(c)
  }
  onCheckboxChange(event: any) {
    event.checked;
  }
  private removeConceptSearcher(searcher: ComponentRef<ConceptSearcherComponent>) {
    this.searchers.removeElement(searcher);
    searcher.instance.destroy();
  }

  removeConcept(c: M_Concept) {
    this.concepts.removeElement(c);
  }

  clearUnsaved() {
    this.concepts.forEach(c => {
      c.line_hasChanges = false;
    })
  }

  get hasChanges() {
    return this.concepts.some(c => c.line_hasChanges);
  }

  notShowPvP(c: M_Concept) {
    if (c.getType === 'Normal') {
      return false
    }
    else if (c.getType === 'Descuento') {
      return false
    }
    else {
      return true
    }
  }

  notShowCost(c: M_Concept) {
    if (c.concept_type === this.CTP.NORMAL) {
      return false
    }
    else if (c.concept_type === this.CTP.DESCUENTO) {
      return true
    }
    else {
      return false
    }
  }

  adjustPriceValue(concept: M_Concept): void {
    let value = concept.price;

    if (concept.concept_type === this.CTP.DESCUENTO && value > 0) {
      value = -value;
    } else if (concept.concept_type !== this.CTP.DESCUENTO && value < 0) {
      value = -value;
    }
    concept.price = value;
  }

  onPriceInput(concept: M_Concept): void {
    concept.line_hasChanges = true;
  }

}
