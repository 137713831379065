import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Action } from '../../models/M_Action';
import { M_Contact } from '../../models/M_Contact';
import { M_Product } from '../../models/Products/M_Product';
import { M_Vehicle } from '../../models/M_Vehicle';
import { ParamsService } from '../../services/params.service';
import { UserService } from '../../services/EinaMainData/user.service';
import { CompanyService } from '../../services/EinaMainData/company.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RouterService } from 'src/app/services/router.service';
import { SearchService } from 'src/app/services/search.service';
import { Views } from 'src/app/custom-classes/View';
import { BillPageFiltesrEnum } from 'src/app/views/invoices/invoices.component';
import { ORPageFiltesrEnum } from 'src/app/views/or/or.component';
import { OrderPageFiltesrEnum } from 'src/app/views/orders/orders.component';
import { ComercialBudgetPageFiltesrEnum } from 'src/app/views/budget/comercial-budget/comercial-budget.component';
import { AppoPageFiltesrEnum } from 'src/app/views/appointments/appointments.component';
import { AlbaranPageFiltesrEnum } from 'src/app/views/albaranes/albaranes.component';
import { BudgetPageFiltesrEnum } from 'src/app/views/budget/workshop-budget/workshop-budget.component';


class ResultOption {
  constructor(public icon: string, public label: string, public action: () => void, public sublabel?: string) { }
}

class ResultOptionGroup {
  constructor(public groupId: groupEnum, public options: ResultOption[]) { }
}

enum groupEnum {
  USERS = "Contactos",
  VEHICLES = "Vehículos",
  PRODUCTS = "Productos",
  OR = "Órdenes de reparación",
  BUDGETS = "Presupuestos",
  ACTION = "Aciones"
}

@Component({
  selector: 'app-big-mama',
  templateUrl: './big-mama.component.html',
  styleUrls: ['./big-mama.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BigMamaComponent implements OnInit {
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('input') input!: ElementRef<HTMLElement>;
  myControl = new UntypedFormControl('');
  resultOptionsGroup: ResultOptionGroup[] = [];
  actions: ResultOption[];
  v = ViewPath;
  loading = false;
  finalsearh: string = "";
  BPFE = BillPageFiltesrEnum;
  OPFE = ORPageFiltesrEnum;
  ORDERSPFE = OrderPageFiltesrEnum;
  BudgetPFE = BudgetPageFiltesrEnum;
  ComercialBudgetPFE = ComercialBudgetPageFiltesrEnum;
  AppoPFE = AppoPageFiltesrEnum;
  AlbaranPFE = AlbaranPageFiltesrEnum;
  focus = false;

  constructor(private apiS: ApiService, private routerS: RouterService, private paramsS: ParamsService, private searchS: SearchService,
    public responsiveS: ResponsiveService, public userS: UserService, private companyS: CompanyService) {
    this.actions = [

      /** Contacts */
      ...this.userCanGo(this.v.createContact) ? [new ResultOption("start", "Crear un contacto", () => this.routerS.goTo(this.v.createContact))] : [],

      /** Vehicles */
      ...this.userCanGo(this.v.createvehicle) ? [new ResultOption("start", "Crear un vehiculo", () => this.routerS.goTo(this.v.createvehicle))] : [],

      /** Products */
      ...this.userCanGo(this.v.createProduct) ? [new ResultOption("start", "Crear un producto", () => this.routerS.goTo(this.v.createProduct))] : [],

      /** Invoices */
      ...this.userCanGo(this.v.createInvoice) ? [new ResultOption("start", "Crear factura", () => this.routerS.goTo(this.v.createInvoice))] : [],
      ...this.userCanGo(this.v.invoices) ? [new ResultOption("start", "Facturas a cobrar", () => this.routerS.goWithQueryParams(this.v.invoices, { filter: this.BPFE.INVOICE_STATE, filtervalue: 0, filter1: this.BPFE.INVOICE_STATE, filtervalue1: 1 }))] : [],

      /** OR */
      ...this.userCanGo(this.v.createOr) ? [new ResultOption("start", "Crear OR", () => this.routerS.goTo(this.v.createOr))] : [],
      ...this.userCanGo(this.v.or) ? [new ResultOption("start", "OR a facturar", () => this.routerS.goWithQueryParams(this.v.or, { filter: this.OPFE.OR_GROUP_STATUS, filtervalue: 1 }))] : [],

      /** Presupuestos taller */
      ...this.userCanGo(this.v.createEditWorkshopBudget) ? [new ResultOption("start", "Crear presupuesto de taller", () => this.routerS.goTo(this.v.createEditWorkshopBudget))] : [],
      ...this.userCanGo(this.v.workshopBudget) ? [new ResultOption("start", "Presupuestos de taller abiertos", () => this.routerS.goWithQueryParams(this.v.workshopBudget, {filter : this.BudgetPFE.BUDGET_STATUS, filtervalue : 0}))] : [],

      /** Presupuestos comercial */
      ...this.userCanGo(this.v.createComercialBudget) ? [new ResultOption("start", "Crear presupuesto de comercial", () => this.routerS.goTo(this.v.createComercialBudget))] : [],
      ...this.userCanGo(this.v.comercialBudget) ? [new ResultOption("start", "Presupuestos de comercial abiertos", () => this.routerS.goWithQueryParams(this.v.comercialBudget, {filter : this.ComercialBudgetPFE.BUDGET_STATUS, filtervalue: 0}))] : [],

      /** Appointments */
      ...this.userCanGo(this.v.createappointment) ? [new ResultOption("start", "Crear cita", () => this.routerS.goTo(this.v.createappointment))] : [],
      ...this.userCanGo(this.v.appointments) ? [new ResultOption("start", "Citas para hoy", () => this.routerS.goWithQueryParams(this.v.appointments, {filter : this.AppoPFE.APPOINTMENT_DAY, filtervalue: 0}))] : [],

      /** Albaranes */
      ...this.userCanGo(this.v.createEditAlbaran) ? [new ResultOption("start", "Crear albarán", () => this.routerS.goTo(this.v.createEditAlbaran))] : [],
      ...this.userCanGo(this.v.albaranes) ? [new ResultOption("start", "Albrán a cobrar", () => this.routerS.goWithQueryParams(this.v.albaranes, {filter : this.AlbaranPFE.STATUS, filtervalue: 1}))] : [],

      /** Pedidos pendientes */
      ...this.userCanGo(this.v.orders) ? [new ResultOption("start", "Pedidos pendientes", () => this.routerS.goWithQueryParams(this.v.orders, { filter: this.ORDERSPFE.ORDER_STATUS, filtervalue: 0 }))] : [],

    ]
  }

  ngOnInit() { }

  userCanGo(v: Views) {
    return this.routerS.userCanGo(v);
  }

  search(searched: string) {

    if (searched == this.finalsearh) { return }

    this.finalsearh = searched;

    if (searched == "") {
      this.clearGroups();
      this.loading = false;
    }
    else if (!this.loading) {
      this.loading = true;
      this.apiS.globalSearch(searched).then(resp => {

        this.loading = false;
        if (searched != this.finalsearh) {
          this.search(this.finalsearh);
        }

        this.clearGroups();


        if (resp.clients && this.userS.userIsAdminOrAdviser) {
          for (let i = 0; i < resp.clients.length; i++) {
            let c = new M_Contact(resp.clients[i])
            this.addOption(groupEnum.USERS, new ResultOption("person", c.getName(), () => this.paramsS.go(this.v.contactDetails, c.client_id), c.nif))
          }
        }
        if (resp.vehicles && this.userS.userIsAdminOrAdviser) {
          for (let i = 0; i < resp.vehicles.length; i++) {
            let v = new M_Vehicle(resp.vehicles[i])
            this.addOption(groupEnum.VEHICLES, new ResultOption(v.icon, v.getName(), () => this.paramsS.goVehicleDetails(v), v.license))
          }
        }
        if (resp.products && this.userS.userIsAdminOrAdviser) {
          for (let i = 0; i < resp.products.length; i++) {
            let p = new M_Product(resp.products[i]);
            this.addOption(groupEnum.PRODUCTS, new ResultOption(p.icon, p.name, () => this.paramsS.go(this.v.productDetails, p.product_id!), p.smartRef(this.companyS.recambiosModule)))
          }
        }
        if (resp.or) {
          for (let i = 0; i < resp.or.length; i++) {
            let or = new M_Action(resp.or[i]);
            this.addOption(groupEnum.PRODUCTS, new ResultOption("build", or.id.toString(), () => this.paramsS.go(this.v.editOr, or.id!), or.vehicle ? or.vehicle.license : "Sin vehículo asigando"))
          }
        }
        if (resp.budget && this.userS.userIsAdminOrAdviser) {
          for (let i = 0; i < resp.budget.length; i++) {
            let budget = new M_Action(resp.budget[i]);
            this.addOption(groupEnum.PRODUCTS, new ResultOption("calculate", budget.id.toString(), () => this.paramsS.go(this.v.createEditWorkshopBudget, budget.id!), budget.vehicle ? budget.vehicle.license : "Sin vehículo asigando"))
          }
        }
        /** Actions  */
        this.addActions(searched);
      })
    }
  }



  /** Se evita que en el input salga [object Object] al hacer click en una opción de 'autocompletado' */
  getOptionText(_v: any) {
    return ""
  }

  addOption(g: groupEnum, ro: ResultOption) {
    let groupFound = false;
    for (let i = 0; i < this.resultOptionsGroup.length; i++) {
      if (this.resultOptionsGroup[i].groupId == g) {
        this.resultOptionsGroup[i].options.push(ro);
        groupFound = true;
      }
    }
    if (!groupFound) {
      this.resultOptionsGroup.push(new ResultOptionGroup(g, [ro]))
    }
  }

  addActions(searched: string) {
    for (let i = 0; i < this.actions.length; i++) {
      if (this.searchS.match(searched, this.actions[i].label)) {
        this.addOption(groupEnum.ACTION, this.actions[i])
      }
    }
  }

  clearGroups() {
    this.resultOptionsGroup = [];
  }

  optionClick(o: ResultOption) {
    o.action();
    this.clearGroups();
    setTimeout(() => {
      this.input.nativeElement.blur();
    })
  }

  hasResults() {
    return this.resultOptionsGroup.length != 0;
  }

  showNoData() {
    return this.resultOptionsGroup.length == 0 && this.myControl.value;
  }

}
