import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { validatorTax } from '../validators/tax';
import { M_Contact } from '../models/M_Contact';
import { RouterService } from '../services/router.service';
import { ViewPath } from '../app-routing.module';
import { MASTER_CLIENT_MINIFIY, MASTER_VECHILE_MINIFY } from '../constants/masters';
import { M_Vehicle } from '../models/M_Vehicle';
import { CreateMasterInDialogService } from '../services/create-master-in-dialog.service';
import { ClassSearcherComponent } from '../components/class-searcher/class-searcher.component';
import { SellVehicleFormComponent } from '../sell-vehicle-form/sell-vehicle-form.component';
import { tap } from 'rxjs';
import { Console } from 'console';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ApiService } from '../services/Api/api.service';
import { TemplateFieldsService } from '../services/template-fields.service';
import { PreviewService } from '../services/preview.service';
import { ParamsService } from '../services/params.service';
import { M_Purchase } from '../models/M_Purchase';
import { SnackService } from '../services/snack.service';

@Component({
  selector: 'app-create-purchase',
  templateUrl: './create-purchase.component.html',
  styleUrls: ['./create-purchase.component.css']
})
export class CreatePurchaseComponent {
  selectedFile: File | null = null;
  formData = new FormData();
  minDate: Date;
  vehicle = MASTER_VECHILE_MINIFY;
  client_select : M_Contact | undefined;
  v = ViewPath;
  activeTab: string | undefined;
  client = MASTER_CLIENT_MINIFIY;
  vehicle_select : M_Vehicle | undefined
  selectedTab : number = 1;
  @ViewChild('vehicleSearcher', { static: true }) vehicleSearcher_!: ClassSearcherComponent<M_Vehicle>;
  @ViewChild('csearcher') clientSearcherComponent?: ClassSearcherComponent<M_Contact>
  @ViewChild(SellVehicleFormComponent, { static: true }) sellForm!: SellVehicleFormComponent;

  constructor(private formBuilder: UntypedFormBuilder, private routerS:RouterService,public createMasterS: CreateMasterInDialogService,
    private apiS: ApiService,
    private templateService: TemplateFieldsService,
    public previewS: PreviewService,
    public paramsS: ParamsService,
    public snackS: SnackService
  ){

    this.minDate = new Date();
  }
  handleClientSelection(selectedClient: M_Contact) {
    this.client_select = selectedClient;
  }
  changeClient(selectedVehicle: M_Vehicle) {
    this.vehicle_select = selectedVehicle;
  }
  goCreateClient() {
    this.routerS.goTo(this.v.createContact);
 
  }
  onTabChange(event: MatTabChangeEvent): void {
    // Identificar el tipo de compra según el índice de la pestaña seleccionada
    // this.sellForm.vehicleSearcherComponent?.remove();
    // this.sellForm.clientSearcherComponent?.remove();
    const tabIndex = event.index;

    this.selectedTab = tabIndex === 0 ? 1 : 2;
  }

  get sell_form(): M_Vehicle | undefined{
    if(this.sellForm.form.valid){
        return  new M_Vehicle(this.sellForm.form.value);
    }
    return undefined;
  }
  get selectorTab(){
    return this.activeTab;
  }
  create(){
      this.sellForm.form.patchValue({type:this.selectedTab});  // Añadir el tipo de compra
      console.log('Formulario enviado:', this.sellForm.form.value);
      const formData = new FormData();
      // Trabajar compra de recambios
      if(this.selectedTab == 1){
        // Agregar valores del formulario
        Object.keys(this.sellForm.form.value).forEach(key => {
            formData.append(key, this.sellForm.form.get(key)?.value);
        });
          if (this.sellForm.pdfUploadComponent.selectedFile) {
            formData.append('pdf_file', this.sellForm.pdfUploadComponent.selectedFile);
           
          }
          this.apiS.sellArticles(formData).then(res=>{
            if(res instanceof M_Purchase){
              this.routerS.goToWithState(this.v.purchases,res.id);
              this.snackS.show("Factura compra recambios creada con éxito");
            }
          })

      }
      // trabajar compra vehiculo
      else{
        // Agregar valores del formulario
        Object.keys(this.sellForm.form.value).forEach(key => {
            formData.append(key, this.sellForm.form.get(key)?.value);
        });
        if(this.sellForm.client_select?.type === 2 || this.sellForm.client_select?.type === 3){
          if (this.sellForm.pdfUploadComponent.selectedFile) {
            formData.append('pdf_file', this.sellForm.pdfUploadComponent.selectedFile);
            
          }
        }
        // llamada endpoint
        this.templateService.showTemplateSteps("CV", this.sellForm.vehicle_select?.vehicle_id, "CV", this.sellForm.vehicle_select?.type, undefined, this.sellForm.form.value).afterClosed().subscribe(res => {
              if (res) {
                if (this.sellForm.vehicle_select && this.sellForm.client_select) {
                  //preview finish and save pdf after call endpoint sell vehicle.
                  this.sellForm.form.patchValue({ mode: "save", buy_transac_id: res });
                  this.previewS.showPreview("CV", "CV", undefined, undefined, false, undefined, undefined, undefined, this.sellForm.form.value);
                  this.apiS.sellVehicleClient(formData).then(_resp => {
                    this.snackS.show("Factura compra vehículo creada con éxito");
                  });
                }
              }
        });
      }
  }
}
