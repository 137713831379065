import { Component, Input, ViewChild } from '@angular/core';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { EnrtryTypeEnum } from 'src/app/enums/EnrtryTypeEnum';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Order } from 'src/app/models/M_Order';
import { M_StoreHouseEntry } from 'src/app/models/M_StoreHouseEntry';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';

@Component({
  selector: 'app-storehouse-entry-section',
  templateUrl: './storehouse-entry-section.component.html',
  styleUrls: ['./storehouse-entry-section.component.css']
})
export class StorehouseEntrySectionComponent implements IProductLineTableComponent {

  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  get blocksLine(): boolean { return false; }

  @Input({ required: true }) products: M_Product[] = [];
  @Input({ required: true }) array!: "products" | "products_file" | undefined;
  @Input({ required: true }) entry!: M_StoreHouseEntry;
  @Input({ required: true }) showProviders: boolean = false;
  @Input({ required: true }) entranceType!: EnrtryTypeEnum;
  @Input() showNewOrPriceChange: boolean = false;
  @Input() showDest: boolean = true;
  @Input() showEntryTo: boolean = false;
  @Input() locationOnlyInformative: boolean = false;
  @Input() order?: M_Order;

  constructor() { }

  get isPMP() { return this.entranceType == EnrtryTypeEnum.PMP; }
  get isImport() { return this.entranceType == EnrtryTypeEnum.IMPORT; }
  get isCost() { return this.entranceType == EnrtryTypeEnum.COST; }

  removeProduct(p: M_Product | M_CustomProduct): void {
    if (p.instanceofCustom()) { return; }
    if (this.order) {
      this.order.remove(p);
      if (!this.order.products.length) {
        this.entry.removeOrder(this.order);
      }
    }
    else if (this.array) {
      this.entry.removeProduct(p, this.array);
    }
  }

  getClientDiscount(p: M_Product | M_CustomProduct): number | null { return null; }

  addProduct(p: M_Product | M_CustomProduct): void {
    if (p.instanceofCustom()) { return; }
    throw new Error('Implemented on parent');
  }

  addTime(time: M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }

  addComment(comment: M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }

  getClient(): M_Contact | undefined {
    return undefined;
  }

}
