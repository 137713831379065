import { Component } from '@angular/core';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';

@Component({
  selector: 'app-centers',
  templateUrl: './centers.component.html',
  styleUrls: ['./centers.component.css']
})
export class CentersComponent {
  constructor(public companyS: CompanyService) { }
  createCenter(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    console.log("Oke")
  }
}
