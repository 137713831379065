import { IWorkload } from "../interfaces/IWorkload";
import { Views } from "./View";


export class WorkloadData {
    isOrView: boolean = true;
    constructor(public data: IWorkload) { }
    changeView(or : boolean) {
        this.isOrView = or;
    }

    get userPreFiltered(){
        return this.data.or.preFilteredUser;
    }

    get canUserAssign(){
        return this.data.or.canUserAssign;
    }

    get ORpermisison(){
        return this.data.or.userPermission;
    }

    get AppoPermisison(){
        return this.data.appointments.userPermission;
    }

    get createView() : Views | undefined{
        return this.isOrView ? this.data.or.views.createOr : this.data.appointments.views.createAppointment;
    }

    get editView() : Views | undefined{
        return this.isOrView ? this.data.or.views.editOr : this.data.appointments.views.editAppointment;
    }

    get canContact(){
        return this.isOrView ? (this.data.or.showContact == undefined || this.data.or.showContact == true): true;
    }
}