import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Vehicle } from 'src/app/models/M_Vehicle';

@Component({
  selector: 'app-vehicle-rectangle',
  templateUrl: './vehicle-rectangle.component.html',
  styleUrls: ['./vehicle-rectangle.component.css']
})
export class VehicleRectangleComponent {
  v = ViewPath;
  @Input({required : true}) vehicle! : M_Vehicle;
  constructor(){
    console.log(this.vehicle);
  }
}
