import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { DayFilter } from 'src/app/custom-classes/Filter';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { M_LastMovements } from 'src/app/models/M_LastMovement';
import { M_TypePayment } from 'src/app/models/M_TypePayment';

@Component({
  selector: 'app-dialog-checkout-control',
  templateUrl: './dialog-checkout-control.component.html',
  styleUrls: ['./dialog-checkout-control.component.css']
})
export class DialogCheckoutControlComponent implements OnInit {

  invoices: M_Invoice[] = [];
  filteredInvoices: M_LastMovements[] = [];
  AdvancespayInvoices: M_LastMovements[] = [];
  filteredAdvances: M_LastMovements[] = [];
  Advances: M_LastMovements[] = [];
  filterDay: DayFilter = new DayFilter("Fecha de cobro", false);
  last_movement_invoices: M_LastMovements[] = [];
  deleted_types_payments: M_LastMovements[] = [];
  loading : boolean = false;
  constructor(private apiS: ApiService,@Inject(MAT_DIALOG_DATA) public data: { invoices: M_Invoice[], type_payments: M_TypePayment[] }) {
    this.invoices = data.invoices;
    this.apiS.client.clientsAdvances().then(res=>{
      this.Advances = res;
      this.loading = true;
      this.refreshInvoices(0);
    }); 
  }

  ngOnInit(): void {
    this.filterDay.selectedOption = 0;
    this.data.type_payments = this.data.type_payments.filter(obj => obj.isCustom);
    this.refreshInvoices(0);
  }

  get totalBizum() {
    let total_bizum = 0;
    let total_adv_bizum = 0;
    const array_bizum: any[] = [];
    const array_advbizum: any[] = [];
    this.filteredInvoices.forEach(i => {
        if (i.type_payment.isBizum) {
          array_bizum.push(i);
          if(!i.abono){
            total_bizum += i.import;
          }else{
           
            total_bizum-= i.import;
          }
        }
    });
    this.filteredAdvances.forEach(x=>{
      if(x.type_payment.isBizum){
        array_advbizum.push(x);
        total_adv_bizum+=x.import;
      }
    });
    return total_bizum+total_adv_bizum;
  }

  get totalCard() {
    let total_card = 0;
    let total_adv_card =0;
    const array_card: any[] = [];
    const array_advcard: any[] = [];
    this.filteredInvoices.forEach(i => {
        if (i.type_payment.isCard) {
          array_card.push(i);
          if(!i.abono){
  
            total_card += i.import;
          }else{
            
            total_card-=i.import;
          }
        }
    });
    this.filteredAdvances.forEach(x=>{
      if(x.type_payment.isCard){
        array_advcard.push(x);
        total_adv_card+=x.import;
      }
    });
    return Math.abs(total_card)+total_adv_card;
  }

  get totalTranferencia() {
    let total_tranferencia = 0;
    let total_adv_tranferencia = 0;
    const array_tranferencia: any[] = [];
    const array_advtranferencia: any[] = [];
    this.filteredInvoices.forEach(i => {
        if (i.type_payment.isTransference) {
          array_tranferencia.push(i);
          if(!i.abono){
            total_tranferencia += i.import;
          }else{
            total_tranferencia-=i.import;
          }
        }
    });
    this.filteredAdvances.forEach(x=>{
      if(x.type_payment.isTransference){
        array_advtranferencia.push(x);
        total_adv_tranferencia+=x.import;
      }
    });
    
    return Math.abs(total_tranferencia)+total_adv_tranferencia;
  }

  get totalEfectivo() {
    let total_efectivo = 0;
    let total_adv_efectivo = 0;
    const array_efectivo: any[] = [];
    const array_advefectivo: any[] = [];
    this.filteredInvoices.forEach(i => {
        if (i.type_payment.isCash) {
          array_efectivo.push(i);
          if(!i.abono){
            total_efectivo += i.import;
          }else{
            
            total_efectivo-=i.import;
           
          }
        }
    });
    this.filteredAdvances.forEach(x=>{
      if(x.type_payment.isCash){
        array_advefectivo.push(x);
        total_adv_efectivo+=x.import;
      }
    });
    return total_efectivo+total_adv_efectivo;
  }

  get totalPersonalizado() {
    let total_personalizado = 0;
    const array_person: any[] = [];
    this.filteredInvoices.forEach(i => {
        if (i.type_payment.isCustom && i.type_payment.deleted == 0) {          
            array_person.push(i);
            if(!i.abono){
              total_personalizado += i.import;
            }else{
              total_personalizado -= i.import;
            }
        }
    });
    return total_personalizado
  }

  get totalDeletedTypePayment() {
    let total_deleteds = 0;
    const array_deleteds: any[] = [];

    this.filteredInvoices.forEach(i => {
        if (i.type_payment.isCustom && i.type_payment.deleted != 0) {
            array_deleteds.push(i);
            if(!i.abono){
              total_deleteds += i.import;
            }else{
              
              total_deleteds -= i.import; 
            }
        }
      });
    return total_deleteds;
  }

  get totalPayType() {
    return this.totalBizum + this.totalEfectivo + this.totalCard + this.totalTranferencia + this.totalPersonalizado + this.totalDeletedTypePayment;
  }

  refreshInvoices(event: number) {
    this.filteredInvoices = [];
    this.filteredAdvances = [];
    this.AdvancespayInvoices = [];
    this.invoices.forEach(i => {
      i.last_movement.forEach(element => {
        let isOk = this.filterDay.checkFilter(element.created_at);
        this.filterDay
        if(isOk){
          
            this.filteredInvoices.push(element);
           
        }
        
      });
    });
    this.Advances.forEach(advance =>{
      let isOk = this.filterDay.checkFilter(advance.created_at);
      this.filterDay
      if(isOk){
        this.filteredAdvances.push(advance);

      }
    });
    this.last_movement_invoices = [];
    this.deleted_types_payments = [];
    this.filteredInvoices.forEach(x => {
        if (x.type_payment.isCustom && x.type_payment.deleted == 0) {
          this.last_movement_invoices.push(x);
        }
        if (x.type_payment.isCustom && x.type_payment.deleted == 1) {
          this.deleted_types_payments.push(x);
        }
    });
  }

  getTotalmovement(type_payment: M_TypePayment) {
    let total = 0;
    return this.filteredInvoices.reduce((sum, currentInvoice) => {
        const invoiceTotal =  currentInvoice.import;
        if(currentInvoice.type_payment.isCustom &&  currentInvoice.type_payment.payment == type_payment.payment){
            total = sum + (currentInvoice.abono ? -invoiceTotal : invoiceTotal); 
        }
       return total
    }, 0);
  }
}
