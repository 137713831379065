import { retry } from "rxjs";
import { ModulesEnum } from "../enums/ModulesEnum";

export type Funcionality = {
    icon: string;
    title: string;
    tooltip: string;
}

export class M_Module {
    id: ModulesEnum;
    service_name: string = "";
    created_at: Date | undefined;
    updated_at: Date | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.service_name = d.service_name ? d.service_name : "";
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
    }

    get name(): string {
        switch (this.id) {
            case ModulesEnum.RECAMBIOS:
                return "Módulo recambios";
            case ModulesEnum.VNVO:
                return "Módulo comercial"
            case ModulesEnum.HONDA:
                return "Módulo Honda"
            case ModulesEnum.TEMPLATES:
                return "Impresión Personalizada"
        }
    }

    get corename(): string {
        switch (this.id) {
            case ModulesEnum.RECAMBIOS:
                return "recambios";
            case ModulesEnum.VNVO:
                return "comercial"
            case ModulesEnum.HONDA:
                return "honda"
            case ModulesEnum.TEMPLATES:
                return "impresión personalizada"
        }
    }

    get title(): string {
        switch (this.id) {
            case ModulesEnum.RECAMBIOS:
                return "El módulo que todo recambista necesita";
            case ModulesEnum.VNVO:
                return "El módulo perfecto para concesionarios"
            case ModulesEnum.HONDA:
                return "El módulo perfecto para Honda"
            case ModulesEnum.TEMPLATES:
                return "El módulo perfecto personalizar las plantillas"
        }
    }

    get desc() {
        switch (this.id) {
            case ModulesEnum.RECAMBIOS:
                return "Simplifica la gestión de repuestos, reduce errores y mantén todo bajo control con nuestro módulo avanzado.";
            case ModulesEnum.VNVO:
                return "Gestiona tu inventario, automatiza procesos y cierra más ventas de vehículos nuevos y de ocasión."
            case ModulesEnum.HONDA:
                return "Honda desc."
            case ModulesEnum.TEMPLATES:
                return "Templates desc."
        }
    }

    get cardColor() {
        switch (this.id) {
            case ModulesEnum.RECAMBIOS:
                return "b1";
            case ModulesEnum.VNVO:
                return "y3"
            case ModulesEnum.HONDA:
                return "b1"
            case ModulesEnum.TEMPLATES:
                return "y3"
        }
    }

    get functionalities(): Funcionality[] {
        switch (this.id) {
            
            case ModulesEnum.RECAMBIOS:
                return [
                    { icon: "local_shipping", title: "Proveedores", tooltip: "Crear contactos de tipo 'proveedor'" },
                    { icon: "storefront", title: "Almacenes", tooltip: "Crear almacenes'" },
                    { icon: "production_quantity_limits", title: "Reservas/faltas", tooltip: "Gestionar reservas y faltas de productos'" },
                    { icon: "assignment", title: "Pedidos/albaranes", tooltip: "Generación de pedidos y albaranes" },
                ]

            case ModulesEnum.VNVO:
                return [
                    { icon: "work", title: "Agentes", tooltip: "Crear contactos de tipo 'agente'" },
                    { icon: "garage", title: "VN y VO", tooltip: "Crear y vender vehículos nuevos y de ocasión" },
                    { icon: "calculate", title: "Presupeuestos comercial", tooltip: "Crear presupuestos de comercial" },
                    { icon: "style", title: "Marcas y modelos", tooltip: "Control de marcas y modelos" },
                ]

            case ModulesEnum.HONDA:
                return [
                    { icon: "flag", title: "To do", tooltip: "To do" },
                ]

            case ModulesEnum.TEMPLATES:
                return [
                    { icon: "flag", title: "To do", tooltip: "To do" },
                ]
        }
    }

    get img(): string {
        return "./assets/img/modules/module-" + this.id.toString() + ".png";
    }
}