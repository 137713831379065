<div class="create-item">
    <app-go-back *ngIf="loaded" [text]="edit ? isProfile ? 'Perfil' : 'Detalles de usuario' : 'Tabla de usuarios'" (click)="goBack()"></app-go-back>
    <app-card [contentLoaded]="loaded">
        <app-card-title>{{edit ? uf.originalUser?.name : 'Crear usuario'}}</app-card-title>
        <app-userform #uf></app-userform>
        <div class="df jcc mt10">
            <button color="primary" [disabled]="!uf.form.valid" mat-flat-button (click)="createEdit()" cdkFocusInitial>
                {{edit ? 'Editar' : 'Crear'}}
            </button>
        </div>
    </app-card>
</div>
