import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { MASTER_USER } from 'src/app/constants/masters';
import { CustomTime } from 'src/app/custom-classes/CustomTime';
import { M_Action } from 'src/app/models/M_Action';
import { M_User } from 'src/app/models/M_User';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { SessionService } from 'src/app/services/session.service';
import { SnackService } from 'src/app/services/snack.service';
import { RolesEnum } from 'src/app/enums/RolesEnum';

@Component({
  selector: 'app-add-time-or-dialog',
  templateUrl: './add-time-or-dialog.component.html',
  styleUrls: ['./add-time-or-dialog.component.css']
})
export class AddTimeOrDialogComponent implements AfterViewInit {

  R = RolesEnum;
  m_user = MASTER_USER;
  form: UntypedFormGroup;

  @ViewChild("workerSearcher") workerSearcher?: ClassSearcherComponent<M_User>;
  constructor(
    public dialogRef: MatDialogRef<AddTimeOrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { or: M_Action, workers: M_User[], assigned: M_User | undefined },
    private apiS: ApiService, private snackS: SnackService, private sessionS: SessionService, userS: UserService, private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      user: ['', userS.userIsMechanic ? [] : [Validators.required]],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      date: [new Date(), Validators.required]
    })
  }

  ngAfterViewInit(): void {
    if (this.data.workers.length == 0) {
      this.form.disable();
    }
    /** If the user is no worker, pre-assign the assigned OR worker */
    if (this.data.assigned) {
      this.workerSearcher?.select(this.data.assigned);
    }
  }

  get hoursFc() {
    return this.form.get('hours')!;
  }

  get minutesFc() {
    return this.form.get('minutes')!;
  }

  get user() {
    return this.form.get('user')!;
  }

  get date() {
    return this.form.get('date')!;
  }



  get isAddTimeEnabled() {
    return this.hoursFc.value && this.minutesFc.value && this.workerSearcher?.selected != undefined;
  }

  closeWithTime() {

    var ct = new CustomTime(this.hoursFc.value, this.minutesFc.value);
    let userId = this.workerSearcher ? this.workerSearcher.selected?.id! : this.sessionS.getId()!;

    this.apiS.addManualTime(this.data.or.id, userId, ct.getHours(), ct.getMinutes(), this.date.value)
      .then(res => {
        this.data.or.addTime(res);
        this.dialogRef.close();
        this.snackS.show("Tiempo añadido correctamente", undefined, 2)
      })
      .catch(e => {
        this.dialogRef.close(new Error(e))
      })
  }

}
