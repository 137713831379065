import { CustomProductType } from "../../enums/CustomProductType";
import { invoiceCallProduct } from "../../types/invoiceCallProduct";
import { M_BaseProduct } from "./M_BaseProduct";
import { M_Product } from "./M_Product";

export class M_CustomProduct extends M_BaseProduct {

    override readonly custom: boolean = true;
    override readonly is_hours: boolean = false;
    override readonly is_comment: boolean = false;
    reference: string | undefined;
    comment: string | undefined;
    type: CustomProductType = CustomProductType.PRODUCT;
    locations = [];

    constructor(d: any) {
        super(d);
        this.reference = d.reference;
        if (d.is_hours) {
            this.type = CustomProductType.TIME;
            this.is_hours = true;
        }
        if (d.is_comment) {
            this.type = CustomProductType.COMMENT;
            this.is_comment = true;
            this.comment = d.comment;
        }
        this.tax = d.tax;

    }


    override instanceofProduct(): this is M_Product { return false; }
    override  instanceofCustom(): this is M_CustomProduct { return true; }

    override copyCoreAttributes(other: M_CustomProduct) {
        this.quantity = other.quantity;
        this.reference = other.reference;
        this.name = other.name;
        this.buy_price = other.buy_price;
        this.price = other.price;
        this.tax = other.tax;
        this.locations = other.locations;
        this.comment = other.comment;
    }

    override getInvoiceCallObject(modifyStock: boolean) {
        let obj: invoiceCallProduct = {
            prod_id: this.line_id!,
            quantity: !modifyStock ? "noaction" : undefined,
            custom: modifyStock ? this.quantity : undefined,
            discount: this.discount,
            selected_location: null,
            /** Custom product specific properties */
            name: this.name,
            reference: this.reference,
            price: this.price,
            buy_price: this.buy_price,
            tax: this.tax,

        };
        return obj;
    }


    override get icon() {
        switch (this.type) {
            case CustomProductType.PRODUCT:
                return "barcode"
            case CustomProductType.TIME:
                return "schedule"
            case CustomProductType.COMMENT:
                return "comment"
            default:
                return "barcode"
        }
    }

    override getInitialValue() { return 0; }

    get isProduct(): boolean { return this.type == CustomProductType.PRODUCT }
    override get isTime(): boolean { return this.type == CustomProductType.TIME }
    override get isComment(): boolean { return this.type == CustomProductType.COMMENT }

    /** CLASS SEARCHER INTERFACE */
    get cs_id() { return this.line_id; }
    getInputText(): string {
        return this.name;
    }
    getOptionText(): [string, string] {
        return ([this.name, this.reference ? this.reference : ""])
    }
}