import { Calls } from "../enums/Calls";
import { Endpoint } from "../custom-classes/Endpoint";

export const endpoints = {

    globalSearch :                  new Endpoint(Calls.globalSearch, true, false),

    login :                         new Endpoint(Calls.login, false),
    logout :                        new Endpoint(Calls.logout, true),

    validateEmail :                 new Endpoint(Calls.validateEmail, false),
    updatePassword :                new Endpoint(Calls.updatePassword, false),
    getInvoice :                    new Endpoint(Calls.getInvoice, true, false),
    getUsersById :                  new Endpoint(Calls.getUsersById, true, false),

    dashboard :                     new Endpoint(Calls.dashboard, true, false),

    uploadImage :                   new Endpoint(Calls.uploadImage, true, false),
    deleteImage :                   new Endpoint(Calls.deleteImage, true, false),
    deleteById :                    new Endpoint(Calls.deleteId, true, false),

    profile :                       new Endpoint(Calls.profile, true, false),
    forgotPassword :                new Endpoint(Calls.forgotPassword, true, true),

    updatePass :                    new Endpoint(Calls.updatePass, true),
    resendConfirmation :            new Endpoint(Calls.resendConfirmation, true, true),
    pickUpMail :                    new Endpoint(Calls.pickUpMail, true, true),
    requestPDF :                    new Endpoint(Calls.requestPDF, true, true),
    getAppointmentId:               new Endpoint(Calls.getAppointmentId, true, false),
    appointment:                    new Endpoint(Calls.appointment, true, false),
    showFirstDay :                  new Endpoint(Calls.showFirstDay, true , false),
    getBookedHours:                 new Endpoint(Calls.getBookedHours, true , false),
    getBookedHoursByCompanyId :     new Endpoint(Calls.getBookedHoursByCompanyId, false , false),
    getHolidays:                    new Endpoint(Calls.getHolidays, true , false),
    getHolidaysRider:               new Endpoint(Calls.getHolidaysRider, false , false),
    showRider:                      new Endpoint(Calls.showRider, false , false),
    appointemtsRange:               new Endpoint(Calls.appointemtsRange, true , false),
    getCitaByDay:                   new Endpoint(Calls.getCitaByDay, true , false),
    setHolidays:                    new Endpoint(Calls.setHolidays, true , false),
    setHolidaysCompanies:           new Endpoint(Calls.setHolidaysCompanies, true , false),
    getTaskAppointment:             new Endpoint(Calls.getTaskAppointment, true , false),
    createAppointmentClient:        new Endpoint(Calls.createAppointmentClient, true , false),
    createAppointmentClientSide:    new Endpoint(Calls.createAppointmentClientSide, false, true),
    changeAppointmentDate :         new Endpoint(Calls.changeAppointmentDate),
    editAppointmentClient :         new Endpoint(Calls.editAppointmentClient),
    updateAppointmentClient :       new Endpoint(Calls.updateAppointmentClient),
    checkHolidays :                 new Endpoint(Calls.checkHolidays,true, false),
    configCompanyAppointment :      new Endpoint(Calls.configCompanyAppointment,true,false),
    updateConfigAppointment :       new Endpoint(Calls.updateConfigAppointment),
    getCompanyInfoClientSide:       new Endpoint(Calls.getCompanyInfoClientSide, false , false),
    getAppointmentsSide:            new Endpoint(Calls.getAppointmentsSide, false , false),
    clients :                       new Endpoint(Calls.clients, true, false),
    c_clients :                     new Endpoint(Calls.c_clients, true, false),
    providers :                     new Endpoint(Calls.providers, true, false),
    clientAdmincenters :            new Endpoint(Calls.clientAdmincenters, true, false),
    clientsminify :                 new Endpoint(Calls.clientsminify, true, false),
    createUpdateClient :            new Endpoint(Calls.createUpdateClient, true, true),
    getClientById :                 new Endpoint(Calls.getClientById, true, false),
    addExtraAddress :               new Endpoint(Calls.addExtraAddress, true, true),


    checkExist :                    new Endpoint(Calls.checkPlateDni, true, false),

    vehicles :                      new Endpoint(Calls.vehicles, true, false),
    clientsAdvances :               new Endpoint(Calls.clientsAdvances, true, false),
    vehicleType :                   new Endpoint(Calls.vehicleType, true, false),
    vehiclesminify :                new Endpoint(Calls.vehiclesminify, true, false),
    vehiclesminify2 :               new Endpoint(Calls.vehiclesminify2, true, false),
    createUpdateVehicle :           new Endpoint(Calls.createUpdateVehicle, true, true),
    searchClinets :                 new Endpoint(Calls.clients, true, true), //
    getVehicleById :                new Endpoint(Calls.getVehicletById, true, true),
    linkVehicle :                   new Endpoint(Calls.linkVehicle, true, true),
    sellVehicleClient :             new Endpoint(Calls.sellVehicleClient, true, true),
    sellArticles :                  new Endpoint(Calls.sellArticles, true, true),
    clientsForVehicles :            new Endpoint(Calls.clientsForVehicles, true, false),

    products :                      new Endpoint(Calls.products, true, false),
    productsOnlyProviders :         new Endpoint(Calls.productsOnlyProviders, true, false),
    storehouses :                   new Endpoint(Calls.storehouses, true, false),
    checkDuplicatedStoreHouseDocu : new Endpoint(Calls.checkDuplicatedStoreHouseDocu, true, false),
    storehouseCUD :                 new Endpoint(Calls.storehouseCUD, true, false),
    createProduct :                 new Endpoint(Calls.createProduct, true, true),
    editProductProviderDiscount:    new Endpoint(Calls.editProductProviderDiscount, true, true),

    getProductById:                 new Endpoint(Calls.getProductById, true, false),
    editStock :                     new Endpoint(Calls.editStock, true, true),
    productProvider :               new Endpoint(Calls.productProvider, true, true),
    alternativeProducts :           new Endpoint(Calls.alternativeProducts, true, true),
    faults :                        new Endpoint(Calls.faults, true, false),
    manualFault :                   new Endpoint(Calls.manualFault, true, false),
    deleteFault :                   new Endpoint(Calls.deleteFault, true, false),
    stockMove :                     new Endpoint(Calls.stockMove, true, false),
    getProductItemQuants :          new Endpoint(Calls.getProductItemQuants, true, false),
    getLinesByDiscountGroup :       new Endpoint(Calls.getLinesByDiscountGroup, true, false),
    addLinesToDiscountGroup :       new Endpoint(Calls.addLinesToDiscountGroup, true, true),
    discountGroups :                new Endpoint(Calls.discountGroups, true, true),


    company :                       new Endpoint(Calls.company, true, true),
    createCompany :                 new Endpoint(Calls.createCompany, false, true),
    updateCompany :                 new Endpoint(Calls.updateCompany, true, true),
    deleteCompany :                 new Endpoint(Calls.deleteCompany, true, true),
    welcomeCompany :                new Endpoint(Calls.welcomeCompany, true, true),
    emailPromo :                    new Endpoint(Calls.emailPromo, true, true),
    saveColors :                    new Endpoint(Calls.saveColors, true, true),
    saveFooters :                   new Endpoint(Calls.saveFooters, true, true),
    saveCompanyFiles :              new Endpoint(Calls.saveCompanyFiles, true, true),
    smtp :                          new Endpoint(Calls.smtp, true, true),
    testEmail :                     new Endpoint(Calls.testEmail, true, true),
    presentationMail :              new Endpoint(Calls.presentationMail, true, true),
    payedInvoice :                  new Endpoint(Calls.payedInvoice, true,true),
    pdfExample :                    new Endpoint(Calls.pdfExample, true, false),
    getLocationByZip :              new Endpoint(Calls.getLocationByZip, true, false, true),
    getFieldsByCCAA :               new Endpoint(Calls.getFieldsByCCAA, true, false, true),
    buyTransac:                     new Endpoint(Calls.buyTransac, true, false, true),
    users :                         new Endpoint(Calls.users, true, false),
    createUser :                    new Endpoint(Calls.createUser, true, true),
    updateUser :                    new Endpoint(Calls.updateUser, true, true),
    updateUserName :                new Endpoint(Calls.updateUserName, true, true),

    bills :                         new Endpoint(Calls.bills, true, false),
    bill :                          new Endpoint(Calls.bill, true, true),
    createBill :                    new Endpoint(Calls.createBill, true, true),
    saveInvoice :                   new Endpoint(Calls.saveInvoice, true, true),
    delProdInvoice :                new Endpoint(Calls.delProdInvoice, true, true),
    payedBill :                     new Endpoint(Calls.payedBill, true, true),
    confirmInvoice :                new Endpoint(Calls.confirmInvoice, true, true),
    discardInvoice :                new Endpoint(Calls.discardInvoice, true, true),
    downloadInvoices :              new Endpoint(Calls.downloadInvoices, true, true),
    templatePreview :               new Endpoint(Calls.templatePreview, true, true),
    hasDraft :                      new Endpoint(Calls.hasDraft, true, false),
    exportInvoices :                new Endpoint(Calls.exportInvoices, true, false),
    lastInvoiceDate :               new Endpoint(Calls.lastInvoiceDate, true, false),

    actionTypes :                   new Endpoint(Calls.actionTypes, true, true),
    actions :                       new Endpoint(Calls.actions, true, false),
    createOR :                      new Endpoint(Calls.createOR, true, true),
    addClientVehicleAction :        new Endpoint(Calls.addClientVehicleAction, true, true),
    getAction :                     new Endpoint(Calls.getAction, true, false),
    addEditGroup :                  new Endpoint(Calls.addEditGroup, true, true),
    addTask :                       new Endpoint(Calls.addTask, true, true),
    assignAction :                  new Endpoint(Calls.assignAction, true, true),
    assignClient :                  new Endpoint(Calls.assignClient, true, true),
    editDelivery :                  new Endpoint(Calls.editDelivery, true, true),
    updateSchedule :                new Endpoint(Calls.updateSchedule, true, true),
    addEditTasks :                  new Endpoint(Calls.addEditTasks, true, true),
    schedules :                     new Endpoint(Calls.schedules, true, true),
    rmGroup :                       new Endpoint(Calls.rmGroup, true, true),
    rmTask :                        new Endpoint(Calls.rmTask, true, true),
    rmAction :                      new Endpoint(Calls.rmAction, true, true),
    invoiceOR :                     new Endpoint(Calls.invoiceOR, true, true),
    sortTask :                      new Endpoint(Calls.sortTask, true, true),
    changeTask :                    new Endpoint(Calls.changeTask, true, true),
    editKm :                        new Endpoint(Calls.editKm, true, true),
    editNotes :                     new Endpoint(Calls.editNotes, true, true),
    editTitle :                     new Endpoint(Calls.editTitle, true, true),
    closeGroup :                    new Endpoint(Calls.closeGroup, true, true),
    openGroup :                     new Endpoint(Calls.openGroup, true, true),
    reorderActions :                new Endpoint(Calls.reorderActions, true, true),
    editAction :                    new Endpoint(Calls.editAction, true, true),
    invoiceState:                   new Endpoint(Calls.stateInvoice, true,true),
    showTypePayment:                new Endpoint(Calls.showTypePayment, true,true),
    CreateLastPayment:              new Endpoint(Calls.CreateLastPayment, true,true),
    CreateAdvancesClient:           new Endpoint(Calls.CreateAdvancesClient, true,true),
    DeleteAdvancesClient:           new Endpoint(Calls.DeleteAdvancesClient, true,true),
    EditAdvancesClient:             new Endpoint(Calls.EditAdvancesClient, true,true),
    DeleteMovementInvoice:          new Endpoint(Calls.DeleteMovementInvoice, true,true),
    DeleteTypePayment:              new Endpoint(Calls.DeleteTypePayment, true,true),
    createBudget :                  new Endpoint(Calls.createBudget, true, true),
    closeBudget :                   new Endpoint(Calls.closeBudget, true, true),
    copyAction :                    new Endpoint(Calls.copyAction, true, true),

    /** Recambios budget */
    createRecambiosBudget :         new Endpoint(Calls.createRecambiosBudget, true, true),

    importStorage :                 new Endpoint(Calls.importStorage, true, true),
    importClients :                 new Endpoint(Calls.importClients, true, true),
    importTarifas :                 new Endpoint(Calls.importTarifas, true, true),

    /**ORDERS */
    getOrder :                      new Endpoint(Calls.getOrder, true, false),
    getOrders :                     new Endpoint(Calls.getOrders, true, false),
    createOrder :                   new Endpoint(Calls.createOrder, true, true),
    editOrder :                     new Endpoint(Calls.editOrder, true, true),
    saveGroupDetails :              new Endpoint(Calls.saveGroupDetails, true, true),
    deleteOrderProduct :            new Endpoint(Calls.deleteOrderProduct, true, true),
    changeSended :                  new Endpoint(Calls.changeSended, true, true),

    /** Reservations */
    manualReservation :             new Endpoint(Calls.manualReservation, true, true),
    getReservation :                new Endpoint(Calls.getReservation, true, true),



    /** Notifications */
    getNotifications :              new Endpoint(Calls.getNotifications, true, false),
    updateNotifications:            new Endpoint(Calls.updateNotifications),
    readAllNotifications :          new Endpoint(Calls.readAllNotifications),

    /** Cloud tickets */
    cloudtickets:                   new Endpoint(Calls.cloudtickets, true, false),
    createTicket:                   new Endpoint(Calls.createTicket),
    addcomment:                     new Endpoint(Calls.addcomment),

    /** Abono */
    abono :                         new Endpoint(Calls.abono, true, true),

    /** Albaranes */
    albaranes :                     new Endpoint(Calls.albaranes, true, true),
    albaranById :                   new Endpoint(Calls.albaranById, true, true),
    createAlbaran :                 new Endpoint(Calls.createAlbaran, true, true),
    saveAlbaranInfo:                new Endpoint(Calls.saveAlbaranInfo, true, true),
    saveAlbaranProducts :           new Endpoint(Calls.saveAlbaranProducts, true, true),
    removeAlbaranProduct :          new Endpoint(Calls.removeAlbaranProduct, true, true),
    changeAlbaranStatus :           new Endpoint(Calls.changeAlbaranStatus, true, true),
    changeAlbaranType :             new Endpoint(Calls.changeAlbaranType, true, true),
    deleteAlbaran :                 new Endpoint(Calls.deleteAlbaran, true, true),
    invoiceAlbaran :                new Endpoint(Calls.invoiceAlbaran, true, true),

    /** Report erorr */
    reportError :                   new Endpoint(Calls.reportError, false, false),


    /** Templates url */
    invoicePDF :                    new Endpoint(Calls.invoice, true, false),
    eInvoicePDF :                   new Endpoint(Calls.eInvoice, true, false),
    budgetPDF :                     new Endpoint(Calls.budgetPDF, true, false),
    raPDF :                         new Endpoint(Calls.ra, true, false),
    createAdvancePdf :              new Endpoint(Calls.createAdvancePdf, true, false),
    ORalbaranPDF :                  new Endpoint(Calls.ORalbaranPDF, true, false),
    albaranPDF :                    new Endpoint(Calls.albaranPDF, true, false),
    storehousePDF :                 new Endpoint(Calls.storehousePDF, true, false),
    comercialBudgetPDF :            new Endpoint(Calls.comercialBudgetPDF, true, false),

    /** No id Objects URL */
    previewRA :                     new Endpoint(Calls.previewRA, true, false),
    previewCV:                      new Endpoint(Calls.previewCV, true, false),
    previewFC:                      new Endpoint(Calls.previewFC, true, false),


    /** Brand and models */
    brands :                        new Endpoint(Calls.brand, true, false),
    brandModels :                   new Endpoint(Calls.brand_models, true, false),

    /** Paycomet */
    payComet :                      new Endpoint(Calls.payComet, true, false, true),
    payCometInvoices :              new Endpoint(Calls.payCometInvoices, true, false, true),
    checkSubscription :             new Endpoint(Calls.checkSubscription,true, false, true),
    paymentByCompany :              new Endpoint(Calls.paymentByCompany,true, false),

    /** Templates */
    getFieldsFromDoc :              new Endpoint(Calls.getFieldsFromDoc,true, false),
    saveTemplateFields :            new Endpoint(Calls.saveTemplateFields,true, true),
    saveTemplateImage :             new Endpoint(Calls.saveTemplateImage,true, true),

    createReserve :                 new Endpoint(Calls.createReserve, true, true),

    /** Massive invoice */
    getForMassiveInvoiceOR :        new Endpoint(Calls.getForMassiveInvoiceOR, true, true),
    getForMassiveInvoiceDNote :     new Endpoint(Calls.getForMassiveInvoiceDNote, true, true),   
    invoiceMassiveOR :              new Endpoint(Calls.invoiceMassiveOR, true, true),
    invoiceMassiveDNote :           new Endpoint(Calls.invoiceMassiveDNote, true, true),


    /** Fichage */
    currentlyWorking :              new Endpoint(Calls.currentlyWorking, true),
    startTime :                     new Endpoint(Calls.startTime, true),
    endTime :                       new Endpoint(Calls.endTime, true),
    addManualTime :                 new Endpoint(Calls.addManualTime, true),
    deleteTimeHistory :             new Endpoint(Calls.deleteTimeHistory, true),

    /** Sotehouse entry */
    getEntryIntoStore :             new Endpoint(Calls.getEntryIntoStore, true),
    entryIntoStore :                new Endpoint(Calls.entryIntoStore, true),


    /** VN and VO */
    vehiclesC :                     new Endpoint(Calls.vehiclesC, true),
    vehicleC :                      new Endpoint(Calls.vehicleC, true),
    createVNVO :                    new Endpoint(Calls.createVNVO, true),
    sellVehicle:                    new Endpoint(Calls.sellVehicle, true),
    getAgents:                      new Endpoint(Calls.getAgents, true),
    updateModel:                    new Endpoint(Calls.updateModel, true),
    getModel:                       new Endpoint(Calls.getModel, true),
    createUpdateOption:             new Endpoint(Calls.createUpdateOption, true),
    getOptionsByModel:              new Endpoint(Calls.getOptionsByModel, true),
    createUpdateConcept:            new Endpoint(Calls.createUpdateConcept, true),
    getConcepts:                    new Endpoint(Calls.getConcepts, true, false),
    getConceptById:                 new Endpoint(Calls.getConceptById, true, false),
    addStock:                       new Endpoint(Calls.addStock, true),
    createComercialBudget:          new Endpoint(Calls.createComercialBudget, true),
    getComercialBudget:             new Endpoint(Calls.getComercialBudget, true),
    getComercialBudgets:            new Endpoint(Calls.getComercialBudgets, true),
    closeComercialBudget:           new Endpoint(Calls.closeComercialBudget, true),
    deleteConmercialBudget:         new Endpoint(Calls.deleteConmercialBudget, true),
    getPurchases:                   new Endpoint(Calls.getPurchases, true),
    getPurchaseById:                new Endpoint(Calls.getPurchaseById, true, false),
    getStockBrandAndModel:          new Endpoint(Calls.getStockBrandAndModel, true, false),
    /** Eina data  */
    einaData :                      new Endpoint(Calls.einaData, true),


    /** Modules */
    modules :                       new Endpoint(Calls.modules, true, true),
    recanviosClient :               new Endpoint(Calls.recanviosClient, true, true),
    toggleModule :                  new Endpoint(Calls.toggleModule, true, true),
}
